import React, { useState, useEffect, useContext } from 'react';
// REACT NATIVE COMPONENTS
import { View, TouchableOpacity, FlatList } from "react-native";
import BelongIcon from '../../../../components/BelongIcon';
import StyledText from '../../../../components/StyledText';
import ListItem from '../../../UserList/components/ListItem';
import CollapsibleWrapper from '../../../../components/CollapsibleWrapper';
import CollapsibleHeader from '../../../../components/CollapsibleHeader';
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import { useMediaQuery } from 'react-responsive';
// STYLING
const styles = {
    backdrop: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden'
    },
    containerBig: {
        position: 'absolute',
        overflow: 'hidden',
        width: '100%',
        maxWidth: 600,
        minHeight: 600,
        maxHeight: '60vh',
        alignSelf: 'center',
        bottom: -10,
        borderRadius: 10,
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 4,
    },
    headerDesktop: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    headerDesktopText: {
        fontSize: 20,
        fontWeight: '500',
        color: '#333333'
    },
    title: {
        fontSize: 30,
        fontWeight: "500",
        color: "#333333",
        marginTop: 5,
    },
    list: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: "#cccccc",
        flex: 1,
    },
};
const MutableWrapper = (props) => {
    const isBig = useMediaQuery({ query: '(min-width: 800px)' });
    return isBig ? <View style={styles.backdrop}><View style={styles.containerBig}>{props.children}</View></View> : <React.Fragment>{props.children}</React.Fragment>;
};
const Comments = props => {
    const [fullItem, setFullItem] = useState(null);
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    const isBig = useMediaQuery({ query: '(min-width: 800px)' });
    const item = props.route.params.item;
    useEffect(() => {
        feathersContext.get('posts', props.route.params.item._id).then(r => setFullItem(r));
    }, [props.route.params.item._id]);
    return (<MutableWrapper>
      <CollapsibleWrapper headerComponent={(disappearingOpacity, appearingOpacity) => (isBig ?
        <View style={styles.headerDesktop}>
              <StyledText style={styles.headerDesktopText} variant="h1">{(fullItem ? fullItem.likes || [] : []).length} likes</StyledText>
            <TouchableOpacity onPress={() => props.navigation.goBack()}>
              <BelongIcon name="sm-x" color="#000000" size={18}/>
            </TouchableOpacity>
          </View> :
        <CollapsibleHeader central={<StyledText tkey="timeline.likesTitle" variant="h1" style={styles.title}>
                Me gusta
              </StyledText>} backAction={() => props.navigation.goBack()} 
        // mainAction={() => {}}
        // mainActionTitle="Buscar"
        disappearingOpacity={disappearingOpacity} appearingOpacity={appearingOpacity} themeColor={props.services.app.manifest.themeColor}/>)} backgroundColor="#EEEFF3" bodyBackgroundColor="#FFFFFF" headerComponentStyle={{
        backgroundColor: "#EEEFF3",
        paddingHorizontal: 15,
    }} bodyComponentStyle={{
        flex: 1,
    }} scrollViewOverride={(onScroll, scrollEventThrottle, contentContainerPadding) => (<FlatList contentContainerStyle={{ paddingTop: contentContainerPadding, backgroundColor: '#fff' }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle} style={{ backgroundColor: '#fff' }} data={fullItem ? fullItem.likes || [] : []} keyExtractor={(item, index) => item._id} renderItem={({ key, item }) => (<ListItem key={key} item={item} themeColor={props.services.app.manifest.themeColor} onPress={() => {
        props.navigation.navigate("UserProfile", { user: item });
    }}/>)} ItemSeparatorComponent={() => <View style={{ marginHorizontal: 15, height: 1, backgroundColor: '#e9e9e9' }}></View>}/>)}/>
    </MutableWrapper>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Comments);
