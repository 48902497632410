import React from 'react';
import { View, Dimensions } from "react-native";
import QRCode from 'react-native-qrcode-svg';
import SmallHeader from "../../components/SmallHeader";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
// STYLING
const styles = {
    container: {
        flex: 1,
        backgroundColor: '#FFFFFF',
        paddingHorizontal: 30,
        paddingTop: 50
    },
    qr: {
        width: '100%',
        backgroundColor: 'red'
    }
};
const FastPass = props => {
    const { t, i18n } = useTranslation();
    const width = Dimensions.get("window").width - 60;
    return (<View style={{ flex: 1 }}>
      <SmallHeader backAction={() => props.navigation.goBack()} title={props.route.params?.module?.title || t('inbox.fastpass')} themeColor={props.services.app.manifest.themeColor}/>
      <View style={styles.container}>
        <QRCode value={props.services.app.feathersUser._id} size={width}/>
      </View>

    </View>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(FastPass);
