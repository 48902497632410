import React from 'react';
import { View } from 'react-native';
const BlurWidget = (props) => {
    return <View style={{ ...styles.blur, backgroundColor: getBackgroundColor(props.blurType || 'dark'), borderRadius: props.radius || 0 }}/>;
};
export default BlurWidget;
const getBackgroundColor = (blurType) => {
    switch (blurType) {
        case 'light':
            return 'rgba(255,255,255,0.5)';
        case 'dark':
            return 'rgba(0,0,0,0.5)';
        default:
            // will just blur without any tinting.
            return 'transparent';
    }
};
const styles = {
    blur: {
        zIndex: -100,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backdropFilter: 'blur(5px)',
        backgroundColor: 'rgba(255,255,255,0.5)'
    }
};
