import React from 'react';
// REACT NATIVE COMPONENTS
import { ActivityIndicator } from 'react-native';
// OWN ROOT COMPONENTS
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import StyledText from '../StyledText';
// OTHER UTILS
// STYLING
const styles = {
    error: {
        color: '#666666',
        textAlign: 'center',
        marginHorizontal: 20
    }
};
const BelongSpinner = props => {
    return props.error ?
        <StyledText tkey="loadError" style={styles.error}>Parece que ha habido un problema, inténtalo de nuevo más tarde.</StyledText>
        :
            <ActivityIndicator size="large" color={props.services.app.manifest.themeColor || "#00ff00"}/>;
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(BelongSpinner);
