import { store } from "../../store";
export const getGrouped = () => {
    const { items } = store.getState().data.users;
    //console.log("items", items)
    let workGroup = {};
    const grouped = items.reduce((acc, curr) => {
        let existObj = acc.find((obj) => obj.title === curr.lastName.substr(0, 1));
        if (existObj) {
            existObj.data.push(curr);
        }
        else {
            acc.push({
                title: curr.lastName.substr(0, 1),
                data: [
                    curr,
                ],
            });
        }
        return acc;
    }, []);
    //console.log("GROUPED", grouped);
    return grouped;
};
