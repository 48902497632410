import React, { useState } from "react";
import { View, TextInput, Platform } from "react-native";
import StyledText from "../../../StyledText";
const styles = {
    container: {
        marginHorizontal: 15,
        justifyContent: "space-between",
        position: "relative",
        minHeight: 64,
        paddingVertical: 0,
    },
    labelText: {
        fontSize: 13,
        color: "#8D8E8F",
        position: "absolute",
        top: 10,
        left: 5,
    },
    input: {
        paddingHorizontal: 5,
        fontFamily: "Matter",
        fontWeight: "500",
        color: "#333333",
        fontSize: 17,
        lineHeight: 20,
        marginBottom: Platform.OS == 'android' ? 0 : 12,
        paddingTop: 30,
        flex: 1,
        outline: 'none'
    },
    separator: {
        height: 1,
        backgroundColor: "#E9E9E9",
    },
    errorText: {
        paddingHorizontal: 20,
        paddingVertical: 8,
        fontSize: 11,
        color: "#EE4A4A",
    },
};
const Input = React.forwardRef((props, ref) => {
    const { label, error, last, style, value, ...inputProps } = props;
    // const [value, setValue] = useState("");
    const [focused, setFocused] = useState(false);
    // const fadeAnim = useRef(new Animated.Value(0)).current;
    // const fadeIn = () => {
    //   // Will change fadeAnim value to 1 in 5 seconds
    //   Animated.timing(fadeAnim, {
    //     toValue: 1,
    //     duration: 5000,
    //   }).start();
    // };
    // const fadeOut = () => {
    //   // Will change fadeAnim value to 0 in 5 seconds
    //   Animated.timing(fadeAnim, {
    //     toValue: 0,
    //     duration: 5000,
    //   }).start();
    // };
    return (<>
        <View style={{
        ...styles.container,
        ...props.containerStyle,
    }}>
          <StyledText style={{
        ...styles.labelText,
        ...(error ? { color: "#EE4A4A" } : {}),
        ...(focused || value
            ? { top: 10, color: "#8D8E8F", fontSize: 13 }
            : { top: 20, color: "#cccccc", fontSize: 17 }),
    }}>
            {label}
          </StyledText>
          <View style={{ flex: 1 }}>
            <TextInput {...inputProps} ref={ref} style={{
        ...styles.input,
        ...(error ? { color: "#EE4A4A" } : {}),
        ...style,
    }} onFocus={() => {
        if (inputProps.onFocus)
            inputProps.onFocus();
        setFocused(true);
    }} onBlur={() => {
        if (inputProps.onBlur)
            inputProps.onBlur();
        setFocused(false);
    }} value={value}/>
            {!last && <View style={styles.separator}/>}
          </View>
        </View>
        {error && (<StyledText style={styles.errorText}>{error.message}</StyledText>)}
      </>);
});
Input.displayName = "Input";
export default Input;
