import React from 'react';
import { Animated, TouchableOpacity } from "react-native";
import { SafeAreaConsumer } from "react-native-safe-area-context";
import StyledText from '../StyledText';
import { connect } from "react-redux";
class FloatingAction extends React.PureComponent {
    constructor(props) {
        super(props);
        this.animateMenu = () => {
            Animated.timing(this.state.opacityAnim, {
                toValue: this.props.hidden ? 0 : 1,
                duration: 300,
                useNativeDriver: true
            }).start();
        };
        this.state = {
            hidden: props.hidden,
            opacityAnim: props.hidden
                ? new Animated.Value(0)
                : new Animated.Value(1)
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.hidden !== this.props.hidden) {
            this.animateMenu();
        }
    }
    render() {
        const { opacityAnim } = this.state;
        return (<SafeAreaConsumer>
        {(insets) => (<Animated.View style={{
            ...styles.container,
            bottom: Math.max(insets.bottom - 20, 0) + 20,
            opacity: opacityAnim,
            backgroundColor: this.props.services.app.manifest.themeColor
        }}>
            <TouchableOpacity onPress={this.props.onPress}>
              <StyledText style={styles.buttonText}>{this.props.children}</StyledText>
            </TouchableOpacity>
          </Animated.View>)}
      </SafeAreaConsumer>);
    }
}
const styles = {
    container: {
        position: "absolute",
        bottom: 20,
        alignSelf: "center",
        flexDirection: "row",
        backgroundColor: "#333333",
        paddingTop: 12,
        paddingBottom: 15,
        paddingLeft: 36,
        paddingRight: 36,
        borderRadius: 34,
    },
    button: {},
    buttonText: {
        fontSize: 25,
        fontWeight: '500',
        color: '#FFFFFF'
    }
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(FloatingAction);
