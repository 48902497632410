import React from "react";
// REACT NATIVE COMPONENTS
import { View } from "react-native";
// OWN ROOT COMPONENTS
import StyledText from '../StyledText';
// STYLING
const styles = {
    container: {
        justifyContent: 'center',
        alignItems: 'center'
    }
};
export default ({ user, themeColor = '#333', textStyle = {}, style, ...props }) => {
    return (<View style={{ ...styles.container, ...style, backgroundColor: themeColor }}>
      <StyledText style={{ color: '#fff', ...textStyle }}>{`${user.firstName
        .substr(0, 1)
        .toUpperCase()}${user.lastName
        .substr(0, 1)
        .toUpperCase()}`}</StyledText>
    </View>);
};
