import React, { useEffect, useState, useContext } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from './services/app/actions';
// Imports: Navigation
import { NavigationContainer, DefaultTheme, useTheme } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { navigationRef, navigate } from './RootNavigation';
import { FeathersContext, } from "./services/feathers/FeathersProvider";
import DeviceInfo from "react-native-device-info";
import LocaleProvider from './i18n';
import ContainerSwitch from './scenes/ContainerSwitch';
import Update from "./scenes/Update";
import FirstSteps from "./scenes/FirstSteps";
import Inbox from "./scenes/Inbox";
import Profile from "./scenes/Profile/index.tsx";
import SignIn from "./scenes/SignIn/index.tsx";
import SignUp from "./scenes/SignUp";
import Home from './scenes/Home/index.tsx';
import Terms from './scenes/Terms/index';
import UserList from "./scenes/UserList";
import UserProfile from "./scenes/UserProfile";
import VersionInfo from "./scenes/VersionInfo";
import Info from "./scenes/Info";
import Notifications from "./scenes/Notifications";
import FastPass from './scenes/FastPass';
// Imports: Modules
import Agenda from "./scenes/Modules/Agenda";
import AgendaDetail from './scenes/Modules/Agenda/AgendaDetail';
import Timeline from "./scenes/Modules/Timeline";
import Comments from "./scenes/Modules/Timeline/Comments";
import Likes from "./scenes/Modules/Timeline/Likes";
import NewPost from "./scenes/Modules/Timeline/NewPost";
import Questions from "./scenes/Modules/Questions";
import Static from "./scenes/Modules/Static";
import WebTab from "./scenes/Modules/WebTab";
import Gamification from "./scenes/Modules/Gamification";
import GamificationBlock from "./scenes/Modules/Gamification/Block";
import GamificationCompletedBlock from "./scenes/Modules/Gamification/CompletedBlock";
import Voting from "./scenes/Modules/Voting";
import VotingBlock from "./scenes/Modules/Voting/Block";
import { BelongAlert } from './components/BelongAlert';
const RootStack = createStackNavigator();
const InboxStack = createStackNavigator();
const LoginStack = createStackNavigator();
const MainStack = createStackNavigator();
const LoginStackNavigator = () => {
    return (<LoginStack.Navigator screenOptions={{ headerShown: false, cardStyle: { flex: 1 } }}>
      <LoginStack.Screen name="SignIn" component={SignIn}/>
      <LoginStack.Screen name="SignUp" component={SignUp}/>
      <LoginStack.Screen name="FirstSteps" component={FirstSteps}/>
    </LoginStack.Navigator>);
};
const InboxStackNavigator = () => {
    return (<InboxStack.Navigator screenOptions={{ headerShown: false, cardStyle: { flex: 1 }, presentation: 'transparentModal' }}>

      <InboxStack.Group screenOptions={{ presentation: 'transparentModal' }}>
        <InboxStack.Screen name="Inbox" component={Inbox}/>
        <InboxStack.Screen name="Profile" component={Profile}/>
        <InboxStack.Screen name="Notifications" component={Notifications}/>
        <InboxStack.Screen name="UserProfile" component={UserProfile}/>
      </InboxStack.Group>
      <InboxStack.Screen name="UserList" component={UserList}/>

      <InboxStack.Screen name="VersionInfo" component={VersionInfo}/>
      <InboxStack.Screen name="Info" component={Info}/>
      <InboxStack.Screen name="FastPass" component={FastPass}/>
    </InboxStack.Navigator>);
};
const StackNavigator = () => {
    return (<MainStack.Navigator screenOptions={{ headerShown: false, cardStyle: { flex: 1 } }}>
      <MainStack.Screen name="Home" component={Home}/>
      <MainStack.Screen name="Module_timelineModules" component={Timeline}/>
      <MainStack.Screen name="Module_questionsModules" component={Questions}/>
      <MainStack.Group screenOptions={{ presentation: 'transparentModal' }}>
        <MainStack.Screen name="NewPost" component={NewPost}/>
        <MainStack.Screen name="Likes" component={Likes}/>
      </MainStack.Group>

      <MainStack.Screen name="Comments" component={Comments}/>

      <MainStack.Screen name="Module_staticModules" component={Static}/>
      <MainStack.Screen name="Module_webtabModules" component={WebTab}/>
      <MainStack.Screen name="Module_gamingModules" component={Gamification}/>
      <MainStack.Screen name="GamificationBlock" component={GamificationBlock}/>
      <MainStack.Screen name="GamificationCompletedBlock" component={GamificationCompletedBlock}/>
      <MainStack.Screen name="Module_votingModules" component={Voting}/>
      <MainStack.Screen name="VotingBlock" component={VotingBlock}/>
      <MainStack.Screen name="UserProfile" component={UserProfile}/>
      <MainStack.Screen name="Module_agendaModules" component={Agenda}/>
      <MainStack.Screen name="AgendaDetail" component={AgendaDetail}/>
      <MainStack.Screen name="Module_userListModules" component={UserList}/>
      <MainStack.Screen name="Module_fastpassModules" component={FastPass}/>
    </MainStack.Navigator>);
};
const NavigationWrap = (props) => {
    const feathersContext = useContext(FeathersContext);
    const [isSwitching, setIsSwitching] = useState(false);
    const [navReady, setNavReady] = useState(false);
    const [pendingNav, setPendingNav] = useState(false);
    const { colors } = useTheme();
    colors.background = "transparent";
    useEffect(() => {
    }, []);
    useEffect(() => {
        if (props.services.app.notifications.length > 0) {
            props.services.app.notifications.map(n => {
                //alert(JSON.stringify(n));
                //navigate('Module_questionsModules')
                let action = n.data.action;
                if (action && typeof action == 'string') {
                    action = JSON.parse(action);
                }
                if (action && action.type && action.type == 'module' && n.userInteraction) {
                    const id = action.moduleId.toString();
                    const modules = props.services.app.manifest.modules
                        .filter((module) => module._id.toString() == id)
                        .filter((module) => module.visibility.everyone
                        ? true
                        : module.visibility.audiences.filter(x => props.services.app.feathersUser.audiences && props.services.app.feathersUser.audiences.includes(x)).length > 0);
                    if (modules.length > 0) {
                        const module = modules[0];
                        safeNavigate(`Module_${module._type}`, {
                            module,
                        });
                    }
                    else {
                    }
                }
            });
            setTimeout(() => {
                props.actions.app.removeNotifications();
            }, 3000);
        }
    }, [props.services.app.notifications]);
    const _onReady = () => {
        setNavReady(true);
        if (pendingNav) {
            navigate(pendingNav[0], pendingNav[1]);
            setPendingNav(false);
        }
    };
    const safeNavigate = (route, params) => {
        if (navReady) {
            navigate(route, params);
        }
        else {
            setPendingNav([route, params]);
        }
    };
    let version = DeviceInfo.getVersion();
    //console.log("USER", props.services.app.feathersUser);
    const MyTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            primary: 'rgb(255, 45, 85)',
        },
    };
    const linking = {
        prefixes: [
        /* your linking prefixes */
        ],
        config: {
            screens: {
                StackNavigator: {
                    screens: {
                        Home: `${props.slug}/home`,
                        Module_agendaModules: `${props.slug}/agenda/:moduleId`,
                        AgendaDetail: `${props.slug}/agenda/:moduleId/appointment/:itemId`,
                        Module_timelineModules: `${props.slug}/timeline/:moduleId`,
                        Module_questionsModules: `${props.slug}/questions/:moduleId`,
                        NewPost: `${props.slug}/timeline/:moduleId/new-post`,
                        Comments: `${props.slug}/timeline/:moduleId/comments`,
                        Likes: `${props.slug}/timeline/:moduleId/likes`,
                        Module_staticModules: `${props.slug}/static/:moduleId`,
                        Module_webtabModules: `${props.slug}/webtab/:moduleId`,
                        Module_gamingModules: `${props.slug}/gaming/:moduleId`,
                        GamificationBlock: `${props.slug}/gaming/:moduleId/block/:blockId`,
                        GamificationCompletedBlock: `${props.slug}/gaming/:moduleId/block/:blockId/completed`,
                        Module_votingModules: `${props.slug}/voting/:moduleId`,
                        VotingBlock: `${props.slug}/voting/:moduleId/block/:blockId`,
                        UserProfile: `${props.slug}/users/:userId`,
                        Module_userListModules: `${props.slug}/userList/:moduleId`,
                    }
                }
            },
        },
    };
    return (<LocaleProvider>
      {isSwitching ?
        <ContainerSwitch dismiss={() => setIsSwitching(false)}/>
        :
            <React.Fragment>
          <NavigationContainer /*linking={linking}*/ theme={MyTheme} ref={navigationRef} onReady={_onReady}>
            {props.services.app.manifest.deprecations.includes(version) ? (<Update />) : (<RootStack.Navigator screenOptions={{ presentation: 'transparentModal' }}>
                {props.services.app.feathersAuthenticated ||
                !props.services.app.manifest.accessOptions.private ? (props.services.app.feathersUser.tcAccepted || !props.services.app.feathersAuthenticated ?
                <RootStack.Group screenOptions={{ presentation: 'transparentModal' }}>
                      <RootStack.Screen name="StackNavigator" component={StackNavigator} options={{ headerShown: false }}/>

                      <RootStack.Screen name="InboxStack" component={InboxStackNavigator} options={{ headerShown: false }}/>
                      <RootStack.Screen name="LoginStack" component={LoginStackNavigator} options={{ headerShown: false }}/>
                    </RootStack.Group>
                :
                    <RootStack.Screen name="Terms" component={Terms} options={{ headerShown: false }}/>) : (<React.Fragment>
                    <RootStack.Screen name="LoginStack" component={LoginStackNavigator} options={{ headerShown: false }}/>
                  </React.Fragment>)}
              </RootStack.Navigator>)}
          </NavigationContainer>
          <BelongAlert />
        </React.Fragment>}
    </LocaleProvider>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), dispatch => ({
    actions: {
        app: bindActionCreators(appActionCreators, dispatch)
    }
}))(NavigationWrap);
