import * as actionTypes from "./actionTypes";
export const update = app => ({
    type: actionTypes.UPDATE,
    app,
});
export const set_slug = slug => ({
    type: actionTypes.SET_SLUG,
    slug,
});
const update_role = role => ({
    type: actionTypes.UPDATE_ROLE,
    role
});
export const set_active_module = activeModule => ({
    type: actionTypes.SET_ACTIVE_MODULE,
    activeModule
});
export const addNotification = notification => ({
    type: actionTypes.ADD_NOTIFICATION,
    notification
});
export const removeNotifications = () => ({
    type: actionTypes.REMOVE_NOTIFICATIONS
});
// export const set_role = role => dispatch =>
//   api.set_role(role).then(response => dispatch(update_role(role)));
