import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import BelongIcon from '../BelongIcon';
import { connect } from "react-redux";
import ScaledImage from '../ScaledImage';
import StyledText from '../StyledText';
const styles = {
    spacer: {
        height: 70
    },
    container: {
        height: 70,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
    },
    inner: {
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.05)',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    left: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    icon: {
        width: 40,
        height: 40,
        borderRadius: 10,
        marginLeft: 16
    },
    title: {
        marginLeft: 14,
        fontSize: 18,
        fontWeight: 'bold',
        color: '#ffffff'
    },
    right: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 18
    },
    menuButton: {
        marginHorizontal: 7,
        flexDirection: 'row',
        alignItems: 'center'
    },
    menuText: {
        color: "#ffffff",
        fontSize: 16,
        fontWeight: 'bold',
        opacity: .75,
        marginLeft: 8
    },
    itemIcon: {
        fontSize: 30,
        color: "#ffffff",
        opacity: .75,
    },
    userIcon: {
        width: 30,
        height: 30,
        borderRadius: 15,
    }
};
const BelongHeader = (props) => {
    return (<React.Fragment>
      <View style={styles.spacer}></View>
      <View style={[styles.container, { backgroundColor: props.services.app.manifest.themeColor }]}>
        <View style={styles.inner}>
          <TouchableOpacity onPress={() => {
        props.navigation.navigate("Home");
    }} style={styles.left}>
            <ScaledImage width={120} height={120} fit="cover" image={props.services.app.manifest.icon} style={styles.icon}/>
            <StyledText style={styles.title}>{props.services.app.manifest.title}</StyledText>
          </TouchableOpacity>
          <View style={styles.right}>
            {props.services.app.feathersUser._id && (<TouchableOpacity style={styles.menuButton} onPress={() => props.navigation.navigate("InboxStack", {
        screen: "Profile",
    })}>
                {props.services.app.feathersUser.picture ? (<ScaledImage width={240} height={240} fit="cover" image={props.services.app.feathersUser.picture} style={styles.userIcon}/>) : (<View style={{
        width: 30,
        height: 30,
        borderRadius: 15,
        backgroundColor: "#555",
    }}/>)}
                <StyledText style={styles.menuText}>{props.services.app.feathersUser.firstName} {props.services.app.feathersUser.lastName}</StyledText>
              </TouchableOpacity>)}
            <TouchableOpacity style={styles.menuButton} onPress={() => props.navigation.navigate("InboxStack", {
        screen: "Notifications",
    })}>
              <BelongIcon name="sm-bell" style={styles.itemIcon}></BelongIcon>
            </TouchableOpacity>

            <TouchableOpacity style={styles.menuButton} onPress={() => props.navigation.navigate("InboxStack", {
        screen: "Inbox",
    })}>
              <BelongIcon name="sm-menu" style={styles.itemIcon} onPress={() => props.navigation.navigate("InboxStack")}></BelongIcon>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </React.Fragment>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(BelongHeader);
