import React from 'react';
import LinearGradient from 'react-native-web-linear-gradient';
export default props => {
    const hexToRgbA = (hex) => {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0)';
        }
        throw new Error('Bad Hex');
    };
    const rgba = hexToRgbA(props.themeColor);
    return (<LinearGradient locations={[0, 0.45, 0.6]} colors={[props.isSelected ? props.themeColor : 'transparent', props.isSelected ? props.themeColor : 'transparent', rgba]} style={props.style}>
      {props.children}
    </LinearGradient>);
};
