import React from 'react';
import { TouchableOpacity } from 'react-native';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import apiConfig from "../../../../services/api/config";
const UploadHandler = props => {
    const { t, i18n } = useTranslation();
    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);
    // HANDLE DOCUMENT PICK
    const handleDocumentPick = () => {
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        console.log(event.target.files[0]);
        props.setLoading(true);
        let url = "";
        const formData = new FormData();
        if (fileUploaded.type.includes("image")) {
            formData.append("uri", fileUploaded);
            url = `${apiConfig.url[props.app.env]}uploads`;
        }
        else {
            props.setLoading(false);
        }
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent) {
                props.setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            },
        };
        const fileType = fileUploaded.type;
        axios
            .post(url, formData, config)
            .then((ar) => {
            if (fileType.includes("image")) {
                props.setImageRef(ar.data.id);
                props.setValue("picture", ar.data.id);
            }
            props.setLoading(false);
        })
            .catch((e) => {
            console.log("Axios error", e.response);
            props.setLoading(false);
        });
    };
    return (<React.Fragment>
        <TouchableOpacity style={props.style} onPress={() => handleDocumentPick()}>
          {props.children}
        </TouchableOpacity>
        <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }}/>
    </React.Fragment>);
};
export default UploadHandler;
