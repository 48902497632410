import React, { useState, useEffect } from "react";
// REACT NATIVE COMPONENTS
import { StyleSheet, View, ScrollView } from "react-native";
// OWN ROOT COMPONENTS
import CollapsibleWrapper from "../../components/CollapsibleWrapper";
import CollapsibleHeader from "../../components/CollapsibleHeader";
import StyledText from "../../components/StyledText";
// WRAPPERS AND CONTEXT CREATORS
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import { useAnalytics } from '../../Analytics';
import { useTranslation } from 'react-i18next';
import AsyncStorage from "@react-native-community/async-storage";
import jwt_decode from "jwt-decode";
import moment from "moment-timezone";
// STYLING
const styles = StyleSheet.create({
    title: {
        fontSize: 30,
        fontWeight: "500",
        color: "#333333",
        marginTop: 5,
    },
    list: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: "#ffffff",
        flex: 1,
    },
});
const VersionInfo = (props) => {
    const [updateStatus, setUpdateStatus] = useState(null);
    const [info, setInfo] = useState(null);
    const [token, setToken] = useState(null);
    const { setMixpanelUser, trackMixpanelEvent } = useAnalytics();
    const { t, i18n } = useTranslation();
    const update = () => {
        trackMixpanelEvent("checkUpdates");
    };
    useEffect(() => {
    }, []);
    const getToken = async () => {
        try {
            const value = await AsyncStorage.getItem(`ubelong_${props.services.app.manifest.slug}_jwt`);
            if (value !== null) {
                return value;
            }
            else {
                return null;
            }
        }
        catch (e) {
            alert(e);
        }
    };
    getToken().then(t => setToken(t));
    return (<CollapsibleWrapper headerComponent={(disappearingOpacity, appearingOpacity) => (<CollapsibleHeader central={<StyledText tkey="version.title" variant="h1" style={styles.title}>
              Info. Versión
            </StyledText>} backAction={() => props.navigation.goBack()} 
    // mainAction={() => {}}
    // mainActionTitle="Buscar"
    disappearingOpacity={disappearingOpacity} appearingOpacity={appearingOpacity} themeColor={props.services.app.manifest.themeColor}/>)} backgroundColor="#EEEFF3" bodyBackgroundColor="#FFFFFF" headerComponentStyle={{
        backgroundColor: "#EEEFF3",
        paddingHorizontal: 15,
    }} bodyComponentStyle={{
        flex: 1,
    }} scrollViewOverride={(onScroll, scrollEventThrottle, contentContainerPadding) => (<View style={{
        ...styles.list,
    }}>
          <ScrollView contentContainerStyle={{ paddingTop: contentContainerPadding, alignItems: 'center' }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle}>
            
            <StyledText style={{ marginTop: 20 }}>
              V: {info ? info.label : 'v0'}
            </StyledText>
            <StyledText style={{ marginTop: 5 }}>
              {updateStatus ?
        JSON.stringify(updateStatus)
        :
            t('version.noUpdate')}
            </StyledText>

            {token != null ?
        <React.Fragment>
                <StyledText>Issued at {moment(jwt_decode(token).iat * 1000).format('dddd D, HH:mm')}</StyledText>
                <StyledText>Expires {moment(jwt_decode(token).exp * 1000).format('dddd D, HH:mm')}</StyledText>
              </React.Fragment>
        :
            null}



          </ScrollView>

        </View>)}/>);
};
export default connect((state) => ({
    data: {},
    services: {
        app: state.services.app,
    },
}), null)(VersionInfo);
