import React, { useContext, useState, useEffect, useCallback } from "react";
// REACT NATIVE COMPONENTS
import { FlatList, View, TouchableWithoutFeedback } from "react-native";
// OWN ROOT COMPONENTS
import CollapsibleWrapper from "../../components/CollapsibleWrapper";
import CollapsibleHeader from "../../components/CollapsibleHeader";
import StyledText from "../../components/StyledText";
import ListItem from "./components/ListItem";
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as notificationsActionCreators from "../../data/notifications/actions";
// OTHER UTILS
import { useMediaQuery } from 'react-responsive';
// STYLING
const styles = {
    backdrop: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    },
    container: {
        backgroundColor: "#EEEFF3",
        flex: 1,
        position: "relative",
    },
    containerBig: {
        width: '100%',
        maxWidth: 400,
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 4,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
    },
    title: {
        fontSize: 30,
        fontWeight: "500",
        color: "#333333",
        marginTop: 5,
    },
    list: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: "#ffffff",
        flex: 1,
    },
    searchContainer: {
        marginHorizontal: 15,
        paddingHorizontal: 10,
        paddingVertical: 10,
    },
    searchBox: {
        fontFamily: "Matter",
        fontSize: 16,
        paddingVertical: 5,
        color: "#444444",
    },
    headerContainer: {
        marginHorizontal: 15,
        paddingHorizontal: 10,
        paddingVertical: 10,
    },
    headerTitle: {
        fontSize: 14,
        color: "#8D8E8F",
    },
};
const UserList = (props) => {
    const [query, setQuery] = useState("");
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    const isBig = useMediaQuery({ query: '(min-width: 800px)' });
    const initialLoad = useCallback(() => {
        feathersContext
            .find("users", {
            query: {
                invisible: false,
                $sort: {
                    lastName: 1,
                },
            },
        })
            .catch((e) => {
            console.log("Error", e);
        });
    }, []);
    const onEndReached = useCallback((info) => {
        const items = props.data.notifications.items;
        if (items.length < props.data.notifications.total) {
            feathersContext
                .findAndAppend("notifications", {
                query: {
                    sent: true,
                    $skip: items.length,
                    $sort: { sentAt: -1 }
                },
            })
                .catch((e) => {
                console.log("Error", e);
            });
        }
    }, [props.data.notifications]);
    useEffect(() => {
        initialLoad();
    }, []);
    return (<TouchableWithoutFeedback onPress={() => props.navigation.goBack()}>
      <View style={styles.backdrop}>
        <View style={{ ...styles.container, ...(isBig ? styles.containerBig : {}) }}>
          <CollapsibleWrapper isSidebar headerComponent={(disappearingOpacity, appearingOpacity) => (<CollapsibleHeader central={<StyledText tkey="notifications.title" variant="h1" style={styles.title}>
                    Notificaciones
                  </StyledText>} backAction={() => props.navigation.goBack()} 
    // mainAction={() => props.actions.notifications.readAll()}
    // mainActionTitle="Leer todas"
    disappearingOpacity={disappearingOpacity} appearingOpacity={appearingOpacity} themeColor={props.services.app.manifest.themeColor}/>)} backgroundColor="#EEEFF3" bodyBackgroundColor="#FFFFFF" headerComponentStyle={{
        backgroundColor: "#EEEFF3",
        paddingHorizontal: 15,
    }} bodyComponentStyle={{
        flex: 1,
    }} scrollViewOverride={(onScroll, scrollEventThrottle, contentContainerPadding) => (<View style={{
        ...styles.list,
    }}>
                <FlatList contentContainerStyle={{ paddingTop: contentContainerPadding }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle} keyExtractor={(item, index) => item._id} onEndReached={onEndReached} onEndReachedThreshold={0.1} style={{}} data={props.data.notifications.items} renderItem={({ key, item, index }) => (<ListItem key={key} item={item} unread={!props.data.notifications.readIds.includes(item._id.toString())} themeColor={props.services.app.manifest.themeColor} onPress={() => {
        props.actions.notifications.readItem(item);
        if (item.action && item.action.type && item.action.type == 'module') {
            const id = item.action.moduleId.toString();
            const modules = props.services.app.manifest.modules
                .filter((module) => module._id.toString() == id)
                .filter((module) => module.visibility.everyone
                ? true
                : module.visibility.audiences.filter(x => props.services.app.feathersUser.audiences && props.services.app.feathersUser.audiences.includes(x)).length > 0);
            if (modules.length > 0) {
                const module = modules[0];
                props.navigation.navigate(`Module_${module._type}`, {
                    module,
                });
            }
        }
    }}/>)}/>
              </View>)}/>
        </View>
      </View>
    </TouchableWithoutFeedback>);
};
export default connect((state) => ({
    data: {
        notifications: state.data.notifications
    },
    services: {
        app: state.services.app,
    },
}), dispatch => ({
    actions: {
        notifications: bindActionCreators(notificationsActionCreators, dispatch)
    }
}))(UserList);
