import React, { createContext, useContext, useState } from "react";
import { View, Text } from 'react-native';
import BelongButton from "../BelongButton";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
const AlertContext = createContext(null);
AlertContext.displayName = 'AlertContext';
export { AlertContext };
export const useAlert = () => useContext(AlertContext);
// STYLING
const styles = {
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.9)',
        zIndex: 999,
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center'
    },
    alert: {
        backgroundColor: '#FFFFFF',
        borderRadius: 15,
        padding: 20,
        width: '90%',
        maxWidth: 600
    },
    title: {
        fontFamily: "Matter",
        fontWeight: "500",
        fontSize: 25,
        color: "#4b4b4b"
    },
    body: {
        fontFamily: "Matter",
        fontSize: 13,
        color: '#8f8f8f',
        marginTop: 10
    },
    buttons: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    button: {
        marginRight: 5
    }
};
const _BelongAlert = props => {
    const alert = useAlert();
    return (alert.showAlert ?
        <View style={styles.overlay}>
      <View style={styles.alert}>
        <Text style={styles.title}>{alert.title}</Text>
        <Text style={styles.body}>{alert.body}</Text>
        <View style={styles.buttons}>
          {alert.actions.map(a => (<BelongButton style={styles.button} color={props.services.app.manifest.themeColor} onPress={() => {
            a.onPress();
            alert.reset();
        }}>
              {a.text}
            </BelongButton>))}
          {alert.actions.length == 0 ?
            <BelongButton onPress={() => { alert.reset(); }} tkey="alert.ok"></BelongButton> : null}
        </View>

      </View>
    </View> : null);
};
export const BelongAlert = connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(_BelongAlert);
export default ({ children }) => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState(null);
    const [alertBody, setAlertBody] = useState(null);
    const [alertActions, setAlertActions] = useState([]);
    const [alertOptions, setAlertOptions] = useState({});
    return (<AlertContext.Provider value={{
        showAlert: showAlert,
        title: alertTitle,
        body: alertBody,
        actions: alertActions,
        options: alertOptions,
        alert: (title, body = '', actions = [], options = {}) => {
            setAlertTitle(title);
            setAlertBody(body);
            setAlertActions(actions);
            setAlertOptions(options);
            setShowAlert(true);
        },
        reset: () => {
            setAlertTitle(null);
            setAlertBody(null);
            setAlertActions([]);
            setAlertOptions({});
            setShowAlert(false);
        }
    }}>
      {children}
    </AlertContext.Provider>);
};
