import React, { useCallback, useEffect, useState, useContext } from 'react';
// REACT NATIVE COMPONENTS
import { StyleSheet, FlatList } from "react-native";
// OWN ROOT COMPONENTS
import RankingUser from '../../../../../components/RankingUser';
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
// STYLING
const styles = StyleSheet.create({});
const Component = props => {
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState([]);
    // Load Feathers context
    const feathersContext = useContext(FeathersContext);
    const initialLoad = useCallback(() => {
        feathersContext
            .onlyFind("users", {
            query: {
                hideFromRanking: { $ne: true },
                points: { $gt: 0 },
                $sort: {
                    points: -1,
                    lastName: 1
                },
            },
        })
            .then(r => {
            setUsers(r.data);
            setTotalUsers(r.total);
            //alert(JSON.stringify(r.data));
        })
            .catch((e) => {
            console.log("Error", e);
        });
    }, []);
    useEffect(() => {
        initialLoad();
    }, []);
    const onEndReached = useCallback((info) => {
        if (users.length < totalUsers) {
            feathersContext
                .onlyFind("users", {
                query: {
                    appId: props.services.app.slug,
                    hideFromRanking: { $ne: true },
                    points: { $gt: 0 },
                    $sort: {
                        points: -1,
                        lastName: 1
                    },
                    $skip: users.length,
                },
            })
                .then(r => {
                setUsers(u => [...u, ...r.data]);
                setTotalUsers(r.total);
            })
                .catch((e) => {
                console.log("Error", e);
            });
        }
    }, [users, totalUsers]);
    return (<FlatList {...props} style={{ flex: 1, backgroundColor: '#FFF' }} contentContainerStyle={{ ...props.contentContainerStyle, ...styles.contentContainer, paddingTop: props.contentContainerStyle.paddingTop + 20 }} showsVerticalScrollIndicator={false} data={users} renderItem={({ key, item, index }) => (<RankingUser key={key} index={index} user={item} themeColor={props.services.app.manifest.themeColor} onPress={() => handleItemPress(item)}/>)} keyExtractor={(item) => item._id} onEndReached={onEndReached} onEndReachedThreshold={0.1}/>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
