import React, { Suspense, useEffect, useRef, useCallback, useContext, createContext, useState } from 'react';
import i18n from 'i18next';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import { getLocales } from 'react-native-localize';
import Backend from 'i18next-locize-backend';
import Splash from '../scenes/Splash';
import moment from "moment-timezone";
import 'moment/locale/es';
import 'moment/locale/ca';
import 'moment/locale/de';
import 'moment/locale/da';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/nl';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/sv';
import { connect } from "react-redux";
const LocaleContext = createContext(null);
export { LocaleContext };
export const useLocale = () => useContext(LocaleContext);
export { moment };
const LocaleProvider = ({ children, ...props }) => {
    const [localeReady, setLocaleReady] = useState(false);
    const loadedI18n = useRef();
    useEffect(() => {
        i18n
            .use(Backend)
            .use(initReactI18next)
            .init({
            interpolation: {
                escapeValue: false,
            },
            //lng: getLocales()[0].languageCode,
            lng: workingLocale(),
            fallbackLng: props.services.app.manifest.langPattern.default,
            // initImmediate: true,
            react: {
                useSuspense: true,
            },
            backend: {
                projectId: 'e6101c6f-4138-4445-bc62-0fc62febff58',
                apiKey: '40c3abda-1e75-45c9-be13-b35c687d2980',
                referenceLng: 'es'
            },
        });
        loadedI18n.current = i18n;
        moment.locale(workingLocale());
        setLocaleReady(true);
    }, [props.services.app.manifest]);
    const workingLocale = useCallback(() => {
        const deviceLocales = getLocales().map(dl => dl.languageCode);
        for (let locale of deviceLocales) {
            if (props.services.app.manifest.langPattern.available.includes(locale))
                return locale;
            else
                return props.services.app.manifest.langPattern.default;
        }
    }, [props.services.app.manifest]);
    const deviceLocale = () => {
        const deviceLocales = getLocales().map(dl => dl.languageCode);
        return deviceLocales[0];
    };
    return (<LocaleContext.Provider value={{ workingLocale, deviceLocale }}>
      <Suspense fallback={<Splash />}>
        <I18nextProvider i18n={loadedI18n.current}>
          {localeReady ? children : false}
        </I18nextProvider>
      </Suspense>
    </LocaleContext.Provider>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(LocaleProvider);
