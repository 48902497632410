import React, { useRef } from "react";
import { SafeAreaInsetsContext } from "react-native-safe-area-context";
import { View, Animated, ScrollView, } from "react-native";
import { useMediaQuery } from 'react-responsive';
const CollapsibleHeader = (props) => {
    const isBig = useMediaQuery({ query: '(min-width: 800px)' });
    const H_MAX_HEIGHT = isBig ? (props.isSidebar ? 200 : 100) : 265;
    const H_MIN_HEIGHT = isBig ? (props.isSidebar ? 200 : 100) : 56;
    const H_SCROLL_DISTANCE = H_MAX_HEIGHT - H_MIN_HEIGHT;
    const scrollOffsetY = useRef(new Animated.Value(0)).current;
    const headerScrollHeight = scrollOffsetY.interpolate({
        inputRange: [0, H_SCROLL_DISTANCE],
        outputRange: [H_MAX_HEIGHT, H_MIN_HEIGHT],
        extrapolate: "clamp",
    });
    const disappearingOpacity = scrollOffsetY.interpolate({
        inputRange: [
            0,
            isBig ? 0 : H_SCROLL_DISTANCE / 2 - 40,
            isBig ? 0 : H_SCROLL_DISTANCE / 2 + 40,
            H_SCROLL_DISTANCE,
        ],
        outputRange: isBig ? [1, 1, 1, 1] : [1, 1, 0, 0],
        extrapolate: "clamp",
    });
    const appearingOpacity = scrollOffsetY.interpolate({
        inputRange: [
            0,
            isBig ? 0 : H_SCROLL_DISTANCE / 2 - 40,
            isBig ? 0 : H_SCROLL_DISTANCE / 2 + 40,
            H_SCROLL_DISTANCE,
        ],
        outputRange: isBig ? [1, 1, 1, 1] : [0, 0, 1, 1],
        extrapolate: "clamp",
    });
    const onScroll = Animated.event([
        { nativeEvent: { contentOffset: { y: scrollOffsetY } } },
    ]);
    return (<SafeAreaInsetsContext.Consumer>
      {(insets) => (<View style={{ flex: 1, backgroundColor: props.backgroundColor }}>
          {props.scrollViewOverride ? props.scrollViewOverride(onScroll, 16, H_MAX_HEIGHT + insets.top) : (<ScrollView onScroll={onScroll} scrollEventThrottle={16} contentContainerStyle={{ flex: 1 }}>
              <View style={{
        ...{
            paddingTop: H_MAX_HEIGHT + insets.top,
        },
        ...props.bodyComponentStyle,
    }}>
                <View style={{
        backgroundColor: props.bodyBackgroundColor,
        flex: 1,
        borderTopRightRadius: 30,
        borderTopLeftRadius: 30,
        paddingBottom: insets.bottom,
    }}>
                  {props.children}
                </View>
              </View>
            </ScrollView>)}

          
          <View style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        height: insets.top,
        width: "100%",
        overflow: "hidden",
        zIndex: 999,
        backgroundColor: props.headerComponentStyle.backgroundColor || "black",
    }}></View>
          <Animated.View style={{
        ...{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0 + insets.top,
            height: headerScrollHeight,
            width: "100%",
            overflow: "hidden",
            zIndex: 999,
            // STYLE
            borderBottomColor: "#EFEFF4",
            borderBottomWidth: 0,
            padding: 0,
            backgroundColor: "blue",
        },
        ...props.headerComponentStyle,
    }}>
            {props.headerComponent(disappearingOpacity, appearingOpacity)}
          </Animated.View>
        </View>)}
    </SafeAreaInsetsContext.Consumer>);
};
export default CollapsibleHeader;
