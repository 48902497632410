import React from 'react';
import { View, ScrollView, TouchableWithoutFeedback } from "react-native";
import ReactMarkdown from 'react-markdown';
import SmallHeader from "../../components/SmallHeader";
import MediaQuery, { useMediaQuery } from 'react-responsive';
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// STYLING
const styles = {
    backdrop: {
        backgroundColor: 'rgba(0,0,0,0.1)',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden'
    },
    container: {
        flex: 1,
        position: "relative",
    },
    containerBig: {
        position: 'absolute',
        overflow: 'hidden',
        width: '100%',
        maxWidth: 600,
        height: 600,
        alignSelf: 'center',
        bottom: -10,
        borderRadius: 10,
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 4,
    },
    markdown: {
        flex: 1,
        backgroundColor: '#FFFFFF',
        paddingHorizontal: 15,
    }
};
const Info = props => {
    const isBig = useMediaQuery({ query: '(min-width: 800px)' });
    return (<TouchableWithoutFeedback onPress={() => props.navigation.goBack()}>
      <View style={styles.backdrop}>
        <View style={{ ...styles.container, ...(isBig ? styles.containerBig : {}) }}>
          <MediaQuery maxWidth={799}>
            <SmallHeader backAction={() => props.navigation.goBack()} title={props.route.params.title} themeColor={props.services.app.manifest.themeColor}/>
          </MediaQuery>
          <ScrollView style={styles.markdown}>
            <ReactMarkdown style={{ fontFamily: 'Matter', flex: 1 }} children={props.route.params.content.replace(/\n/gi, '  \n') + props.route.params.content.replace(/\n/gi, '  \n') + props.route.params.content.replace(/\n/gi, '  \n') + props.route.params.content.replace(/\n/gi, '  \n')} skipHtml={false} allowDangerousHtml/>
          </ScrollView>

        </View>
      </View>
    </TouchableWithoutFeedback>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Info);
