import React from "react";
import { StyleSheet, View } from "react-native";
import FormGroup from "./components/FormGroup";
import Input from "./components/Input/index.tsx";
import RadioGroup from "./components/RadioGroup";
import { Controller } from "react-hook-form";
const styles = StyleSheet.create({
    container: {
        alignSelf: "stretch",
    },
});
const Form = ({ control, errors, validation, children }) => {
    const Inputs = React.useRef([]);
    let inputIndex = -1;
    // React.useEffect(() => {
    //   (Array.isArray(children) ? [...children] : [children]).forEach((child) => {
    //     if (child.type.displayName == "FormGroup2") {
    //       (Array.isArray(child.props.children)
    //         ? [...child.props.children]
    //         : [child.props.children]
    //       ).forEach((child) => {
    //         register({ name: child.props.name }, validation[child.props.name]);
    //       });
    //     }
    //   });
    // }, [register]);
    // let report = "";
    // (Array.isArray(children) ? [...children] : [children]).map((child, i) => {
    //   report += "\n" + child.type.displayName;
    //   if (child.type.displayName == "FormGroup2") {
    //     (Array.isArray(child.props.children)
    //       ? [...child.props.children]
    //       : [child.props.children]
    //     ).map((child, j) => {
    //       report += `\n- ${child.props.name} ${child.type.displayName}`;
    //     });
    //   }
    // });
    // Alert.alert("Formdata", report);
    return (<>
      {(Array.isArray(children) ? [...children] : [children]).map((child, i) => {
        //console.log("Iterating item", child.type.name);
        return child.type.displayName == "FormGroup2"
            ? React.createElement(child.type, { ...child.props, key: `group-${i}` }, (Array.isArray(child.props.children)
                ? [...child.props.children]
                : [child.props.children]).map((child, j) => {
                inputIndex++;
                //return <Input {...child.props} placeholder="HOLA" />;
                return child.props.name ? (<Controller control={control} render={({ onChange, onBlur, value }) => React.createElement(child.type, {
                    ...{
                        ...child.props,
                        ref: (e) => {
                            Inputs.current[inputIndex] = e;
                        },
                        control: control,
                        onBlur: onBlur,
                        onChangeText: (value) => onChange(value),
                        value: value,
                        onSubmitEditing: () => {
                            console.log("THE INPUTS", Inputs.current);
                            if (!child.props.multiline) {
                                Inputs.current[inputIndex + 1]
                                    ? Inputs.current[inputIndex + 1].focus()
                                    : Inputs.current[inputIndex].blur();
                            }
                        },
                        blurOnSubmit: false,
                        error: errors[child.props.name],
                    },
                })} name={child.props.name} rules={validation[child.props.name]} defaultValue={child.props.defaultValue}/>) : (child.props.children);
            }))
            : child.props.children;
    })}
    </>);
    return <View style={styles.container}>{props.children}</View>;
};
export { Form, FormGroup, Input, RadioGroup };
