import React from "react";
// REACT NATIVE COMPONENTS
import { View, TouchableWithoutFeedback, Dimensions } from "react-native";
// OWN ROOT COMPONENTS
import BelongIcon from "../../../../../components/BelongIcon";
import StyledText from "../../../../../components/StyledText";
import ScaledImage from "../../../../../components/ScaledImage";
import PostContent from "./components/PostContent";
import Video from "./components/Video";
import UserPostSheet from "./components/UsetPostSheet";
import { TouchableOpacity } from "react-native-gesture-handler";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
const deviceWidth = Dimensions.get("window").width;
import Moment from "react-moment";
import "moment-timezone";
import axios from "axios";
import apiConfig from "../../../../../services/api/config";
import { LocaleContext } from '../../../../../i18n';
import { useMediaQuery } from 'react-responsive';
// STYLING
const styles = {
    post: {
        backgroundColor: "#ffffff",
        marginBottom: 15,
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 4,
        paddingTop: 25,
    },
    postBig: {
        borderRadius: 10,
        marginBottom: 25
    },
    userHeader: {
        flexDirection: "row",
        paddingHorizontal: 20,
    },
    userPic: {
        width: 35,
        height: 35,
        borderRadius: 17.5,
        marginRight: 9,
        marginTop: 2
    },
    dummyPic: {
        width: 35,
        height: 35,
        borderRadius: 17.5,
        marginRight: 9,
        justifyContent: "center",
        alignItems: "center",
    },
    dummyText: {
        fontSize: 13,
        fontWeight: "500",
        color: "#fff",
    },
    userInfo: {
        flex: 1,
    },
    userName: {
        fontSize: 17,
        color: "#333333",
        fontWeight: "500",
    },
    userSubtitle: {
        fontSize: 13,
        color: "#8D8E8F",
        paddingTop: 0,
    },
    pinnedIcon: {
        fontSize: 30,
        color: "#CACACA",
    },
    date: {
        fontSize: 13,
        color: "#CACACA",
    },
    picWrap: {
        marginTop: 20,
    },
    videoWrap: {
        marginTop: 20,
        position: "relative",
    },
    video: {
        width: deviceWidth,
        height: deviceWidth
    },
    mutedIndicator: {
        position: "absolute",
        top: 8,
        right: 8,
        width: 32,
        height: 32,
        borderRadius: 16,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255,255,255,0.5)",
    },
    postInner: {
        marginTop: 20,
        paddingHorizontal: 20,
    },
    translateButton: {
        marginTop: 10
    },
    translateText: {
        fontSize: 13,
        color: '#CACACA'
    },
    postText: {
        fontSize: 16,
        lineHeight: 24,
        color: "#444444",
    },
    interactionArea: {
        marginTop: 15,
        paddingHorizontal: 15,
    },
    commentInfo: {
        color: "#CACACA",
        fontSize: 13,
        marginLeft: 5,
    },
    buttonArea: {
        paddingHorizontal: 5,
        marginTop: 13,
        borderTopWidth: 1,
        borderTopColor: "#E9E9E9",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    buttonAreaLeft: {
        flexDirection: "row",
    },
    buttonAreaRight: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    interactionButton: {
        flexDirection: "row",
        marginRight: 15,
        paddingTop: 10,
        paddingBottom: 12,
    },
    interactionIcon: {
        color: "#8D8E8F",
        fontSize: 20,
        marginRight: 5,
    },
    interactionText: {
        fontSize: 15,
        color: "#98999A",
        fontWeight: "500",
    },
};
const withHooksHOC = (Component) => {
    return (props) => {
        const isBig = useMediaQuery({ query: '(min-width: 800px)' });
        return <Component isBig={isBig} {...props}/>;
    };
};
class TimelineItem extends React.Component {
    constructor(props) {
        super(props);
        this.showActions = () => {
            // let options = [];
            // if (this.props.isMine) {
            //   options.push(
            //     { text: this.props.t("timeline.editPost"), onPress: () => this.props.editPost() },
            //   );
            //   options.push(
            //     { text: this.props.t("timeline.deletePost"), onPress: () => this.props.deletePost() },
            //   );
            // }
            // ActionSheet.options({
            //   options: options,
            //   cancel: { onPress: () => console.log('cancel') }
            // })
            this.actionSheetRef.show();
        };
        this.translate = (text, locale) => axios.post(`${apiConfig.url[this.props.services.app.env]}translations`, {
            text,
            target: locale
        });
        this.translateText = text => {
            this.setState({
                translating: true,
            });
            const that = this;
            this.translate(text, this.context.deviceLocale())
                .then(r => {
                this.setState({
                    translating: false,
                    translated: r.data.translated
                });
            })
                .catch(e => {
                console.log(e);
                this.setState({
                    translating: false,
                });
            });
        };
        this.state = {
            rendered: 1,
            translating: false,
            translated: false,
            liking: false
        };
    }
    componentDidMount() {
        if (this.props.getRef)
            this.props.getRef(this);
    }
    componentWillUnmount() {
        if (this.video) {
            //this.video.unloadAsync();
        }
    }
    async play() {
        console.log("Viewable PLAY", this.video);
        this.video.setNativeProps("paused", false);
    }
    pause() {
        if (this.video) {
            this.video.setNativeProps("paused", true);
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.translating !== this.state.translating) {
            return true;
        }
        else if (nextState.translated !== this.state.translated) {
            return true;
        }
        else if (nextProps.item._id !== this.props.item._id) {
            return true;
        }
        else if (nextProps.viewable !== this.props.viewable) {
            return true;
        }
        else if (nextProps.muted !== this.props.muted) {
            return true;
        }
        else if (nextProps.item.userLikes !== this.props.item.userLikes) {
            return true;
        }
        else if (nextProps.item.updatedAt !== this.props.item.updatedAt) {
            return true;
        }
        else {
            return false;
        }
    }
    render() {
        const { item, viewable, muted, toggleMuted, themeColor, ...props } = this.props;
        const videoUrl = `https://res.cloudinary.com/padcelona-sl/video/upload/w_600,q_60/${item.video}.mp4`;
        const posterUrl = `https://res.cloudinary.com/padcelona-sl/video/upload/w_600,q_60/${item.video}.jpg`;
        this.setState({ rendered: this.state.rendered + 1 });
        return (<React.Fragment>
        <View key={item._id} style={{ ...styles.post, ...(this.props.isBig ? styles.postBig : {}), ...(props.isHomeItem ? { marginBottom: 0 } : {}), ...props.style }}>
          <View style={styles.userHeader}>
            {item.user.picture ? (<ScaledImage style={styles.userPic} image={item.user.picture} width={105} height={105} fit="cover"/>) : (<View style={{ ...styles.dummyPic, backgroundColor: themeColor }}>
                <StyledText style={styles.dummyText}>
                  {item.user.firstName[0]}
                  {item.user.lastName[0]}
                </StyledText>
              </View>)}

            <View style={styles.userInfo}>
              <StyledText style={styles.userName}>
                {item.user.firstName} {item.user.lastName}
              </StyledText>
              
              <StyledText style={styles.userSubtitle}>
                {item.user.subtitle}
              </StyledText>
            </View>
            {item.pinned ?
            <BelongIcon name="md-sparkles" style={styles.pinnedIcon}></BelongIcon>
            :
                <Moment locale={this.context.workingLocale()} fromNow ago element={StyledText} style={styles.date}>
                {item.createdAt}
              </Moment>}
          </View>
          {item.video && !item.image && (<TouchableWithoutFeedback style={styles.picWrap} onPress={() => toggleMuted()}>
              <View style={styles.videoWrap}>
                <Video ref={(ref) => {
            this.video = ref;
        }} source={{ uri: videoUrl }} // Can be a URL or a local file.
         poster={posterUrl} 
        //onLoad={this._onVideoLoad}
        // onBuffer={this.onBuffer} // Callback when remote video is buffering
        // onError={this.videoError} // Callback when video cannot be loaded
        repeat={true} muted={!viewable || muted} paused={!viewable} ignoreSilentSwitch="ignore" resizeMode='cover' style={{
            ...styles.video,
            height: (item.dimensions && item.dimensions.height && item.dimensions.width) ? item.dimensions.height * ((this.props.isBig ? '675' : deviceWidth) / item.dimensions.width) : (this.props.isBig ? '675' : deviceWidth),
            width: this.props.isBig ? '675' : styles.video.width
        }}/>
                {muted && (<View style={styles.mutedIndicator}>
                    <BelongIcon name="sm-volume-off" color="#000000" size={18}/>
                  </View>)}
              </View>
            </TouchableWithoutFeedback>)}
          {item.image && (<View style={styles.picWrap}>
              <ScaledImage image={item.image} width={900} height={900}/>
            </View>)}

          <View style={styles.postInner}>
            <PostContent themeColor={props.services.app.manifest.themeColor} style={styles.postText} text={this.state.translated || item.content} onTagPress={tag => props.setHashtag(tag)}/>
            {!this.state.translated && item.content && item.content !== ''
            ? <TouchableOpacity onPress={() => this.translateText(item.content)} style={styles.translateButton}>
                  <StyledText style={styles.translateText}>
                    {this.state.translating
                ? this.props.t("timeline.translating")
                : this.props.t("timeline.translate")}
                  </StyledText>
                </TouchableOpacity>
            : null}
          </View>
          {props.isHomeItem ?
            <View style={{ marginVertical: 10 }}/>
            :
                <View style={styles.interactionArea}>
              <View style={{ flexDirection: 'row' }}>
                <TouchableOpacity onPress={() => this.props.navigation.navigate("Comments", { item: this.props.item })}>
                  <StyledText style={styles.commentInfo}>
                    {item.commentCount} {this.props.t('timeline.commentCount')}
                  </StyledText>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => this.props.navigation.navigate("Likes", { item: this.props.item })}>
                  <StyledText style={styles.commentInfo}>
                    {item.likeCount} {this.props.t('timeline.likeCount')}
                  </StyledText>
                </TouchableOpacity>

              </View>

              <View style={styles.buttonArea}>
                <View style={styles.buttonAreaLeft}>
                  <TouchableOpacity style={styles.interactionButton} onPress={() => {
                    if (!this.state.liking) {
                        this.setState({ liking: true });
                        this.props.onLike().finally(() => {
                            this.setState({ liking: false });
                        });
                    }
                }}>
                    <BelongIcon name={item.userLikes ? "sm-heart" : "md-heart"} style={{
                    ...styles.interactionIcon,
                    ...(item.userLikes || (!item.userLikes && this.state.liking) ? { color: themeColor } : {}),
                }}/>
                    <StyledText tkey="timeline.likeButton" style={{
                    ...styles.interactionText,
                    ...(item.userLikes || (!item.userLikes && this.state.liking) ? { color: themeColor } : {}),
                }}>
                      Me gusta
                    </StyledText>
                  </TouchableOpacity>
                  <TouchableOpacity style={styles.interactionButton} onPress={() => this.props.navigation.navigate("Comments", { item: this.props.item })}>
                    <BelongIcon name="md-chat" style={styles.interactionIcon}/>
                    <StyledText tkey="timeline.commentButton" style={styles.interactionText}>Comentar</StyledText>
                  </TouchableOpacity>
                </View>
                {this.props.isMine || item.image || item.video ?
                    <View style={styles.buttonAreaRight}>
                    <TouchableOpacity style={{ ...styles.interactionButton, marginRight: 0 }} onPress={() => this.showActions()}>
                      <BelongIcon name="md-dots-horizontal" style={{ ...styles.interactionIcon, marginRight: 0 }}/>
                    </TouchableOpacity>
                  </View>
                    : null}

              </View>
            </View>}
        </View>
        <UserPostSheet isMine={this.props.isMine} setRef={(ref) => {
            this.actionSheetRef = ref;
        }} editPost={this.props.editPost} deletePost={this.props.deletePost} downloadImage={item.image ? this.props.downloadImage : null} downloadVideo={item.video && !item.image ? this.props.downloadVideo : null} dismiss={() => {
            this.actionSheetRef.hide();
        }}/>
      </React.Fragment>);
    }
}
TimelineItem.contextType = LocaleContext;
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(withHooksHOC(TimelineItem));
