import React from 'react';
import { TouchableOpacity, Platform, Linking } from 'react-native';
import StyledText from '../../../../../../../components/StyledText';
import { parseValue, isMentionPartType } from 'react-native-controlled-mentions';
import Hyperlink from 'react-native-hyperlink';
// STYLING
const styles = {
    tagButton: {},
    tagButtonText: {
        lineHeight: Platform.OS === 'ios' ? 0 : null,
        marginBottom: -1,
        position: 'relative',
        top: Platform.OS === 'ios' ? 0 : 3,
    }
};
export default props => {
    const onLinkPress = async (url) => {
        //alert(url);
        //alert(url);
        const canOpen = await Linking.canOpenURL(url);
        if (canOpen) {
            Linking.openURL(url);
        }
    };
    const pieces = props.text.split(/((?:^|\s)(?:#[a-z\d-]+))/gi).filter(Boolean).map((v, i) => {
        if (v.includes('#')) {
            return <TouchableOpacity onPress={() => props.onTagPress(v)} style={{ ...props.style, ...styles.tagButton }}><StyledText style={{ ...props.style, ...styles.tagButtonText, color: props.themeColor || 'blue' }} key={i}>{v}</StyledText></TouchableOpacity>;
        }
        else {
            const { parts } = parseValue(v, [
                {
                    trigger: '@',
                    textStyle: { fontWeight: '500', color: 'red' },
                }
            ]);
            return parts.map((part, index) => {
                if (!part.partType) {
                    return <StyledText style={props.style} key={[i, index]}>{part.text}</StyledText>;
                }
                if (isMentionPartType(part.partType)) {
                    return <StyledText style={{ ...props.style, color: props.themeColor || 'blue' }} key={[i, index]}>{part.text}</StyledText>;
                }
            });
        }
    });
    return (<React.Fragment>
      <Hyperlink onPress={onLinkPress} linkStyle={{ color: props.themeColor || 'blue' }} linkDefault={false}><StyledText style={props.style}>{pieces}</StyledText></Hyperlink>
    </React.Fragment>);
};
