import React, { useEffect, useContext, useState } from 'react';
// REACT NATIVE COMPONENTS
import { View, TouchableOpacity } from "react-native";
// OWN ROOT COMPONENTS
import StyledText from "../../../../../../components/StyledText";
import ScaledImage from "../../../../../../components/ScaledImage";
import PlaceholderAvatar from "../../../../../../components/PlaceholderAvatar";
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../../../services/feathers/FeathersProvider";
// OTHER UTILS
// STYLING
const styles = {
    container: {
        flexDirection: "column",
        backgroundColor: '#FFFFFF'
    },
    user: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 15,
        marginHorizontal: 15,
        borderTopWidth: 1,
        borderTopColor: '#E9E9E9'
    },
    pic: {
        width: 56,
        height: 56,
        borderRadius: 28,
        marginRight: 15
    },
    text: {
        flexDirection: 'column'
    },
    title: {
        fontSize: 17,
        color: '#333333',
        fontWeight: '500'
    },
    subtitle: {
        fontSize: 13,
        color: '#8D8E8F',
    }
};
export default ({ keyword, onSuggestionPress, themeColor }) => {
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    const [suggestions, setSuggestions] = useState([]);
    useEffect(() => {
        if (keyword && keyword.length > 0) {
            feathersContext
                .onlyFind("users", {
                query: {
                    invisible: false,
                    $or: [
                        { firstName: { $like: keyword, $search: keyword } },
                        { lastName: { $like: keyword, $search: keyword } },
                        { subtitle: { $like: keyword, $search: keyword } },
                    ],
                }
            }).then(r => {
                //alert(JSON.stringify(r))
                if (r.total > 0)
                    setSuggestions(r.data.map(s => ({ ...s, id: s._id, name: `${s.firstName} ${s.lastName}` })));
            }).catch(e => alert(JSON.stringify(e)));
        }
    }, [keyword]);
    if (keyword == null) {
        return null;
    }
    return (<View style={styles.container}>
      {suggestions
        .map(item => (<TouchableOpacity key={item.id} onPress={() => onSuggestionPress(item)} style={styles.user}>
            {item.picture ? (<ScaledImage image={item.picture} width={165} height={165} fit="cover" style={styles.pic}/>) : (<PlaceholderAvatar user={item} themeColor={themeColor} style={styles.pic}/>)}
            <View style={styles.text}>
              <StyledText style={styles.title}>{item.name}</StyledText>
              <StyledText style={styles.subtitle}>{item.subtitle}</StyledText>
            </View>
          </TouchableOpacity>))}
    </View>);
};
