import React from 'react';
// REACT NATIVE COMPONENTS
import { View } from "react-native";
// OWN ROOT COMPONENTS
import StyledText from '../StyledText';
import ScaledImage from "../ScaledImage";
import PlaceholderAvatar from "../PlaceholderAvatar";
//import Icon from 'react-native-ico-flags';
// OTHER UTILS
// STYLING
const styles = {
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 16,
        paddingHorizontal: 8,
        paddingVertical: 14,
        borderBottomWidth: 1,
        borderBottomColor: '#E9E9E9'
    },
    position: {
        marginRight: 10,
        fontSize: 14,
        fontWeight: '500',
        color: '#A7A7A7'
    },
    text: {
        marginLeft: 10,
        flexDirection: 'column',
        flex: 1
    },
    firstRow: {
        flexDirection: 'row'
    },
    title: {
        fontSize: 17,
        fontWeight: '500',
        color: '#333333'
    },
    subtitle: {
        fontSize: 13,
        color: '#8D8E8F'
    },
    points: {
        marginLeft: 10,
        backgroundColor: '#000',
        borderRadius: 10,
        height: 20,
        paddingHorizontal: 8,
        justifyContent: 'center',
        alignItems: 'center'
    },
    pointsText: {
        fontSize: 13,
        color: '#FFFFFF'
    }
};
export default props => {
    const { user } = props;
    return (<View style={{ ...styles.container, ...(props.reduced ? { marginHorizontal: 8 } : {}) }}>
      <StyledText style={styles.position}>{props.index + 1}</StyledText>
      {user.picture ? (<ScaledImage width={240} height={240} fit="cover" image={user.picture} style={{
        width: 56,
        height: 56,
        borderRadius: 28,
        backgroundColor: "#333",
    }}/>) : (<PlaceholderAvatar user={user} themeColor={props.themeColor} style={{
        width: 56,
        height: 56,
        borderRadius: 28,
        backgroundColor: "#333",
    }}/>)}
      <View style={styles.text}>
        <View style={styles.firstRow}>
          <StyledText style={styles.title}>
            {user.firstName} {user.lastName}
          </StyledText>
          
        </View>
        <StyledText style={styles.subtitle}>{user.subtitle}</StyledText>
      </View>

      <View style={{ ...styles.points, backgroundColor: props.themeColor }}>
        <StyledText style={styles.pointsText}>{user.points || 0}</StyledText>
      </View>

    </View>);
};
