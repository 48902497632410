import React, { useCallback } from "react";
import { View, ScrollView, TouchableOpacity, Platform, Dimensions, } from "react-native";
import StyledText from "../../components/StyledText";
import BigHeader from "../../components/BelongHeader/big";
import SmallHeader from "../../components/BelongHeader/small";
import BelongBanner from "../../components/BelongBanner";
import BelongMenu from "../../components/BelongMenu";
import BelongIcon from "../../components/BelongIcon";
import RankingUser from "../../components/RankingUser";
import Announcement, { AnnouncementSeparator } from "./components/Announcement";
import TimelineItem from './../Modules/Timeline/components/TimelineItem';
import LinearGradient from 'react-native-linear-gradient';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { FeathersContext } from "../../services/feathers/FeathersProvider";
import { I18nContext } from 'react-i18next';
import { AnalyticsContext } from '../../Analytics';
import { useFocusEffect } from '@react-navigation/native';
import { connect } from "react-redux";
import moment from "moment-timezone";
import Svg, { G, Path } from "react-native-svg";
const withHooksHOC = (Component) => {
    return (props) => {
        const isBig = useMediaQuery({ query: '(min-width: 800px)' });
        return <Component isBig={isBig} {...props}/>;
    };
};
const styles = {
    outer: {
        flex: 1,
        backgroundColor: 'rgb(242,242,242)'
    },
    container: {
        flex: 1
    },
    scroller: {},
    scrollerContent: {
        paddingBottom: 110,
        flexGrow: 1,
        width: '100%',
        maxWidth: 1090,
        marginHorizontal: 'auto'
    },
    modulesWrap: {
        width: "100%",
    },
    banner: {
        backgroundColor: "#222",
        borderRadius: 10,
        marginHorizontal: 14,
        position: 'relative'
    },
    sectionTitleWrap: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    },
    sectionTitle: {
        fontSize: 25,
        fontWeight: "500",
        color: "#333333",
        marginLeft: 14,
        marginTop: 25,
    },
    sectionButtonWrap: {
        flexDirection: 'row',
        marginRight: 5,
    },
    sectionButton: {
        width: 36,
        height: 36,
        borderRadius: 18,
        backgroundColor: 'rgba(55, 66, 96, 0.05)',
        marginRight: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    sectionAction: {
        fontSize: 13,
        color: "#333333",
        marginRight: 14,
        marginTop: 25,
    },
    modulesOuter: {
        position: 'relative'
    },
    modules: {
        paddingHorizontal: 7,
        flexDirection: "row",
        paddingVertical: 20,
        marginRight: 60
    },
    module: {
        marginHorizontal: 7,
        paddingHorizontal: 8,
        width: Platform.OS == "web" ? 135 : Dimensions.get("window").width * 0.26,
        // minHeight:
        //   Platform.os == "web" ? "26vw" : Dimensions.get("window").width * 0.26,
        backgroundColor: "#fff",
        shadowColor: "#333",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.2,
        shadowRadius: 6.68,
        elevation: 11,
        borderRadius: 10,
        maxWidth: 200,
        minHeight: Platform.OS == "web" ? 135 : Dimensions.get("window").width * 0.26,
        justifyContent: "space-between",
        alignItems: "center",
    },
    moduleBig: {
        width: 200,
        minHeight: 164,
        paddingVertical: 20
    },
    moduleIcon: {
        marginTop: 20,
        fontSize: 45,
    },
    moduleText: {
        textAlign: "center",
        color: "#B9B9B9",
        fontSize: 13,
        fontWeight: "500",
        marginTop: 15,
        marginBottom: 10,
    },
    announcementsWrap: {
        margin: 15,
        paddingHorizontal: 10,
        borderRadius: 10,
        backgroundColor: "#ffffff",
        shadowColor: "#550000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 8,
    },
    rankingWrap: {
        margin: 15,
        borderRadius: 10,
        backgroundColor: "#ffffff",
        shadowColor: "#550000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 8,
    },
    appointments: {
        paddingHorizontal: 7,
        flexDirection: "row",
        paddingVertical: 20,
        marginRight: 60
    },
    appointment: {
        marginHorizontal: 7,
        paddingHorizontal: 15,
        paddingVertical: 15,
        width: Platform.OS == "web" ? "52vw" : Dimensions.get("window").width * 0.52,
        maxWidth: 400,
        backgroundColor: "#8681FF",
        shadowColor: "#333",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.2,
        shadowRadius: 6.68,
        elevation: 11,
        borderRadius: 10,
        justifyContent: "space-between",
    },
    appointmentTitle: {
        fontWeight: "500",
        fontSize: 17,
        color: "#ffffff",
        marginBottom: 10,
    },
    appointmentSubtitle: {
        fontSize: 13,
        color: "#ffffff",
        opacity: 0.6,
    },
    socialWrap: {
        marginTop: 15,
        marginBottom: 0,
        backgroundColor: "#ffffff",
        shadowColor: "#550000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 8,
    },
};
function FocusComponent({ didFocus }) {
    useFocusEffect(useCallback(() => {
        didFocus();
    }, []));
    return null;
}
class Home extends React.PureComponent {
    constructor(props) {
        super(props);
        this.componentDidFocus = () => {
            this.context
                .onlyFind("users", {
                query: {
                    appId: this.props.services.app.slug,
                    hideFromRanking: { $ne: true },
                    points: { $gt: 0 },
                    $sort: {
                        points: -1,
                        lastName: 1
                    },
                    $limit: 5,
                },
            })
                .then(r => {
                this.setState({ homeRanking: r.data });
            })
                .catch((e) => {
                console.log("Error", e);
            });
            this.context
                .onlyFind("posts", {
                query: {
                    appId: this.props.services.app.slug,
                    pinned: true,
                    $sort: {
                        createdAt: -1,
                    },
                },
            })
                .then(r => {
                this.setState({ posts: r.data });
            })
                .catch((e) => {
                console.log("Error", e);
            });
            // I load appointments
            const now = new Date();
            this.context.onlyFind('appointments', {
                query: {
                    dateFrom: now,
                    dateTo: new Date(now.getTime() + 365 * 24 * 60 * 60 * 1000),
                    $limit: 5
                }
            }).then(r => {
                this.setState({
                    appointments: r.data
                });
            });
            // I load announcements
            this.context.find("announcements", { query: { $limit: 3, $sort: { createdAt: -1 } } });
            this.context.find('notifications', { query: { sent: true, $sort: { sentAt: -1 } } });
        };
        this.handleScroll = (event) => {
            var currentOffset = event.nativeEvent.contentOffset.y;
            const goesDown = currentOffset > this.offset && currentOffset > 0;
            this.setState({ menuHidden: goesDown });
            this.offset = currentOffset;
        };
        this.myModules = () => this.props.services.app.manifest.modules
            .filter((module) => module.active)
            .filter((module) => module.visibility.everyone
            ? true
            : module.visibility.audiences.filter(x => this.props.services.app.feathersUser.audiences && this.props.services.app.feathersUser.audiences.includes(x)).length > 0);
        this.jsUcfirst = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };
        this.state = {
            menuHidden: false,
            posts: [],
            homeRanking: [],
            appointments: [],
            muted: true,
            modulesScrollPosition: 0,
            agendaScrollPosition: 0
        };
        this.offset = 0;
        this.modulesScrollView = React.createRef();
        this.agendaScrollView = React.createRef();
    }
    componentDidMount() {
    }
    render() {
        const modules = this.myModules();
        return (<I18nContext.Consumer>
        {i18nContext => (<View style={styles.outer}>
            <View style={styles.container}>
              <FocusComponent didFocus={this.componentDidFocus}/>
              <MediaQuery minWidth={800}>
                <BigHeader navigation={this.props.navigation}>
                  {this.props.services.app.manifest.title}
                </BigHeader>
              </MediaQuery>
              <AnalyticsContext.Consumer>
                {analyticsContext => (<ScrollView scrollEventThrottle={2} onScroll={this.handleScroll} style={styles.scroller} contentContainerStyle={[styles.scrollerContent, this.props.isBig ? { paddingTop: 45 } : {}]} onScrollEndDrag={(e) => analyticsContext.trackMixpanelEvent("homeScroll")}>
                    <MediaQuery maxWidth={799}>
                      <SmallHeader navigation={this.props.navigation}>
                        {this.props.services.app.manifest.title}
                      </SmallHeader>
                    </MediaQuery>
                    <BelongBanner image={this.props.services.app.manifest.banner} hasCountdown={this.props.services.app.manifest.enableCountdown} eventDate={this.props.services.app.manifest.eventDate} style={{
            ...styles.banner,
            backgroundColor: this.props.services.app.manifest.themeColor,
        }}/>
                    
                    <View style={styles.sectionTitleWrap}>
                      <StyledText tkey="home.accesses" style={styles.sectionTitle}>Accesos</StyledText>
                      <View style={styles.sectionButtonWrap}>
                        <TouchableOpacity onPress={() => {
            this.modulesScrollView.current.scrollTo({ x: this.state.modulesScrollPosition - (Platform.OS == "web" ? (this.props.isBig ? styles.moduleBig.width : 135) : Dimensions.get("window").width * 0.26) - 14, y: 0, animated: true });
        }} style={styles.sectionButton}>
                          <Svg width="10px" height="18px" viewBox="0 0 10 18" style={{ marginRight: 2 }}>
                            <G id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.5">
                              <G id="Home-Copy-2" transform="translate(-1195.000000, -416.000000)" fill="#374260" fill-rule="nonzero" stroke="#374260">
                                <G id="next-1-copy" transform="translate(1200.000000, 425.000000) rotate(-180.000000) translate(-1200.000000, -425.000000) translate(1196.000000, 417.000000)">
                                  <Path d="M7.87661511,7.68103945 L0.732357787,0.133284434 C0.564145597,-0.0444281448 0.294371331,-0.0444281448 0.126159142,0.133284434 C-0.0420530473,0.310997014 -0.0420530473,0.596007754 0.126159142,0.773720333 L6.96573024,7.99958087 L0.126159142,15.2254414 C-0.0420530473,15.403154 -0.0420530473,15.6881647 0.126159142,15.8658773 C0.208678329,15.9530571 0.319761851,16 0.427671557,16 C0.535581263,16 0.646664785,15.9564101 0.729183972,15.8658773 L7.8734413,8.31812228 C8.04165349,8.14376277 8.04165349,7.85539896 7.87661511,7.68103945 Z" id="Path"></Path>
                                </G>
                              </G>
                            </G>
                          </Svg>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => {
            this.modulesScrollView.current.scrollTo({ x: this.state.modulesScrollPosition + (Platform.OS == "web" ? (this.props.isBig ? styles.moduleBig.width : 135) : Dimensions.get("window").width * 0.26) + 14, y: 0, animated: true });
        }} style={styles.sectionButton}>
                          <Svg width="10px" height="18px" viewBox="0 0 10 18" style={{ marginLeft: 2 }}>
                            <G id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.5">
                              <G id="Home-Copy-2" transform="translate(-1243.000000, -416.000000)" fill="#374260" fill-rule="nonzero" stroke="#374260">
                                <G id="next-1" transform="translate(1244.000000, 417.000000)">
                                  <Path d="M7.87661511,7.68103945 L0.732357787,0.133284434 C0.564145597,-0.0444281448 0.294371331,-0.0444281448 0.126159142,0.133284434 C-0.0420530473,0.310997014 -0.0420530473,0.596007754 0.126159142,0.773720333 L6.96573024,7.99958087 L0.126159142,15.2254414 C-0.0420530473,15.403154 -0.0420530473,15.6881647 0.126159142,15.8658773 C0.208678329,15.9530571 0.319761851,16 0.427671557,16 C0.535581263,16 0.646664785,15.9564101 0.729183972,15.8658773 L7.8734413,8.31812228 C8.04165349,8.14376277 8.04165349,7.85539896 7.87661511,7.68103945 Z" id="Path"></Path>
                                </G>
                              </G>
                            </G>
                          </Svg>
                        </TouchableOpacity>
                      </View>
                    </View>

                    <View style={styles.modulesOuter}>
                      <ScrollView ref={this.modulesScrollView} onScroll={(event) => this.setState({ modulesScrollPosition: event.nativeEvent.contentOffset.x })} style={styles.modulesWrap} contentContainerStyle={styles.modules} horizontal={true} showsHorizontalScrollIndicator={false}>
                        {modules.map((module, i) => {
            return [
                "questionsModules",
                "timelineModules",
                "webtabModules",
                "staticModules",
                "gamingModules",
                "agendaModules",
                "userListModules",
                "userListModules",
                "votingModules",
                "fastpassModules"
            ].indexOf(module._type) !== -1 ? (<TouchableOpacity onPress={() => {
                analyticsContext.trackMixpanelEvent("visitedModule", {
                    moduleType: module._type,
                    moduleId: module._id
                });
                this.props.navigation.navigate(`Module_${module._type}`, {
                    module,
                });
            }} key={module._id} style={[styles.module, this.props.isBig ? styles.moduleBig : null]}>
                              <BelongIcon name={`md-${module.icon}`} size={30} style={{
                ...styles.moduleIcon,
                color: this.props.services.app.manifest.themeColor,
            }}/>
                              <StyledText style={styles.moduleText}>
                                {module.title}
                              </StyledText>
                            </TouchableOpacity>) : null;
        })}
                      </ScrollView>
                      <LinearGradient start={{ x: 1, y: 0 }} end={{ x: 0, y: 0 }} style={{ position: 'absolute', left: -30, top: 0, bottom: 0, width: 50 }} colors={['rgba(240, 240, 240, 0)', 'rgba(240, 240, 240, 0.7)', 'rgba(242, 242, 242, 1)']} pointerEvents={'none'}/>
                      <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} style={{ position: 'absolute', right: 0, top: 0, bottom: 0, width: 80 }} colors={['rgba(240, 240, 240, 0)', 'rgba(240, 240, 240, 0.7)', 'rgba(242, 242, 242, 1)']} pointerEvents={'none'}/>
                    </View>
                    {this.props.services.app.manifest?.mobileHomeOptions?.showAgenda && this.state.appointments.length > 0 && (<View style={styles.modulesOuter}>
                        <View style={styles.sectionTitleWrap}>
                          <StyledText tkey="home.agenda" style={styles.sectionTitle}>Agenda</StyledText>
                          <View style={styles.sectionButtonWrap}>
                            <TouchableOpacity onPress={() => {
            this.agendaScrollView.current.scrollTo({ x: this.state.agendaScrollPosition - (Platform.OS == "web" ? (this.props.isBig ? styles.moduleBig.width : 135) : Dimensions.get("window").width * 0.26) - 14, y: 0, animated: true });
        }} style={styles.sectionButton}>
                              <Svg width="10px" height="18px" viewBox="0 0 10 18" style={{ marginRight: 2 }}>
                                <G id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.5">
                                  <G id="Home-Copy-2" transform="translate(-1195.000000, -416.000000)" fill="#374260" fill-rule="nonzero" stroke="#374260">
                                    <G id="next-1-copy" transform="translate(1200.000000, 425.000000) rotate(-180.000000) translate(-1200.000000, -425.000000) translate(1196.000000, 417.000000)">
                                      <Path d="M7.87661511,7.68103945 L0.732357787,0.133284434 C0.564145597,-0.0444281448 0.294371331,-0.0444281448 0.126159142,0.133284434 C-0.0420530473,0.310997014 -0.0420530473,0.596007754 0.126159142,0.773720333 L6.96573024,7.99958087 L0.126159142,15.2254414 C-0.0420530473,15.403154 -0.0420530473,15.6881647 0.126159142,15.8658773 C0.208678329,15.9530571 0.319761851,16 0.427671557,16 C0.535581263,16 0.646664785,15.9564101 0.729183972,15.8658773 L7.8734413,8.31812228 C8.04165349,8.14376277 8.04165349,7.85539896 7.87661511,7.68103945 Z" id="Path"></Path>
                                    </G>
                                  </G>
                                </G>
                              </Svg>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => {
            this.agendaScrollView.current.scrollTo({ x: this.state.agendaScrollPosition + (Platform.OS == "web" ? (this.props.isBig ? styles.moduleBig.width : 135) : Dimensions.get("window").width * 0.26) + 14, y: 0, animated: true });
        }} style={styles.sectionButton}>
                              <Svg width="10px" height="18px" viewBox="0 0 10 18" style={{ marginLeft: 2 }}>
                                <G id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.5">
                                  <G id="Home-Copy-2" transform="translate(-1243.000000, -416.000000)" fill="#374260" fill-rule="nonzero" stroke="#374260">
                                    <G id="next-1" transform="translate(1244.000000, 417.000000)">
                                      <Path d="M7.87661511,7.68103945 L0.732357787,0.133284434 C0.564145597,-0.0444281448 0.294371331,-0.0444281448 0.126159142,0.133284434 C-0.0420530473,0.310997014 -0.0420530473,0.596007754 0.126159142,0.773720333 L6.96573024,7.99958087 L0.126159142,15.2254414 C-0.0420530473,15.403154 -0.0420530473,15.6881647 0.126159142,15.8658773 C0.208678329,15.9530571 0.319761851,16 0.427671557,16 C0.535581263,16 0.646664785,15.9564101 0.729183972,15.8658773 L7.8734413,8.31812228 C8.04165349,8.14376277 8.04165349,7.85539896 7.87661511,7.68103945 Z" id="Path"></Path>
                                    </G>
                                  </G>
                                </G>
                              </Svg>
                            </TouchableOpacity>
                          </View>
                        </View>
                        <ScrollView ref={this.agendaScrollView} onScroll={(event) => this.setState({ agendaScrollPosition: event.nativeEvent.contentOffset.x })} style={styles.appointmentsWrap} contentContainerStyle={styles.appointments} horizontal={true} showsHorizontalScrollIndicator={false}>
                          {this.state.appointments.map((appointment, i) => {
            return (<TouchableOpacity onPress={() => this.props.navigation.navigate("AgendaDetail", { item: appointment })} key={appointment._id} style={{
                ...styles.appointment,
                backgroundColor: this.props.services.app.manifest
                    .themeColor,
            }}>
                                <StyledText style={styles.appointmentTitle}>
                                  {appointment.title}
                                </StyledText>
                                <StyledText style={styles.appointmentSubtitle}>
                                  {this.jsUcfirst(`${moment(appointment.startDate).format('dddd D MMM, HH:mm')}`)} h
                                </StyledText>
                              </TouchableOpacity>);
        })}
                        </ScrollView>
                        <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} style={{ position: 'absolute', right: 0, top: 75, bottom: 0, width: 80 }} colors={['rgba(240, 240, 240, 0)', 'rgba(240, 240, 240, 0.7)', 'rgba(242, 242, 242, 1)']} pointerEvents={'none'}/>
                      </View>)}
                    {this.props.data.announcements.items.length > 0 && (<>
                        <StyledText tkey="home.featured" style={styles.sectionTitle}>Destacados</StyledText>
                        <View style={styles.announcementsWrap}>
                          {this.props.data.announcements.items
            .map((announcement, i) => (<Announcement key={announcement._id} item={announcement} themeColor={this.props.services.app.manifest.themeColor}/>))
            .reduce((prev, curr) => [
            prev,
            <AnnouncementSeparator />,
            curr,
        ])}
                        </View>
                      </>)}
                    {this.state.posts.length > 0 && (<>
                        <View style={styles.sectionTitleWrap}>
                          <StyledText tkey="home.social" style={styles.sectionTitle}>Social Wall</StyledText>
                          <TouchableOpacity onPress={() => {
            const timelineModules = modules.filter(m => m._type == "timelineModules");
            if (timelineModules.length > 0) {
                this.props.navigation.navigate(`Module_${timelineModules[0]._type}`, {
                    module: timelineModules[0],
                });
            }
        }}>
                            <StyledText tkey="home.socialMore" style={{ ...styles.sectionAction, color: this.props.services.app.manifest.themeColor }}>Ver más</StyledText>
                          </TouchableOpacity>

                        </View>
                        <View style={styles.socialWrap}>
                          {this.state.posts
            .map((post, i) => (<TimelineItem key={post._id} item={post} isMine={false} viewable={true} muted={this.state.muted} toggleMuted={() => this.setState({ muted: !this.state.muted })} themeColor={this.props.services.app.manifest.themeColor} navigation={this.props.navigation} isHomeItem setHashtag={() => { }} t={i18nContext.i18n.t}/>))
            .reduce((prev, curr) => [
            prev,
            <AnnouncementSeparator />,
            curr,
        ])}
                        </View>
                      </>)}
                    {this.props.services.app.manifest?.mobileHomeOptions?.showRanking && this.state.homeRanking.length > 0 && (<>
                        <View style={styles.sectionTitleWrap}>
                          <StyledText tkey="home.ranking" style={styles.sectionTitle}>Ranking</StyledText>
                          <TouchableOpacity onPress={() => {
            const gamingModules = modules.filter(m => m._type == "gamingModules");
            if (gamingModules.length > 0) {
                this.props.navigation.navigate(`Module_${gamingModules[0]._type}`, {
                    module: gamingModules[0],
                    tabIndex: 2
                });
            }
        }}>
                            <StyledText tkey="home.rankingMore" style={{ ...styles.sectionAction, color: this.props.services.app.manifest.themeColor }}>Ver más</StyledText>
                          </TouchableOpacity>

                        </View>
                        <View style={styles.rankingWrap}>
                          {this.state.homeRanking
            .map((user, i) => (<RankingUser reduced key={user._id} index={i} user={user} themeColor={this.props.services.app.manifest.themeColor} onPress={() => { }}/>))}
                        </View>
                      </>)}


                  </ScrollView>)}
              </AnalyticsContext.Consumer>
              <MediaQuery maxWidth={799}>
                <BelongMenu hidden={this.state.menuHidden} navigation={this.props.navigation} app={this.props.services.app} notifications={this.props.data.notifications}/>
              </MediaQuery>
            </View>
          </View>)}
      </I18nContext.Consumer>);
    }
}
Home.contextType = FeathersContext;
export default connect((state) => ({
    data: {
        announcements: state.data.announcements,
        notifications: state.data.notifications,
    },
    services: {
        app: state.services.app,
    },
}), null)(withHooksHOC(Home));
