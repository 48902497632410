import React from "react";
// REACT NATIVE COMPONENTS
import { View } from "react-native";
// OWN ROOT COMPONENTS
import StyledText from '../../../../components/StyledText';
import "moment-timezone";
import { useTranslation } from 'react-i18next';
// STYLING
const styles = {
    separator: {
        backgroundColor: "#E9E9E9",
        height: 1,
    },
    container: {
        paddingHorizontal: 5,
        paddingVertical: 24,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    title: {
        color: "#333333",
        fontSize: 17,
        fontWeight: "500",
        flex: 1
    },
    date: {
        fontSize: 13,
    },
    body: {
        color: "#8D8E8F",
        fontSize: 13,
        marginTop: 5
    },
};
export const AnnouncementSeparator = props => {
    return (<View style={styles.separator}>
    </View>);
};
export default ({ item, themeColor }) => {
    const { t, i18n } = useTranslation();
    return new Date(item.createdAt) < new Date() ? (<View style={styles.container}>
      <View style={styles.header}>
        <StyledText style={styles.title}>{item.title}</StyledText>
        
      </View>
      <StyledText style={styles.body}>{item.body}</StyledText>
    </View>) : null;
};
