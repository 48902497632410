import React, { useState, useContext, useCallback, useEffect } from 'react';
// REACT NATIVE COMPONENTS
import { View, TouchableOpacity, FlatList } from "react-native";
// OWN ROOT COMPONENTS
import StyledText from '../../../../../components/StyledText';
import BelongIcon from '../../../../../components/BelongIcon';
import Report from './components/Report';
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import { useTranslation } from 'react-i18next';
// STYLING
const styles = {
    contentContainer: {
        marginHorizontal: 12,
    },
    row: {},
    gameWrap: {
        height: 80,
        flex: 0.5,
        marginBottom: 16
    },
    game: {
        backgroundColor: '#FF9281',
        borderRadius: 10,
        marginHorizontal: 8,
        flex: 1,
        padding: 10,
        flexDirection: 'row',
    },
    label: {
        fontSize: 15,
        lineHeight: 16,
        fontWeight: '500',
        color: '#FFFFFF',
        paddingRight: 10,
        flex: 1
    },
    icon: {
        alignSelf: 'flex-end'
    }
};
const Component = props => {
    const { t, i18n } = useTranslation();
    const [report, setReport] = useState([]);
    // Load Feathers context
    const feathersContext = useContext(FeathersContext);
    const initialLoad = useCallback(() => {
        if (props.services.app.feathersAuthenticated) {
            feathersContext
                .onlyFind("rankings")
                .then(r => {
                setReport(r);
            })
                .catch((e) => {
                alert(JSON.stringify(e));
            });
        }
    }, []);
    useEffect(() => {
        initialLoad();
    }, []);
    return (<React.Fragment>
      <FlatList {...props} style={{ flex: 1, backgroundColor: '#FFF' }} contentContainerStyle={{ ...props.contentContainerStyle, ...styles.contentContainer, paddingTop: props.contentContainerStyle.paddingTop + 20 }} data={props.games} renderItem={(item) => (<View style={{ ...styles.gameWrap, opacity: item.item.completed ? 0.5 : 1 }}>
            <TouchableOpacity onPress={() => {
        if (item.item.completed) {
            props.onCompletedPress(item);
        }
        else {
            props.onItemPress(item);
        }
    }} style={{ ...styles.game, backgroundColor: item.item.color }}>
              <StyledText style={styles.label}>{item.item.title}</StyledText>
              <BelongIcon name={`md-${item.item.icon}`} size={30} color="rgba(255,255,255,0.7)" style={styles.icon}/>
            </TouchableOpacity>
          </View>)} keyExtractor={item => item.id} horizontal={false} showsVerticalScrollIndicator={false} numColumns={2} ListFooterComponent={<Report report={report}/>} ListFooterComponentStyle={{ marginHorizontal: -12 }}/>

    </React.Fragment>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
