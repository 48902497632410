import React from "react";
import { StyleSheet, View } from "react-native";
const styles = StyleSheet.create({
    container: {
        borderRadius: 10,
        paddingVertical: 5,
        backgroundColor: "#fff",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        alignSelf: "stretch",
        marginBottom: 15,
    },
});
export default class FormGroup extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <View style={[styles.container, this.props.style]}>{this.props.children}</View>;
    }
}
FormGroup.displayName = "FormGroup2";
// export default (props) => {
//   return <View style={styles.container}>{props.children}</View>;
// };
