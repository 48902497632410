import React from 'react';
import { View } from 'react-native';
export default class WebVideo extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
        this.shouldComponentUpdate = (nextProps, nextState) => {
            if (nextProps.paused != this.props.paused) {
                if (nextProps.paused)
                    this.video.pause();
                else
                    this.video.play();
                return false;
            }
            else {
                return true;
            }
        };
        this.setNativeProps = (prop, value) => {
            if (prop == 'paused') {
                if (value) {
                    alert("Pause");
                    this.video.pause();
                }
                else {
                    alert("Play");
                    this.video.play();
                }
            }
        };
    }
    render() {
        return (<View style={this.props.style}>
        <video muted={this.props.muted} loop ref={(ref) => this.video = ref} src={this.props.source.uri}/>
      </View>);
    }
}
