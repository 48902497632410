import React from 'react';
import { TouchableOpacity } from 'react-native';
import BelongIcon from "../../../../../../components/BelongIcon";
import axios from "axios";
import apiConfig from "../../../../../../services/api/config";
export default props => {
    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);
    // HANDLE DOCUMENT PICK
    const handleDocumentPick = () => {
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        console.log(event.target.files[0]);
        props.setLoading(true);
        let url = "";
        const formData = new FormData();
        if (fileUploaded.type.includes("image")) {
            props.setValue("video", null);
            formData.append("uri", fileUploaded);
            url = `${apiConfig.url[props.app.env]}uploads`;
        }
        else if (fileUploaded.type.includes("video")) {
            props.setValue("image", null);
            formData.append("file", fileUploaded);
            formData.append("upload_preset", "r0tgo8i0");
            formData.append("cloud_name", "padcelona-sl");
            url = "https://api.cloudinary.com/v1_1/padcelona-sl/upload";
        }
        else {
            props.setLoading(false);
        }
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent) {
                props.setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            },
        };
        const fileType = fileUploaded.type;
        axios
            .post(url, formData, config)
            .then((ar) => {
            if (fileType.includes("image")) {
                props.setValue("image", ar.data.id);
            }
            else if (fileType.includes("video")) {
                props.setVideoThumb(ar.data.secure_url.replace(/\.([a-zA-Z0-9]{3})($|\?)/, ".jpg$2"));
                props.setValue("video", ar.data.public_id);
                props.setValue("dimensions", {
                    width: ar.data.width,
                    height: ar.data.height,
                });
            }
            props.setLoading(false);
        })
            .catch((e) => {
            console.log("Axios error", e.response);
            props.setLoading(false);
        });
    };
    return (<React.Fragment>
            <TouchableOpacity onPress={() => handleDocumentPick()} style={{
        ...props.buttonStyle,
        backgroundColor: props.themeColor,
    }}>
            <BelongIcon name="md-paper-clip" color="#ffffff" size={30}/>
            </TouchableOpacity>
            <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }}/>
        </React.Fragment>);
};
