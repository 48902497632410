import React, { useEffect, useState, useContext } from 'react';
// REACT NATIVE COMPONENTS
import { View, FlatList, TouchableOpacity } from "react-native";
// OWN ROOT COMPONENTS
import CollapsibleWrapper from "../../../../components/CollapsibleWrapper";
import CollapsibleHeader from "../../../../components/CollapsibleHeader";
import BigHeader from "../../../../components/BelongHeader/big";
import StyledText from "../../../../components/StyledText";
import BelongIcon from "../../../../components/BelongIcon";
import CompletedQuestion from './components/CompletedQuestion';
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import MediaQuery, { useMediaQuery } from 'react-responsive';
// OTHER UTILS
// STYLING
const styles = {
    headerDesktop: {
        height: 100,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    desktopBackOuter: {
        flex: 1
    },
    desktopBackWrap: {
        backgroundColor: 'rgba(55,66,96,0.05)',
        width: 36,
        height: 36,
        borderRadius: 18,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    barButton: {
        height: 30,
        borderRadius: 15,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    contentContainerBig: {
        marginHorizontal: 'auto',
        width: '100%',
        maxWidth: 675,
        alignSelf: 'center',
        marginTop: 30
    },
    end: {
        fontSize: 16,
        color: '#FFFFFF',
        fontWeight: '500',
        textAlign: 'center',
        marginHorizontal: 20
    },
    title: {
        fontSize: 30,
        lineHeight: 32,
        color: '#FFFFFF',
        fontWeight: '500',
        textAlign: 'center',
        marginHorizontal: 20
    },
    subtitle: {
        fontSize: 13,
        color: '#FFFFFF',
        textAlign: 'center',
        opacity: 0.5,
        marginHorizontal: 20,
        marginTop: 15
    },
};
const Component = props => {
    const feathers = useContext(FeathersContext);
    const [gameplay, setGameplay] = useState(null);
    const isBig = useMediaQuery({ query: '(min-width: 800px)' });
    useEffect(() => {
        feathers.onlyFind("gameplays", {
            query: {
                userId: props.services.app.feathersUser._id,
                gameId: props.route.params.game._id,
                completedQuestions: { $ne: [] }
            }
        }).then(r => {
            if (r.total > 0) {
                setGameplay(r.data[0]);
            }
            else {
                props.navigation.navigate("Module_gamingModules", { module: props.route.params.module });
                props.route.params.initialLoad();
            }
        });
    }, [props.route.params.game]);
    const totalQuestions = gameplay ? gameplay.completedQuestions.length : 0;
    const totalCorrect = gameplay ? gameplay.completedQuestions.filter(q => {
        if (q.options) {
            return q.answers.filter(o => o.correct).length > 0;
        }
        else {
            return false;
        }
    }).length : 0;
    return (<React.Fragment>
      <MediaQuery minWidth={800}>
        <BigHeader navigation={props.navigation}>
          {props.services.app.manifest.title}
        </BigHeader>
      </MediaQuery>
      <CollapsibleWrapper headerComponent={(disappearingOpacity, appearingOpacity) => (<React.Fragment>
            <MediaQuery minWidth={800}>
              <View style={styles.headerDesktop}>
                <View style={styles.desktopBackOuter}>
                  <TouchableOpacity style={[styles.barButton, isBig ? styles.desktopBackWrap : {}]} onPress={() => {
        props.navigation.navigate("Module_gamingModules", { module: props.route.params.module });
    }}>
                    <StyledText>
                      <BelongIcon name="md-cheveron-left" style={{
        ...styles.barIcon,
        color: '#ffffff',
    }}/>
                    </StyledText>
                  </TouchableOpacity>
                </View>
                <StyledText variant="h1" style={styles.title}>
                  {props.route.params.module.title}
                </StyledText>
                <View style={{ flex: 1 }}>
                </View>
              </View>
            </MediaQuery>
            <MediaQuery maxWidth={799}>
              <CollapsibleHeader central={<View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <StyledText tkey="gamification.report.end" variant="h1" style={styles.end}>
                      FIN
                    </StyledText>
                    <StyledText tkey="gamification.report.title" interpolations={{ points: gameplay ? gameplay.totalPoints : 0 }} variant="h1" style={styles.title}>
                      Conseguiste X puntos
                    </StyledText>
                    <StyledText tkey="gamification.report.subtitle" interpolations={{ totalCorrect, totalQuestions }} variant="h1" style={styles.subtitle}>
                      Has acertado 2 de 4 preguntas
                    </StyledText>
                  </View>} backAction={() => props.navigation.navigate("Module_gamingModules", { module: props.route.params.module })} 
    // mainAction={() => {}}
    // mainActionTitle="Buscar"
    disappearingOpacity={disappearingOpacity} appearingOpacity={appearingOpacity} themeColor={props.services.app.manifest.themeColor} negative bottomTitleOverride={null}/>
            </MediaQuery>
          </React.Fragment>)} backgroundColor="#EEEFF3" bodyBackgroundColor="#FFFFFF" headerComponentStyle={{
        backgroundColor: props.services.app.manifest.themeColor,
        paddingHorizontal: 15,
    }} bodyComponentStyle={{
        flex: 1,
    }} scrollViewOverride={(onScroll, scrollEventThrottle, contentContainerPadding) => (gameplay ?
        <React.Fragment>

              <FlatList contentContainerStyle={{ ...(isBig ? styles.contentContainerBig : {}), paddingTop: contentContainerPadding }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle} showsVerticalScrollIndicator={false} data={gameplay.completedQuestions} ListHeaderComponent={<MediaQuery minWidth={800}>
                    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', paddingVertical: 50, backgroundColor: '#374260', borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                      <StyledText tkey="gamification.report.end" variant="h1" style={styles.end}>
                        FIN
                      </StyledText>
                      <StyledText tkey="gamification.report.title" interpolations={{ points: gameplay ? gameplay.totalPoints : 0 }} variant="h1" style={styles.title}>
                        Conseguiste X puntos
                      </StyledText>
                      <StyledText tkey="gamification.report.subtitle" interpolations={{ totalCorrect, totalQuestions }} variant="h1" style={styles.subtitle}>
                        Has acertado 2 de 4 preguntas
                      </StyledText>
                    </View>
                  </MediaQuery>} renderItem={({ key, item, index }) => (<CompletedQuestion total={gameplay.completedQuestions.length} index={index} item={item}/>)} keyExtractor={(item) => item._id}/>
            </React.Fragment>
        : null)}/>
    </React.Fragment>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
