import * as actionTypes from "./actionTypes";
export const initialState = {
    env: "production",
    slug: "2gjjy",
    feathersConnected: false,
    feathersReady: false,
    feathersAuthenticated: false,
    feathersUser: {},
    role: null,
    activeModule: 0,
    manifest: {
        title: "",
        terms: "",
        themeColor: "#000000",
        font: "Montserrat",
        banner: null,
        accessOptions: {},
        languages: { priorities: {}, default: "en" },
        langPattern: {
            default: 'es',
            available: ['es']
        },
        modules: [],
        audiences: [],
        deprecations: []
    },
    pushToken: false,
    notifications: []
};
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SLUG:
            return {
                ...state,
                slug: action.slug
            };
        case actionTypes.UPDATE_ROLE:
            return {
                ...state,
                role: action.role
            };
        case actionTypes.UPDATE:
            return {
                ...state,
                ...action.app
            };
        case actionTypes.SET_ACTIVE_MODULE:
            return {
                ...state,
                activeModule: action.activeModule
            };
        case actionTypes.ADD_NOTIFICATION:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    action.notification
                ]
            };
        case actionTypes.REMOVE_NOTIFICATIONS:
            return {
                ...state,
                notifications: []
            };
        default:
            return state;
    }
};
