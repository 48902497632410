import React, { useEffect, useState } from 'react';
import { View, Keyboard, Dimensions, Platform, TouchableWithoutFeedback } from 'react-native';
export default props => {
    const [keyboardHeight, setKeyboardHeight] = useState(0);
    useEffect(() => {
        Keyboard.addListener("keyboardDidShow", _keyboardDidShow);
        Keyboard.addListener("keyboardDidHide", _keyboardDidHide);
        // cleanup function
        return () => {
            Keyboard.removeListener("keyboardDidShow", _keyboardDidShow);
            Keyboard.removeListener("keyboardDidHide", _keyboardDidHide);
        };
    }, []);
    const _keyboardDidShow = (event) => {
        const { height: windowHeight } = Dimensions.get("window");
        const keyboardHeight = event.endCoordinates.height;
        if (Platform.os == 'ios')
            setKeyboardHeight(keyboardHeight);
    };
    const _keyboardDidHide = () => {
        if (Platform.os == 'ios')
            setKeyboardHeight(0);
    };
    return (<TouchableWithoutFeedback onPress={() => {
        if (['ios', 'android'].indexOf(Platform.OS) !== -1)
            Keyboard.dismiss();
    }}>
      <View style={{ marginBottom: keyboardHeight, ...props.style }}>
        {props.children}
      </View>
    </TouchableWithoutFeedback>);
};
