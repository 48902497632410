import React, { useState } from 'react';
// REACT NATIVE COMPONENTS
import { View, Dimensions } from "react-native";
// OWN ROOT COMPONENTS
import StyledText from '../../../../../../../components/StyledText';
// WRAPPERS AND CONTEXT CREATORS
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
// STYLING
const styles = {
    container: {
        backgroundColor: '#EEEFF3',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        paddingBottom: 30
    },
    title: {
        color: '#333333',
        fontWeight: '500',
        fontSize: 25,
        textAlign: 'center',
        marginTop: 23,
        marginBottom: 34
    },
    ovals: {
        flexDirection: 'row',
        marginHorizontal: 32,
        justifyContent: 'space-around'
    },
    ovalWrap: {},
    oval: {
        width: Dimensions.get('window').width * 0.34,
        height: Dimensions.get('window').width * 0.34,
        borderRadius: Dimensions.get('window').width * 0.17,
        backgroundColor: 'red',
        justifyContent: 'center',
        alignItems: 'center'
    },
    ovalTitle: {
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 45,
        fontWeight: '500'
    },
    ovalSubtitle: {
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 16,
        fontWeight: '500'
    },
    pill: {
        backgroundColor: '#FFFFFF',
        borderRadius: 15,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 30,
        marginTop: 10
    },
    pillKey: {
        fontSize: 13,
        color: '#8D8E8F',
        marginRight: 10
    },
    pillValue: {
        fontSize: 13,
        color: '#8D8E8F'
    },
    widePills: {
        marginTop: 20,
        flexDirection: 'row',
        marginLeft: 20,
        marginRight: 7
    },
    widePill: {
        borderRadius: 10,
        backgroundColor: '#4ABE95',
        flex: 1,
        marginRight: 13,
        paddingHorizontal: 5,
        paddingVertical: 10,
    },
    widePillWrong: {
        backgroundColor: '#E97D6E'
    },
    widePillKey: {
        color: '#FFFFFF',
        fontWeight: '500',
        fontSize: 30,
        textAlign: 'center'
    },
    widePillValue: {
        color: '#FFFFFF',
        fontWeight: '500',
        fontSize: 13,
        textAlign: 'center'
    },
};
const Component = props => {
    const [ovalsWidth, setOvalsWidth] = useState(null);
    const { t, i18n } = useTranslation();
    const isBig = useMediaQuery({ query: '(min-width: 800px)' });
    if (props.report && props.report[0]) {
        const points = props.report[0].position.points || '-';
        const position = props.report[0].position.position || '-';
        const timelinePointsArray = props.report[0].percents.filter(p => p._id == 'timeline');
        const timelinePoints = timelinePointsArray.length > 0 ? timelinePointsArray[0].points : '-';
        const gamingPointsArray = props.report[0].percents.filter(p => p._id == 'gaming');
        const gamingPoints = gamingPointsArray.length > 0 ? gamingPointsArray[0].points : '-';
        const onOvalsLayout = (e) => {
            setOvalsWidth(e.nativeEvent.layout.width);
            console.log(e.nativeEvent.layout.width);
        };
        return props.report && props.report[0] && props.report[0].correctRatio ? (<View style={styles.container}>
        <StyledText tkey="gamification.progress.title" style={styles.title}>Progreso</StyledText>
        <View style={styles.ovals} onLayout={onOvalsLayout}>
          <View style={{ ...styles.ovalWrap }}>
            <View style={{ ...styles.oval, backgroundColor: props.services.app.manifest.themeColor, ...(isBig ? { width: ovalsWidth * 0.34, height: ovalsWidth * 0.34, borderRadius: ovalsWidth * 0.17 } : {}) }}>
              <StyledText style={styles.ovalTitle}>{points}</StyledText>
              <StyledText tkey="gamification.progress.points" style={styles.ovalSubtitle}>Puntos</StyledText>
            </View>
            <View style={styles.pill}>
              <StyledText style={styles.pillKey}>{gamingPoints}</StyledText>
              <StyledText tkey="gamification.progress.quizPoints" style={styles.pillValue}>Puntos quiz</StyledText>
            </View>
            <View style={styles.pill}>
              <StyledText style={styles.pillKey}>{timelinePoints}</StyledText>
              <StyledText tkey="gamification.progress.socialPoints" style={styles.pillValue}>Puntos social</StyledText>
            </View>
          </View>
          <View style={styles.ovalWrap}>
            <View style={{ ...styles.oval, backgroundColor: props.services.app.manifest.themeColor, ...(isBig ? { width: ovalsWidth * 0.34, height: ovalsWidth * 0.34, borderRadius: ovalsWidth * 0.17 } : {}) }}>
              <StyledText style={styles.ovalTitle}>{position}</StyledText>
              <StyledText tkey="gamification.progress.ranking" style={styles.ovalSubtitle}>Ranking</StyledText>
            </View>
            <View style={styles.pill}>
              <StyledText style={styles.pillKey}>{props.report[0].participants.total}</StyledText>
              <StyledText tkey="gamification.progress.participants" style={styles.pillValue}>Participantes</StyledText>
            </View>
          </View>

        </View>
        {props.report[0].correctRatio ?
            <View style={styles.widePills}>
            <View style={styles.widePill}>
              <StyledText style={styles.widePillKey}>{Math.round(props.report[0].correctRatio * 100)}%</StyledText>
              <StyledText tkey="gamification.progress.correctRatio" style={styles.widePillValue}>Respuestas correctas</StyledText>
            </View>
            <View style={{ ...styles.widePill, ...styles.widePillWrong }}>
              <StyledText style={styles.widePillKey}>{Math.round((1 - props.report[0].correctRatio) * 100)}%</StyledText>
              <StyledText tkey="gamification.progress.incorrectRatio" style={styles.widePillValue}>Respuestas falladas</StyledText>
            </View>
          </View>
            : null}
      </View>) : null;
    }
    else {
        return null;
    }
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
