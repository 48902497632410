import React from 'react';
// REACT NATIVE COMPONENTS
import { View, ScrollView, Platform } from "react-native";
// OWN ROOT COMPONENTS
import SmallHeader from '../../../../components/SmallHeader';
import StyledText from '../../../../components/StyledText';
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import moment from "moment-timezone";
import workingLocale from "../../../../services/locale";
const locale = workingLocale();
// STYLING
const styles = {
    container: {
        flex: 1
    },
    scrollView: {
        ...(Platform.OS == 'web' ? { flex: 1 } : { flexGrow: 1 })
    },
    headerInfo: {
        flexDirection: 'column',
        paddingHorizontal: 20
    },
    title: {
        fontSize: 25,
        fontWeight: "500",
        color: "#FFFFFF",
        marginTop: 10,
    },
    subtitle: {
        fontSize: 17,
        lineHeight: 19,
        fontWeight: 'normal',
        color: "#FFFFFF",
        opacity: 0.6,
        marginTop: 5,
        marginBottom: 30
    },
    detail: {
        backgroundColor: '#ffffff',
        flex: 1,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        padding: 15,
        paddingHorizontal: 0,
    },
    detailTitle: {
        fontSize: 20,
        lineHeight: 26,
        fontWeight: '500',
        color: "#333333",
        marginTop: 10,
        marginBottom: 10,
        paddingHorizontal: 20
    },
    detailBody: {
        fontSize: 16,
        lineHeight: 24,
        color: "#444444",
        flex: 1,
        backgroundColor: '#ffffff',
        paddingHorizontal: 20
    }
};
const jsUcfirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
const Component = props => {
    const item = props.route.params.item;
    return (<View style={{ ...styles.container, backgroundColor: props.services.app.manifest.themeColor }}>
      <SmallHeader negative title={props.route.params.title} noRound numberOfLines={1} backAction={() => props.navigation.goBack()}/>
      <ScrollView contentContainerStyle={styles.scrollView} style={{ flex: 1 }}>
        <View style={styles.headerInfo}>
          <StyledText variant="h1" style={styles.title}>
            {item.title}
          </StyledText>
          <StyledText variant="h1" style={styles.subtitle}>
            {moment(item.startDate).isSame(moment(item.endDate), 'day') ?
        `${jsUcfirst(moment(item.startDate).format('dddd D, HH:mm'))} h — ${moment(item.endDate).format('HH:mm')} h`
        :
            `${jsUcfirst(moment(item.startDate).format('dddd D, HH:mm'))} h — ${jsUcfirst(moment(item.endDate).format('dddd D, HH:mm'))} h`}
          </StyledText>
        </View>
        <View style={styles.detail}>
          <StyledText variant="h2" style={styles.detailTitle} tkey="agenda.info">
            Info
          </StyledText>
          <StyledText style={styles.detailBody}>
            {item.body}
          </StyledText>

        </View>
      </ScrollView>

    </View>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
