import React, { useContext } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { FeathersContext, } from "../../services/feathers/FeathersProvider";
import { useForm } from "react-hook-form";
import validation from "./validation.tsx";
import { StyleSheet, View, KeyboardAvoidingView, Platform, TouchableWithoutFeedback, Keyboard, } from "react-native";
import StyledText from "../../components/StyledText";
import { Form, FormGroup, Input, RadioGroup } from "../../components/Form/index.tsx";
import BelongButton from "../../components/BelongButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../services/app/actions';
import { useTranslation } from 'react-i18next';
const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 15,
        paddingVertical: 25,
        backgroundColor: "#8681FF",
        flex: 1,
    },
    inner: {
        flex: 1,
        alignItems: "center",
        justifyContent: "space-between",
    },
    headerArea: {
        marginTop: 75,
    },
    subtitle: {
        marginTop: 10,
    },
});
const ContainerSwitch = (props) => {
    const { control, handleSubmit, errors, reset, setValue } = useForm();
    const feathersContext = useContext(FeathersContext);
    const { t, i18n } = useTranslation();
    const onSubmit = (data) => {
        Keyboard.dismiss();
        props.actions.app.update({ env: data.env, slug: data.slug });
        props.dismiss();
        // setTimeout(() => {
        //   feathersContext
        //     .get("apps", data.slug)
        //     .then((r) => {
        //       alert(JSON.stringify(r))
        //     })
        //     .catch((e) => {
        //       alert(JSON.stringify(e))
        //       Alert.alert(t('signIn.wrongTitle'), t('signIn.wrongBody'));
        //     });
        // }, 5000);
    };
    return (<SafeAreaView style={{
        ...styles.container,
        backgroundColor: props.services.app.manifest.themeColor,
    }}>
      <KeyboardAvoidingView behavior={Platform.OS == "ios" ? "padding" : "height"} style={styles.inner}>
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <React.Fragment>
            <View style={styles.headerArea}>
              <StyledText variant="h1" white centered>
                Cambiar entorno
              </StyledText>
            </View>
            <Form {...{ control, validation, errors }}>
              <FormGroup elementType="FormGroup">
                <RadioGroup name="env" label={'Entorno'} options={[
        {
            id: '1',
            label: 'Producción',
            value: 'production'
        }, {
            id: '2',
            label: 'Staging',
            value: 'staging'
        }
    ]}/>
                <Input name="slug" label={'Código de app'} autoCapitalize="none" last/>
              </FormGroup>
              <View style={{
        flexDirection: "row",
        justifyContent: "center",
        alignSelf: "stretch",
    }}>
                <BelongButton color={props.services.app.manifest.themeColor} white disabled={false} onPress={handleSubmit(onSubmit)}>
                  Cambiar
                </BelongButton>
              </View>
            </Form>
          </React.Fragment>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </SafeAreaView>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), dispatch => ({
    actions: {
        app: bindActionCreators(appActionCreators, dispatch)
    }
}))(ContainerSwitch);
