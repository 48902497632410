import React from 'react';
// REACT NATIVE COMPONENTS
import { TouchableOpacity } from "react-native";
// OWN ROOT COMPONENTS
import StyledText from '../../../../../../../../components/StyledText';
// OTHER UTILS
// STYLING
const styles = {
    button: {
        padding: 20,
        borderRadius: 10,
        backgroundColor: '#FFFFFF',
        marginTop: 15
    },
    right: {
        backgroundColor: '#4CE297',
    },
    wrong: {
        backgroundColor: '#E97D6E',
    },
    buttonText: {
        fontWeight: '500',
        color: '#333333',
        fontSize: 17
    },
    rightWrongText: {
        color: '#FFFFFF',
    }
};
export default props => {
    return (<TouchableOpacity disabled={props.disabled} onPress={() => props.onSelectAnswer(props.option)} style={{ ...styles.button, ...(props.selected ? { backgroundColor: props.themeColor } : {}), ...(props.right ? styles.right : {}), ...(props.wrong ? styles.wrong : {}) }}>
      <StyledText style={{ ...styles.buttonText, ...(props.right || props.wrong || props.selected ? styles.rightWrongText : {}) }}>{props.option.title}</StyledText>
    </TouchableOpacity>);
};
