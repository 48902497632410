import React from 'react';
// REACT NATIVE COMPONENTS
import { View, TouchableOpacity } from "react-native";
// OWN ROOT COMPONENTS
import StyledText from '../../../../../components/StyledText';
import moment from "moment-timezone";
// STYLING
const styles = {
    container: {
        backgroundColor: '#A43F88',
        flexDirection: 'row',
        marginHorizontal: 23,
        marginTop: 18,
        borderRadius: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        padding: 15
    },
    meta: {
        flex: 1,
        flexDirection: 'column'
    },
    titleLabel: {
        color: '#ffffff',
        fontSize: 17,
        fontWeight: '500',
        marginBottom: 5
    },
    timeLabel: {
        color: '#ffffff',
        opacity: 0.6,
        fontSize: 13,
    },
    actions: {}
};
export default ({ appointment, themeColor, ...props }) => {
    return (<TouchableOpacity onPress={props.onPress} style={{ ...styles.container, backgroundColor: themeColor }}>
      <View style={styles.meta}>
        <StyledText style={styles.titleLabel}>{appointment.title}</StyledText>
        <StyledText style={styles.timeLabel}>{`${moment(appointment.startDate).format('HH:mm')} h — ${moment(appointment.endDate).format('HH:mm')}`} h</StyledText>
      </View>
      <View style={styles.actions}>
      </View>
    </TouchableOpacity>);
};
