import React, { useContext } from 'react';
import { Animated, FlatList, View, Text, TouchableOpacity, TouchableWithoutFeedback, Linking } from 'react-native';
import CollapsibleWrapper from "../../components/CollapsibleWrapper";
import BelongIcon from "../../components/BelongIcon";
import StyledText from "../../components/StyledText";
import ScaledImage from "../../components/ScaledImage";
import { connect } from "react-redux";
import hextorgba from "../../utils/hextorgba";
import { FeathersContext, } from "../../services/feathers/FeathersProvider";
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../i18n';
import { useMediaQuery } from 'react-responsive';
const styles = {
    backdrop: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    },
    container: {
        backgroundColor: "#EEEFF3",
        flex: 1,
        position: "relative",
    },
    containerBig: {
        width: '100%',
        maxWidth: 400,
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 4,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0
    },
    headerContainer: {
        flex: 1,
        flexDirection: "column"
    },
    headerUpper: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    name: {
        fontSize: 25,
        fontWeight: "500",
        color: "#333333",
        marginTop: 5,
    },
    subtitle: {
        fontSize: 13,
        color: "#8D8E8F",
        marginTop: 8,
    },
    headerLower: {
        height: 56,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    title: {
        fontSize: 16,
        fontWeight: "500",
    },
    barButton: {
        width: 30,
        height: 30,
        borderRadius: 15,
        justifyContent: "center",
        alignItems: "center",
    },
    barIcon: {
        fontSize: 24,
    },
    barIconSmall: {
        fontSize: 24,
    },
    listItem: {
        height: 70,
        marginHorizontal: 16,
        borderBottomColor: "#E9E9E9",
        borderBottomWidth: 1,
        flexDirection: "row",
        alignItems: "center",
    },
    listItemIcon: {
        color: "#8D8E8F",
        fontSize: 25,
    },
    listItemTitle: {
        color: "#333333",
        fontSize: 17,
        fontWeight: "500",
        marginLeft: 15,
        flex: 1,
    },
    listItemBadge: {
        marginRight: 3,
        width: 24,
        height: 24,
        borderRadius: 12,
        backgroundColor: "#D84444",
        justifyContent: 'center',
        alignItems: 'center'
    },
    listItemBadgeText: {
        fontSize: 13,
        color: '#ffffff',
        fontWeight: '500'
    },
};
const Inbox = props => {
    const { t, i18n } = useTranslation();
    const feathersContext = useContext(FeathersContext);
    const localeContext = useLocale();
    const locale = localeContext.workingLocale();
    const isBig = useMediaQuery({ query: '(min-width: 800px)' });
    const menuItems = [];
    if (props.services.app.feathersUser._id && props.services.app.feathersUser.fastpassEnabled) {
        menuItems.push({
            key: "Acreditación",
            icon: "md-qrcode",
            title: t('inbox.fastpass'),
            onPress: () => props.navigation.navigate("FastPass"),
        });
    }
    menuItems.push({
        key: "Notificaciones",
        icon: "md-bell",
        title: t('inbox.notifications'),
        onPress: () => props.navigation.navigate("Notifications"),
    });
    if (props.services.app.feathersUser._id) {
        menuItems.push({
            key: "Participantes",
            icon: "md-users",
            title: t('inbox.users'),
            onPress: () => props.navigation.navigate("UserList"),
        });
    }
    menuItems.push({
        key: "Version",
        icon: "md-cog",
        title: t('inbox.version'),
        onPress: () => props.navigation.navigate("VersionInfo"),
    });
    if (props.services.app.manifest.infoEnabled) {
        menuItems.push({
            key: "Info",
            icon: "md-document",
            title: props.services.app.manifest.infoTitle[locale],
            onPress: () => props.navigation.navigate("Info", {
                title: props.services.app.manifest.infoTitle[locale],
                content: props.services.app.manifest.infoContent[locale]
            }),
        });
    }
    if (props.services.app.feathersUser._id) {
        // menuItems.push(
        //   {
        //     key: "Perfil",
        //     icon: "md-user",
        //     title: t('inbox.profile'),
        //     onPress: () => props.navigation.navigate("Profile"),
        //   }
        // );
        menuItems.push({
            key: "Cerrar sesión",
            icon: "md-arrow-narrow-left",
            title: t('inbox.logout'),
            onPress: () => {
                feathersContext.logOut();
                props.navigation.navigate("LoginStack");
            },
        });
    }
    else {
        if (props.services.app.manifest.accessOptions.loginable) {
            menuItems.push({
                key: "Acceder",
                icon: "md-user",
                title: t('signIn.title'),
                onPress: () => {
                    props.navigation.navigate("LoginStack");
                },
            });
        }
        if (props.services.app.manifest.accessOptions.registerable) {
            menuItems.push({
                key: "Registro",
                icon: "md-pencil",
                title: t('signUp.title'),
                onPress: () => {
                    if (props.services.app.manifest.accessOptions.registerOverride) {
                        Linking.openURL(props.services.app.manifest.accessOptions.registerURL[locale]);
                    }
                    else {
                        props.navigation.navigate("LoginStack", { screen: "SignUp" });
                    }
                },
            });
        }
    }
    return (<TouchableWithoutFeedback onPress={() => props.navigation.goBack()}>
      <View style={styles.backdrop}>
        <View style={{ ...styles.container, ...(isBig ? styles.containerBig : {}) }}>
          <CollapsibleWrapper isSidebar headerComponent={(disappearingOpacity, appearingOpacity) => (<View style={styles.headerContainer}>
                {props.services.app.feathersUser._id ?
        <Animated.View style={{ ...styles.headerUpper, opacity: disappearingOpacity }}>
                    <TouchableOpacity onPress={() => props.navigation.navigate("Profile")} style={{ ...styles.headerUpper, marginTop: isBig ? 40 : 0 }}>
                      {props.services.app.feathersUser.picture ? (<ScaledImage width={240} height={240} fit="cover" image={props.services.app.feathersUser.picture} style={{
            width: 70,
            height: 70,
            borderRadius: 35,
            backgroundColor: "#333",
        }}/>) : (<View style={{
            width: 70,
            height: 70,
            borderRadius: 35,
            backgroundColor: "#333",
        }}></View>)}
                      <StyledText style={styles.name}>
                        {props.services.app.feathersUser.firstName}{" "}
                        {props.services.app.feathersUser.lastName}
                      </StyledText>
                      <StyledText style={styles.subtitle}>
                        {props.services.app.feathersUser.subtitle}
                      </StyledText>
                    </TouchableOpacity>
                  </Animated.View>
        :
            <View style={styles.headerUpper}/>}
                <View style={styles.headerLower}>
                  <TouchableOpacity style={styles.barButton} onPress={() => props.navigation.navigate("StackNavigator")}>
                    <StyledText>
                      <BelongIcon name="md-cheveron-left" style={{
        ...styles.barIcon,
        color: props.services.app.manifest.themeColor,
    }}/>
                    </StyledText>
                  </TouchableOpacity>

                  {props.services.app.feathersUser._id ?
        <TouchableOpacity style={{
            ...styles.barButton,
            backgroundColor: hextorgba(props.services.app.manifest.themeColor, 0.1),
        }} onPress={() => props.navigation.navigate("Profile")}>
                      <StyledText>
                        <BelongIcon name="md-cog" style={{
            ...styles.barIconSmall,
            color: props.services.app.manifest.themeColor,
        }}/>
                      </StyledText>
                    </TouchableOpacity>
        : null}
                </View>
              </View>)} backgroundColor="#EEEFF3" bodyBackgroundColor="#FFFFFF" headerComponentStyle={{
        backgroundColor: "#EEEFF3",
        paddingHorizontal: 15,
    }} bodyComponentStyle={{
        flex: 1,
    }}>
            <FlatList contentContainerStyle={{}} data={menuItems} renderItem={({ item }) => (<TouchableOpacity style={styles.listItem} onPress={item.onPress}>
                  <BelongIcon name={item.icon} style={styles.listItemIcon}></BelongIcon>
                  <Text style={styles.listItemTitle}>{item.title}</Text>
                  {item.badge && (<View style={styles.listItemBadge}>
                      <StyledText style={styles.listItemBadgeText}>
                        {item.badge}
                      </StyledText>
                    </View>)}
                </TouchableOpacity>)}/>
          </CollapsibleWrapper>
        </View>
      </View>
    </TouchableWithoutFeedback>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Inbox);
