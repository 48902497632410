import React from "react";
// REACT NATIVE COMPONENTS
import { StyleSheet } from "react-native";
// OWN ROOT COMPONENTS
import SmallHeader from "../../../components/SmallHeader";
import BigHeader from "../../../components/BelongHeader/big";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import { useLocale } from '../../../i18n';
import MediaQuery from 'react-responsive';
// STYLING
const styles = StyleSheet.create({});
const Component = (props) => {
    const localeContext = useLocale();
    const locale = localeContext.workingLocale();
    const original = props.route.params.module.content[locale];
    const user = props.services.app.feathersUser;
    const filled = props.route.params.module.autofill && props.services.app.feathersAuthenticated ?
        original
            .replaceAll('${firstName}', user.firstName)
            .replaceAll('${lastName}', user.lastName)
            .replaceAll('${email}', user.email)
            .replaceAll('${id}', user._id)
        : original;
    return (<>
      <MediaQuery minWidth={800}>
        <BigHeader navigation={props.navigation}>
          {props.services.app.manifest.title}
        </BigHeader>
      </MediaQuery>
      <SmallHeader backAction={() => props.navigation.goBack()} title={props.route.params.title || props.route.params.module.title[locale]} themeColor={props.services.app.manifest.themeColor} noRound negative/>
      <iframe frameBorder={0} style={{ flex: 1, borderTopLeftRadius: 20, borderTopRightRadius: 20, backgroundColor: '#ffffff' }} src={filled} allow="autoplay; fullscreen; camera; microphone; display-capture" allowFullScreen webkitallowfullscreen mozallowfullscreen></iframe>
    </>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
