import React, { useState } from "react";
// REACT NATIVE COMPONENTS
import { TouchableOpacity, View } from "react-native";
// OWN ROOT COMPONENTS
import BelongIcon from "../../../../../components/BelongIcon";
import StyledText from "../../../../../components/StyledText";
// STYLING
const styles = {
    container: {
        flexDirection: "row",
        alignItems: "center",
        height: 70,
        borderBottomWidth: 1,
        borderBottomColor: "#E9E9E9",
        marginHorizontal: 15,
        paddingHorizontal: 10
    },
    title: {
        marginLeft: 15,
        fontSize: 17,
        fontWeight: "500",
        color: "#333333",
        flex: 1,
    },
    dot: {
        width: 10,
        height: 10,
        borderRadius: 5,
        marginLeft: 15
    }
};
export default ({ themeColor, key, item, progress, onPress = () => { }, ...props }) => {
    const [downloaded, setDownloaded] = useState(true);
    let icon = "";
    switch (item.contentType) {
        case "list":
            icon = "md-folder";
            break;
        case "general":
            icon = "md-document";
            break;
        case "attachment":
            icon = "md-paper-clip";
            break;
        case "external":
            icon = "md-globe-alt";
            break;
        case "gallery":
            icon = "md-photograph";
            break;
    }
    if (props.downloaded) {
        props.downloaded().then(r => setDownloaded(r));
    }
    return (<TouchableOpacity key={key} onPress={onPress} style={styles.container}>
      <BelongIcon name={icon} size={38} color={themeColor}/>

      <StyledText style={styles.title}>{item.title}</StyledText>
      {!downloaded && <View style={{ ...styles.dot, backgroundColor: themeColor }}/>}
    </TouchableOpacity>);
};
