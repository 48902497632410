import * as actionTypes from './actionTypes';
const initialState = {
    loading: false,
    items: [],
    selected: {}
};
export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE:
            return {
                loading: false,
                items: action.items,
                selected: state.selected
            };
        case actionTypes.SET_SELECTED:
            return {
                loading: state.loading,
                items: state.items,
                selected: action.selected
            };
        case actionTypes.EMPTY:
            return {
                loading: state.loading,
                items: [],
                selected: {}
            };
        case actionTypes.LOADING:
            return {
                loading: true,
                items: state.items,
                selected: state.selected,
            };
        default:
            return state;
    }
};
