import React, { useEffect, useState, useContext } from 'react';
// REACT NATIVE COMPONENTS
import { View, FlatList, TouchableOpacity } from "react-native";
// OWN ROOT COMPONENTS
import SmallHeader from '../../../components/SmallHeader';
import StyledText from '../../../components/StyledText';
import DayScroll from './components/DayScroll';
import Event from './components/Event';
import BelongSpinner from '../../../components/BelongSpinner';
import BigHeader from '../../../components/BelongHeader/big';
import BelongIcon from '../../../components/BelongIcon';
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import { useLocale, moment } from '../../../i18n';
import MediaQuery, { useMediaQuery } from 'react-responsive';
// STYLING
const styles = {
    containerBig: {
        marginHorizontal: 'auto',
        width: '100%',
        maxWidth: 675,
        backgroundColor: '#FFFFFF',
        shadowColor: "#333",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.2,
        shadowRadius: 6.68,
        elevation: 11,
        borderRadius: 10,
        marginTop: 50,
        flex: 1,
        marginBottom: 40
    },
    headerDesktop: {
        height: 100,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingHorizontal: 15
    },
    desktopBackOuter: {
        flex: 1
    },
    desktopBackWrap: {
        backgroundColor: 'rgba(55,66,96,0.05)',
        width: 36,
        height: 36,
        borderRadius: 18,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    barButton: {
        height: 30,
        borderRadius: 15,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    barIcon: {
        fontSize: 24,
    },
    title: {
        fontSize: 30,
        fontWeight: "500",
        color: "#FFFFFF",
        marginTop: 5,
    },
    dayList: {
        flex: 1,
        marginTop: 10
    },
    dayListContent: {
        paddingBottom: 25,
        flexGrow: 1, borderWidth: 1, borderColor: 'transparent'
    },
    scrollView: {
        marginTop: 20,
        flex: 1,
    },
    promptBox: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    prompt: {
        color: '#E7E7E7',
        fontWeight: '500',
        fontSize: 16
    }
};
// What should be handling layout overlaps
const PIXELS_HOUR = 225;
// Practical
//const PIXELS_HOUR = 425;
const Component = props => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);
    const [appointments, setAppointments] = useState(null);
    const [days, setDays] = useState({});
    const feathersContext = useContext(FeathersContext);
    const localeContext = useLocale();
    const isBig = useMediaQuery({ query: '(min-width: 800px)' });
    const dayPressed = (day) => {
        setSelectedDay(day);
        console.log(day);
        setMarked(m => {
            const copy = {};
            Object.keys(m).map(k => {
                const { selected, ...rest } = m[k];
                copy[k] = rest;
            });
            return { ...copy, [day.dateString]: { ...copy[day.dateString], selected: true } };
        });
    };
    useEffect(() => {
        setLoading(true);
        feathersContext.onlyFind('appointments', {
            query: {
                moduleId: props.route.params.module._id
            }
        }).then(r => {
            setAppointments(r.data);
            const days = [];
            for (let a of r.data) {
                const firstDay = parseInt(moment(a.startDate).format('YYYYMMDD'));
                const lastDay = parseInt(moment(a.endDate).format('YYYYMMDD'));
                for (var i = firstDay; i <= lastDay; i++) {
                    days.push(i);
                }
            }
            const uniqueDays = [...new Set(days)];
            setDays(uniqueDays);
            setLoading(false);
            setError(false);
            // setTimeout(() => {
            //   setLoading(false);
            //   setError(true);
            // }, 3000)
        }).catch(e => {
            console.log("Error", e);
            setLoading(false);
            setError(true);
        });
    }, []);
    useEffect(() => {
        // Auto set day (first or current)
        if (days.length > 0 && !selectedDay) {
            const todays = days.filter(d => moment(d.toString(), 'YYYYMMDD').isSame(moment(), "day"));
            const today = todays[0] || days[0];
            setSelectedDay(today);
        }
    }, [days, selectedDay]);
    const dayEvents = selectedDay ? appointments.filter(a => {
        return parseInt(moment(a.startDate).format('YYYYMMDD')) <= selectedDay && parseInt(moment(a.endDate).format('YYYYMMDD')) >= selectedDay;
    }) : [];
    return (<React.Fragment>
      <MediaQuery minWidth={800}>
        <BigHeader navigation={props.navigation}>
          {props.route.params.title || props.route.params.module.title}
        </BigHeader>
        <View style={{ ...styles.headerDesktop, backgroundColor: props.services.app.manifest.themeColor }}>
          <View style={styles.desktopBackOuter}>
            <TouchableOpacity style={[styles.barButton, isBig ? styles.desktopBackWrap : {}]} onPress={() => {
        props.navigation.goBack();
        if (props.route.params.onGoBack)
            props.route.params.onGoBack();
    }}>
              <StyledText>
                <BelongIcon name="md-cheveron-left" style={{
        ...styles.barIcon,
        color: '#ffffff',
    }}/>
              </StyledText>
            </TouchableOpacity>
          </View>
          <StyledText variant="h1" style={styles.title}>
            {props.route.params.title || props.route.params.module.title}
          </StyledText>
          <View style={{ flex: 1 }}>
          </View>
        </View>
      </MediaQuery>
      <View style={{ flex: 1, backgroundColor: isBig ? '#E9EAEE' : '#FFFFFF' }}>
        <MediaQuery maxWidth={799}>
          <SmallHeader title={props.route.params.title || props.route.params.module.title} noRound backAction={() => props.navigation.goBack()}/>
        </MediaQuery>

        {loading || error ?
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <BelongSpinner error={error}/>
            </View>
        :
            <React.Fragment>
              <View style={isBig ? styles.containerBig : {}}>
                <DayScroll themeColor={props.services.app.manifest.themeColor} days={days} selectedDay={selectedDay} onDaySelect={d => setSelectedDay(d)}/>
                {selectedDay ?
                <FlatList style={styles.dayList} contentContainerStyle={styles.dayListContent} data={dayEvents} renderItem={({ item, index, separators }) => {
                    return (<Event onPress={() => props.navigation.navigate("AgendaDetail", { item, title: props.route.params.title || props.route.params.module.title })} key={item._id} appointment={item} themeColor={props.services.app.manifest.themeColor}/>
                    //<EventGroup navigation={props.navigation} initialHour={initialHour} finalHour={finalHour} pixelsHour={PIXELS_HOUR} item={item} />
                    );
                }}/>
                // <ScrollView style={styles.scrollView}>
                //   <View style={{position: 'relative'}}>
                //     <HourList pixelsHour={PIXELS_HOUR} initialHour={initialHour} finalHour={finalHour} />
                //     <View style={styles.dayList}>
                //       {dayEvents.map((item) => (
                //         <EventGroup navigation={props.navigation} initialHour={initialHour} finalHour={finalHour} pixelsHour={PIXELS_HOUR} item={item} />
                //       ))}
                //     </View>
                //     {/*
                //     <FlatList
                //       style={styles.dayList}
                //       contentContainerStyle={styles.dayListContent}
                //       data={dayEvents}
                //       renderItem={({ item, index, separators }) => {
                //         return (
                //           //Event onPress={() => props.navigation.navigate("AgendaDetail", { item }) } key={item._id} appointment={item} themeColor={props.services.app.manifest.themeColor} />
                //           <EventGroup navigation={props.navigation} initialHour={initialHour} finalHour={finalHour} pixelsHour={PIXELS_HOUR} item={item} />
                //         );
                //       }}
                //     />
                //     */}
                //   </View>
                // </ScrollView>
                :
                    <View style={styles.promptBox}>
                      <StyledText tkey="agenda.prompt" style={styles.prompt}>Selecciona un día</StyledText>
                    </View>}
              </View>

            </React.Fragment>}

      </View>
    </React.Fragment>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
