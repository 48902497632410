import React, { useState } from 'react';
// REACT NATIVE COMPONENTS
import { View, TouchableOpacity } from "react-native";
// OWN ROOT COMPONENTS
import StyledText from '../../../../../../components/StyledText';
import ScaledImage from '../../../../../../components/ScaledImage';
import PlaceholderAvatar from '../../../../../../components/PlaceholderAvatar';
// OTHER UTILS
import axios from "axios";
import apiConfig from "../../../../../../services/api/config";
import { useLocale } from '../../../../../../i18n';
import { useTranslation } from 'react-i18next';
// STYLING
const styles = {
    container: {
        flexDirection: 'row',
        paddingVertical: 15,
        marginHorizontal: 15,
    },
    pic: {
        width: 36,
        height: 36,
        borderRadius: 18,
    },
    commentInfo: {
        marginLeft: 15,
        flexDirection: 'column',
        flex: 1
    },
    userName: {
        fontSize: 16,
        fontWeight: "500",
        color: "#333333",
    },
    comment: {
        fontSize: 13,
        lineHeight: 21,
        color: "#444444",
    },
    translateButton: {
        marginTop: 6
    },
    translateText: {
        fontSize: 13,
        color: '#CACACA'
    },
};
export default ({ comment, themeColor, ...props }) => {
    const localeContext = useLocale();
    const { t, i18n } = useTranslation();
    const [translating, setTranslating] = useState(false);
    const [translated, setTranslated] = useState(false);
    const translate = (text, locale) => axios.post(`${apiConfig.url[props.env]}translations`, {
        text,
        target: locale
    });
    const translateText = text => {
        setTranslating(true);
        translate(text, localeContext.deviceLocale())
            .then(r => {
            setTranslating(false);
            setTranslated(r.data.translated);
        })
            .catch(e => {
            console.log(e);
            setTranslating(false);
        });
    };
    return (<View style={styles.container}>
      {comment.user.picture ? (<ScaledImage image={comment.user.picture} width={165} height={165} fit="cover" style={styles.pic}/>) : (<PlaceholderAvatar user={comment.user} themeColor={themeColor} style={styles.pic}/>)}
      <View style={styles.commentInfo}>
        <StyledText style={styles.userName}>
          {comment.user.firstName} {comment.user.lastName}
        </StyledText>
        <StyledText style={styles.comment}>
          {translated || comment.content}
        </StyledText>
        {!translated && comment.content && comment.content !== ''
        ? <TouchableOpacity onPress={() => translateText(comment.content)} style={styles.translateButton}>
            <StyledText style={styles.translateText}>
              {translating
            ? t("timeline.translating")
            : t("timeline.translate")}
            </StyledText>
          </TouchableOpacity>
        : null}
      </View>
    </View>);
};
