import React, { useEffect, useContext, useState } from "react";
// REACT NATIVE COMPONENTS
import { View, ScrollView, TouchableOpacity } from "react-native";
// OWN ROOT COMPONENTS
import CollapsibleWrapper from "../../../components/CollapsibleWrapper";
import CollapsibleHeader from "../../../components/CollapsibleHeader";
import StyledText from "../../../components/StyledText";
import ListItem from './components/ListItem';
import Markdown from './components/Markdown';
import BelongSpinner from "../../../components/BelongSpinner";
import BelongIcon from "../../../components/BelongIcon";
import BigHeader from "../../../components/BelongHeader/big";
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../services/feathers/FeathersProvider";
import { useAnalytics } from '../../../Analytics';
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import { FlatList } from "react-native-gesture-handler";
import { useLocale } from '../../../i18n';
import fileOpenHandler, { fileDownloadedHandler } from "../../../helpers/fileOpenHandler";
import MediaQuery, { useMediaQuery } from 'react-responsive';
// STYLING
const styles = {
    contentContainerBig: {
        marginHorizontal: 'auto',
        width: '100%',
        maxWidth: 675,
        backgroundColor: '#ffffff',
        borderRadius: 10,
        shadowColor: "#333",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.2,
        shadowRadius: 6.68,
        elevation: 11,
    },
    headerDesktop: {
        height: 100,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    desktopBackWrap: {
        backgroundColor: 'rgba(55,66,96,0.05)',
        width: 36,
        height: 36,
        borderRadius: 18,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    barButton: {
        height: 30,
        borderRadius: 15,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    barIcon: {
        fontSize: 24,
    },
    title: {
        fontSize: 30,
        fontWeight: "500",
        color: "#FFFFFF",
        marginTop: 5,
    },
    list: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: "#ffffff",
    },
    htmlview: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        flex: 1,
        backgroundColor: "#ffffff",
        paddingHorizontal: 15,
        paddingTop: 20,
        fontFamily: "Matter"
    },
};
const Component = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [currentMode, setCurrentMode] = useState("default");
    const [items, setItems] = useState([]);
    const [item, setItem] = useState(null);
    const [fullModule, setFullModule] = useState(null);
    const [progress, setProgress] = useState({});
    const isBig = useMediaQuery({ query: '(min-width: 800px)' });
    // Load Feathers context
    const feathersContext = useContext(FeathersContext);
    const { trackMixpanelEvent } = useAnalytics();
    const localeContext = useLocale();
    const locale = localeContext.workingLocale();
    useEffect(() => {
        if (props.route.params.fullModule) {
            setFullModule(props.route.params.fullModule);
            // If I'm a subcontent page
            if (props.route.params.subcontents) {
                loadContent(true, props.route.params.subcontents);
            }
            else if (props.route.params.item) {
                loadContent(false, [props.route.params.item]);
            }
            else {
                loadContent(true, props.route.params.fullModule.contents);
            }
        }
        else {
            // Load the entire content of the static module
            setLoading(true);
            feathersContext
                .get("modules", props.route.params.module._id)
                .then((r) => {
                loadContent(true, r.contents);
                setFullModule(r);
                setLoading(false);
                setError(false);
                // setTimeout(() => {
                //   setLoading(false);
                //   setError(true);
                // }, 3000)
            })
                .catch((e) => {
                console.log(e);
                setLoading(false);
                setError(true);
            });
        }
    }, []);
    const loadContent = (isList = true, theItems = []) => {
        // If I'm a list
        if (isList) {
            setCurrentMode("list");
            setItems(theItems);
            console.log("Items", theItems);
        }
        else if (theItems.length == 1) {
            const item = theItems[0];
            setCurrentMode(item.contentType);
            setItem(item);
        }
    };
    const handleItemPress = (item) => {
        switch (item.contentType) {
            case "list":
                props.navigation.push("Module_staticModules", {
                    title: item.title[locale],
                    fullModule: fullModule,
                    subcontents: item.subcontents,
                });
                break;
            case "general":
            case "gallery":
                props.navigation.push("Module_staticModules", {
                    title: item.title[locale],
                    fullModule: fullModule,
                    item: item,
                });
                break;
            case "external":
                props.navigation.push("Module_webtabModules", {
                    module: { title: item.title, content: item.content },
                });
                break;
            case "attachment":
                fileOpenHandler(item, locale, setProgress);
                // const url = `${config.assetsUrl}${encodeURI(item.content[locale])}`;
                // const localFile = `${RNFS.DocumentDirectoryPath}/item_${
                //   item._id
                // }_${url.split("/").pop().split("?")[0]}`;
                // RNFS.exists(localFile).then((exists) => {
                //   if (exists) {
                //     trackMixpanelEvent("openFile", {
                //       moduleId: fullModule._id,
                //       itemId: item._id
                //     });
                //     FileViewer.open(localFile);
                //   } else {
                //     trackMixpanelEvent("downloadFile", {
                //       moduleId: fullModule._id,
                //       itemId: item._id
                //     });
                //     RNFS.downloadFile({
                //       fromUrl: url,
                //       toFile: localFile,
                //       // headers
                //       background: true,
                //       discretionary: true,
                //       cacheable: true,
                //       begin: (res) => {
                //         console.log("Response begin ===\n\n");
                //         console.log(res);
                //       },
                //       progress: (res) => {
                //         // here you can calculate your progress for file download
                //         const progressPercent =
                //           (res.bytesWritten / res.contentLength); // to calculate in percentage
                //         setProgress( prev => ({ ...prev, [item._id]: progressPercent }) );
                //         console.log(res);
                //       },
                //     }).promise.then((res) => {
                //       if (res.statusCode == 200) {
                //         trackMixpanelEvent("openFile", {
                //           moduleId: fullModule._id,
                //           itemId: item._id
                //         });
                //         FileViewer.open(localFile);
                //       } else {
                //         alert(`Error ${res.statusCode}`)
                //       }
                //     }).catch(e => {
                //       alert("Error")
                //     });
                //   }
                // });
                break;
        }
    };
    const itemDownloaded = async (item) => {
        return await fileDownloadedHandler(item, locale);
    };
    return (<React.Fragment>
      <MediaQuery minWidth={800}>
        <BigHeader navigation={props.navigation}>
          {props.services.app.manifest.title}
        </BigHeader>
      </MediaQuery>
      <CollapsibleWrapper headerComponent={(disappearingOpacity, appearingOpacity) => (<React.Fragment>
            <MediaQuery minWidth={800}>
              <View style={styles.headerDesktop}>
                <TouchableOpacity style={[styles.barButton, isBig ? styles.desktopBackWrap : {}]} onPress={() => {
        props.navigation.goBack();
        if (props.route.params.onGoBack)
            props.route.params.onGoBack();
    }}>
                  <StyledText>
                    <BelongIcon name="md-cheveron-left" style={{
        ...styles.barIcon,
        color: '#ffffff',
    }}/>
                  </StyledText>
                </TouchableOpacity>
                <StyledText variant="h1" style={styles.title}>
                  {props.route.params.title || props.route.params.module.title[locale]}
                </StyledText>
                <View>
                </View>
              </View>
            </MediaQuery>
            <MediaQuery maxWidth={799}>
              <CollapsibleHeader negative central={<StyledText variant="h1" style={styles.title}>
                    {props.route.params.title || props.route.params.module.title[locale]}
                  </StyledText>} backAction={() => props.navigation.goBack()} disappearingOpacity={disappearingOpacity} appearingOpacity={appearingOpacity} themeColor={props.services.app.manifest.themeColor}/>
            </MediaQuery>
          </React.Fragment>)} backgroundColor="#EEEFF3" bodyBackgroundColor="#FFFFFF" headerComponentStyle={{
        backgroundColor: props.services.app.manifest.themeColor,
        paddingHorizontal: 15,
    }} bodyComponentStyle={{
        flex: 1,
    }} scrollViewOverride={(onScroll, scrollEventThrottle, contentContainerPadding) => {
        if (loading || error) {
            return (<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingTop: contentContainerPadding }}>
                <BelongSpinner error={error}/>
              </View>);
        }
        else {
            switch (currentMode) {
                case "list":
                    return (<FlatList style={{
                        flex: 1, backgroundColor: isBig ? 'E9EAEE' : '#FFF'
                    }} contentContainerStyle={{
                        ...styles.list,
                        marginTop: contentContainerPadding + (isBig ? 50 : 0),
                        ...(isBig ? styles.contentContainerBig : {}),
                    }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle} showsVerticalScrollIndicator={false} data={items} renderItem={({ key, item, index }) => (<ListItem key={key} item={item} themeColor={props.services.app.manifest.themeColor} onPress={() => handleItemPress(item)} progress={progress[item._id]} downloaded={() => itemDownloaded(item)}/>)} keyExtractor={(item) => item._id}/>);
                    break;
                case "general":
                    return (<ScrollView style={{ flex: 1 }} contentContainerStyle={{
                        ...styles.htmlview,
                        marginTop: contentContainerPadding + (isBig ? 50 : 0),
                        ...(isBig ? styles.contentContainerBig : {}),
                    }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle}>
                    <Markdown content={item.content[locale]}/>
                  </ScrollView>);
                    break;
                case "gallery":
                    return (<View>
                    <StyledText>Gallery</StyledText>
                  </View>);
                    break;
            }
        }
    }}/>
    </React.Fragment>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
