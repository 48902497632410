import { persistCombineReducers } from "redux-persist";
import storage from '../storageService';
import surveyBlocksReducer from "./survey_blocks/reducer";
import postsReducer from "./posts/reducer";
import notificationsReducer from "./notifications/reducer";
import usersReducer from "./users/reducer";
import announcementsReducer from "./announcements/reducer";
import modulesReducer from "./modules/reducer";
const config = {
    key: "data",
    storage
};
export const reducer = persistCombineReducers(config, {
    survey_blocks: surveyBlocksReducer,
    posts: postsReducer,
    notifications: notificationsReducer,
    users: usersReducer,
    announcements: announcementsReducer,
    modules: modulesReducer,
});
