import * as actionTypes from "./actionTypes";
export const initialState = {
    user: {
        firstName: 'Tomeu',
        lastName: 'Penya'
    }
};
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_USER:
            return {
                ...state,
                ...action.user,
            };
        default:
            return state;
    }
};
