import React from 'react';
// REACT NATIVE COMPONENTS
import { StyleSheet, View, Platform } from "react-native";
// OTHER UTILS
import gsap, { Back } from "gsap";
const Zdog = require('zdog');
const spinner = require('../../img/spin.gif');
export default class Splash extends React.Component {
    constructor(props) {
        super(props);
        this.onLayout = () => {
            console.log('Update Layout:', window.innerWidth, window.innerHeight, window.screen.orientation);
        };
        this.initCanvas = (canvas) => {
            if (this.canvas) {
                return;
            }
            this.canvas = canvas;
            if (Platform.OS === 'web' && this.canvas) {
                // canvas.width not equal canvas.clientWidth, so have to assign again
                this.canvas.width = this.canvas.clientWidth;
                this.canvas.height = this.canvas.clientHeight;
            }
            this.zdog();
        };
        this.zdog = () => {
            if (this.canvas) {
                let positions = { posX: 0.005, posY: 2.1, posZ: -1.57 };
                let viewRotation = new Zdog.Vector();
                let dragStartRX, dragStartRY, dragStartRZ;
                // ZDOG Scene
                let illo = new Zdog.Illustration({
                    element: this.canvas,
                });
                // ZDOG Shape
                let zdog = new Zdog.Shape({
                    addTo: illo,
                    path: [
                        { x: -40, y: 0, z: 30 },
                        { x: -40, y: -40, z: 30 },
                        { arc: [
                                { x: -40, y: -60, z: 30 },
                                { x: -20, y: -60, z: 30 },
                            ] },
                        { x: 20, y: -60, z: 30 },
                        { arc: [
                                { x: 40, y: -60, z: 30 },
                                { x: 40, y: -40, z: 30 },
                            ] },
                        { x: 40, y: -20, z: 30 },
                        { arc: [
                                { x: 40, y: 0, z: 30 },
                                { x: 20, y: 0, z: 30 },
                            ] },
                        { x: 10, y: 0, z: 30 },
                        { arc: [
                                { x: -10, y: 0, z: 30 },
                                { x: -10, y: 0, z: 10 },
                            ] },
                        { x: -10, y: 0, z: -10 },
                        { arc: [
                                { x: -10, y: 0, z: -30 },
                                { x: 10, y: 0, z: -30 },
                            ] },
                        { x: 20, y: 0, z: -30 },
                        { arc: [
                                { x: 40, y: 0, z: -30 },
                                { x: 40, y: -20, z: -30 },
                            ] },
                        { x: 40, y: -40, z: -30 },
                        { arc: [
                                { x: 40, y: -60, z: -30 },
                                { x: 20, y: -60, z: -30 },
                            ] },
                        { x: -20, y: -60, z: -30 },
                        { arc: [
                                { x: -40, y: -60, z: -30 },
                                { x: -40, y: -40, z: -30 },
                            ] },
                        { x: -40, y: 30, z: -30 },
                        { arc: [
                                { x: -40, y: 50, z: -30 },
                                { x: -40, y: 50, z: -10 },
                            ] },
                        { x: -40, y: 50, z: 10 },
                        { arc: [
                                { x: -40, y: 50, z: 30 },
                                { x: -40, y: 30, z: 30 },
                            ] },
                    ],
                    closed: true,
                    stroke: 11,
                    color: '#FFBD2C',
                    rotate: { x: positions.posX, y: positions.posY, z: positions.posZ },
                });
                function animateLoader() {
                    let changeY = zdog.rotate.y - Zdog.TAU;
                    gsap.to(positions, { posY: changeY, duration: 1.5, repeat: -1, repeatDelay: 0.15, ease: Back.easeInOut.config(1.1) });
                }
                animateLoader();
                // ZDOG Animation
                function animate() {
                    zdog.rotate = { x: positions.posX, y: positions.posY, z: positions.posZ };
                    illo.rotate.set(viewRotation);
                    illo.updateRenderGraph();
                    requestAnimationFrame(animate);
                }
                animate();
            }
        };
        this.canvas = null;
        // only useful on Android, because it's always true on iOS
        this.isGReactTextureViewReady = true;
    }
    componentDidMount() {
        window.addEventListener('resize', this.onLayout);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.onLayout);
        this.canvas = null;
    }
    render() {
        return (<View style={styles.container}>
        <canvas ref={this.initCanvas} style={{
            width: 200,
            height: 300,
        } /* canvas with react-native-web can't use width and height in styles.gcanvas */}/>
      </View>);
    }
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#FDFBE6',
        justifyContent: 'center',
        alignItems: 'center'
    },
    gcanvas: {
        width: 200,
        height: 300,
    }
});
