import React, { PureComponent } from "react";
import { View } from "react-native";
import ScaledImage from '../ScaledImage';
import Counter from "./components/Counter";
export default class BelongBanner extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { height: 180 };
    }
    render() {
        return (<View style={{
            ...this.props.style,
        }}>
        <ScaledImage image={this.props.image} width={1035} height={540} style={{
            // ...this.props.style,
            borderRadius: 10,
        }}/>
        {this.props.hasCountdown &&
            <Counter eventDate={this.props.eventDate}/>}
      </View>);
    }
}
