import moment from "moment-timezone";
import 'moment/locale/es';
import 'moment/locale/ca';
// import DeviceInfo from "react-native-device-info";
let deviceLocale = 'es';
// if (DeviceInfo.getUserAgent()) {
//   deviceLocale = DeviceInfo.getDeviceLocale().substr(0, 2);
// }
function workingLocale() {
    return "es";
    // const manifest = store.getState().services.app.manifest;
    // if (manifest.langPattern.available.includes(deviceLocale)) {
    //   return deviceLocale;
    // } else if (manifest.langPattern.mappings[deviceLocale]) {
    //   return manifest.langPattern.mappings[deviceLocale];
    // } else if (manifest.langPattern.default) {
    //   return manifest.langPattern.default;
    // } else {
    //   return "en";
    // }
}
export default workingLocale;
export { deviceLocale, moment };
