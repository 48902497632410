import React from 'react';
import { View, TouchableOpacity, Platform } from 'react-native';
import StyledText from '../../../../../../../components/StyledText';
import ActionSheet from "react-native-actions-sheet";
import { useTranslation } from 'react-i18next';
const UserPostSheet = props => {
    const { t, i18n } = useTranslation();
    const webHeight = 170 + (props.isMine ? 150 : 0) + (props.downloadImage || props.downloadVideo ? 75 : 0);
    return (<ActionSheet id="userPostSheet" ref={props.setRef} containerStyle={{ top: Platform.OS == 'web' ? webHeight * -1 : 0 }}>
      <View style={styles.container}>
        <View style={styles.handle}>
          <View style={styles.handleBar}></View>
        </View>
        {props.downloadImage ?
        <TouchableOpacity style={styles.option} onPress={() => {
            props.downloadImage();
            props.dismiss();
        }}>
            <StyledText style={styles.optionText} tkey="timeline.downloadImage"></StyledText>
          </TouchableOpacity>
        : null}
        {props.downloadVideo ?
        <TouchableOpacity style={styles.option} onPress={() => {
            props.downloadVideo();
            props.dismiss();
        }}>
            <StyledText style={styles.optionText} tkey="timeline.downloadVideo"></StyledText>
          </TouchableOpacity>
        : null}
        {props.isMine ?
        <React.Fragment>
            <TouchableOpacity style={styles.option} onPress={() => {
            props.editPost();
            props.dismiss();
        }}>
              <StyledText style={styles.optionText} tkey="timeline.editPost"></StyledText>
            </TouchableOpacity>
            <TouchableOpacity style={styles.option} onPress={() => {
            props.deletePost();
            props.dismiss();
        }}>
              <StyledText style={styles.optionText} tkey="timeline.deletePost"></StyledText>
            </TouchableOpacity>
          </React.Fragment>
        : null}

      </View>
    </ActionSheet>);
};
export default UserPostSheet;
// STYLING
const styles = {
    container: {
        paddingTop: 20,
        paddingHorizontal: 20,
        paddingBottom: 50,
        position: 'relative'
    },
    handle: {
        position: 'absolute',
        top: 10,
        left: 0,
        right: 0,
        flexDirection: 'column',
        alignItems: 'center',
        height: 20
    },
    handleBar: {
        height: 5,
        borderRadius: 999,
        width: 60,
        backgroundColor: '#dddddd'
    },
    option: {
        borderBottomWidth: 1,
        borderBottomColor: '#eeeeee',
        paddingVertical: 25,
    },
    optionText: {
        textAlign: 'center',
        fontSize: 18,
    }
};
