import React, { useState, useEffect, useRef } from 'react';
import { View, Platform } from 'react-native';
import BlurWidget from './components/BlurWidget';
import StyledText from '../../../StyledText';
const styles = {
    centerer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    counter: {
        paddingHorizontal: 16,
        paddingVertical: 13,
        position: 'relative',
        borderRadius: 10,
        overflow: 'hidden'
    },
    counterElements: {
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    counterElement: {
        alignItems: 'center',
        width: 40
    },
    spacerElement: {
        alignItems: 'center',
    },
    counterValue: {
        color: '#EEEFF3',
        fontSize: 21
    },
    counterSpacer: {
        color: '#EEEFF3',
        fontSize: 21,
        lineHeight: Platform.OS == 'android' ? 26 : 22,
    },
    counterKey: {
        color: '#EEEFF3',
        fontSize: 7,
        textTransform: 'uppercase',
        marginTop: 3
    }
};
const addLeadingZero = (value) => {
    if (value < 10) {
        return `0${value}`;
    }
    else {
        return `${value}`;
    }
};
const Counter = (props) => {
    const [seconds, setSeconds] = useState(0.01);
    const timer = useRef(null);
    useEffect(() => {
        const eventDate = new Date(props.eventDate);
        const currentDate = new Date();
        if (eventDate > currentDate) {
            setSeconds(Math.round((eventDate - currentDate) / 1000));
            if (timer.current) {
                clearInterval(timer.current);
                timer.current = null;
            }
            timer.current = setInterval(() => {
                setSeconds(s => s - 1);
            }, 1000);
        }
    }, [props.eventDate]);
    useEffect(() => {
        if (seconds == 0) {
            clearInterval(timer.current);
            timer.current = null;
        }
    }, [seconds]);
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds - days * 86400) / 3600);
    const minutes = Math.floor((seconds - days * 86400 - hours * 3600) / 60);
    const remainingSeconds = Math.round(seconds % 60);
    return (<View style={styles.centerer}>
      <View style={styles.counter}>


        <BlurWidget />

        <View style={styles.counterElements}>
          <View style={styles.counterElement}>
            <StyledText style={styles.counterValue}>{addLeadingZero(days)}</StyledText>
            <StyledText style={styles.counterKey} tkey='home.counter.days'>Días</StyledText>
          </View>
          <View style={styles.spacerElement}>
            <StyledText style={styles.counterSpacer}>:</StyledText>
          </View>
          <View style={styles.counterElement}>
            <StyledText style={styles.counterValue}>{addLeadingZero(hours)}</StyledText>
            <StyledText style={styles.counterKey} tkey='home.counter.hours'>Horas</StyledText>
          </View>
          <View style={styles.spacerElement}>
            <StyledText style={styles.counterSpacer}>:</StyledText>
          </View>
          <View style={styles.counterElement}>
            <StyledText style={styles.counterValue}>{addLeadingZero(minutes)}</StyledText>
            <StyledText style={styles.counterKey} tkey='home.counter.min'>Min</StyledText>
          </View>
          <View style={styles.spacerElement}>
            <StyledText style={styles.counterSpacer}>:</StyledText>
          </View>
          <View style={styles.counterElement}>
            <StyledText style={styles.counterValue}>{addLeadingZero(remainingSeconds)}</StyledText>
            <StyledText style={styles.counterKey} tkey='home.counter.sec'>Sec</StyledText>
          </View>
        </View>
      </View>
    </View>);
};
export default Counter;
