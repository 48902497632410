import React from "react";
// REACT NATIVE COMPONENTS
import { StyleSheet, View, Linking, Platform } from "react-native";
// OWN ROOT COMPONENTS
import BelongIcon from "../../components/BelongIcon";
import StyledText from "../../components/StyledText";
// WRAPPERS AND CONTEXT CREATORS
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import BelongButton from "../../components/BelongButton";
// OTHER UTILS
// STYLING
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginHorizontal: 30
    },
    explanation: {
        fontSize: 16,
        lineHeight: 22,
        color: "#FFFFFF",
        opacity: 0.8,
        textAlign: "center",
        marginTop: 20,
    },
    title: {
        fontSize: 25,
        lineHeight: 29,
        color: "#FFFFFF",
        textAlign: "center",
        marginTop: 20,
    },
    icon: {
        color: "#FFFFFF",
    },
    button: {
        alignSelf: 'center',
        marginBottom: 35,
        marginTop: 0
    }
});
const Update = (props) => {
    return (<View style={{
        flex: 1,
        backgroundColor: props.services.app.manifest.themeColor,
    }}>
      <View style={styles.container}>
        <BelongIcon name="md-exclamation" style={styles.icon} size={45}/>
        <StyledText tkey="update.copy" style={styles.explanation}>
          Hemos realizado varias mejoras de funcionamiento. Para poder continuar
          debes actualizar tu aplicación
        </StyledText>
        <StyledText tkey="update.title" variant="h1" style={styles.title}>
          Por favor, descarga la nueva versión de la app
        </StyledText>
      </View>

      <BelongButton color={props.services.app.manifest.themeColor} style={styles.button} white onPress={() => {
        if (Platform.OS == "ios") {
            Linking.openURL(props.services.app.manifest.appleUrl);
        }
        else if (Platform.OS == "android") {
            Linking.openURL(props.services.app.manifest.androidUrl);
        }
    }} tkey="update.button">
        Actualizar
      </BelongButton>
    </View>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Update);
