import React, { useState, useEffect, useContext } from 'react';
// REACT NATIVE COMPONENTS
import { View, ScrollView, TouchableOpacity, Linking, TouchableWithoutFeedback } from "react-native";
// OWN ROOT COMPONENTS
import CollapsibleWrapper from "../../components/CollapsibleWrapper";
import CollapsibleHeader from "../../components/CollapsibleHeader";
import StyledText from "../../components/StyledText";
import BelongIcon from "../../components/BelongIcon";
import ScaledImage from "../../components/ScaledImage";
import PlaceholderAvatar from "../../components/PlaceholderAvatar";
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import MediaQuery, { useMediaQuery } from 'react-responsive';
// OTHER UTILS
// STYLING
const styles = {
    backdrop: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden'
    },
    container: {
        backgroundColor: "#EEEFF3",
        flex: 1,
        position: "relative",
    },
    containerBig: {
        position: 'absolute',
        overflow: 'hidden',
        width: '100%',
        maxWidth: 600,
        minHeight: 600,
        alignSelf: 'center',
        bottom: -10,
        borderRadius: 10,
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 4,
    },
    headerInfo: {
        flexDirection: 'column',
        alignItems: 'center'
    },
    closeButton: {
        alignSelf: 'flex-end',
        marginRight: 20,
        marginTop: 20
    },
    barIcon: {
        fontSize: 24,
    },
    firstRow: {
        marginTop: 5,
        flexDirection: 'row',
        alignItems: 'center'
    },
    title: {
        fontSize: 25,
        fontWeight: "500",
        color: "#333333",
        textAlign: 'center'
    },
    subtitle: {
        fontSize: 13,
        lineHeight: 19,
        color: "#8D8E8F",
        marginTop: 5,
    },
    list: {
        flex: 1
    },
    group: {
        marginBottom: 15,
        marginHorizontal: 15,
        paddingHorizontal: 8,
        borderRadius: 10,
        backgroundColor: '#FFFFFF',
        shadowColor: "#550000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 8
    },
    item: {
        paddingHorizontal: 8,
        paddingVertical: 15,
        flexDirection: 'column',
        borderBottomWidth: 1,
        borderBottomColor: '#E9E9E9'
    },
    lastItem: {
        borderBottomWidth: 0
    },
    key: {
        fontSize: 13,
        color: '#8D8E8F'
    },
    value: {
        fontSize: 17,
        fontWeight: '500',
        color: '#8D8E8F',
        marginTop: 2
    },
    valueMulti: {
        fontSize: 16,
        lineHeight: 24,
        color: '#444444',
        marginTop: 2
    },
    horizontalGroup: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        paddingVertical: 15
    },
    horizontalItem: {
        alignItems: 'center',
    },
    horizontalKey: {
        color: '#8D8E8F',
        fontSize: 13,
        marginTop: 5
    },
    horizontalValue: {
        color: '#333333',
        fontWeight: '500',
        fontSize: 20
    }
};
const Component = props => {
    const [fullUser, setFullUser] = useState({});
    const [lightboxVisible, setLightboxVisible] = useState(false);
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    let user = props.route.params.user;
    useEffect(() => {
        feathersContext.client.service("users").get(user._id).then(r => {
            setFullUser(r);
        });
    }, [props.route.params.user]);
    const isBig = useMediaQuery({ query: '(min-width: 800px)' });
    return (<TouchableWithoutFeedback onPress={() => props.navigation.goBack()}>
      <View style={styles.backdrop}>
        <TouchableWithoutFeedback onPress={e => e.stopPropagation()}>
          <View style={{ ...styles.container, ...(isBig ? styles.containerBig : {}) }}>
            <MediaQuery minWidth={800}>
              <TouchableOpacity style={styles.closeButton} onPress={() => props.navigation.goBack()}>
                <BelongIcon name="md-x" style={{
        ...styles.barIcon,
        color: '#A3A4A6',
    }}/>
              </TouchableOpacity>
            </MediaQuery>
            <CollapsibleWrapper isSidebar headerComponent={(disappearingOpacity, appearingOpacity) => (<CollapsibleHeader central={<View style={{ ...styles.headerInfo, marginTop: isBig ? 40 : 0 }}>
                      {user.picture ? (<TouchableOpacity onPress={() => setLightboxVisible(true)}>
                          <ScaledImage width={240} height={240} fit="cover" image={user.picture} style={{
        width: 70,
        height: 70,
        borderRadius: 35,
        backgroundColor: "#333",
    }}/>
                        </TouchableOpacity>) : (<PlaceholderAvatar user={user} themeColor={props.services.app.manifest.themeColor} style={{
        width: 70,
        height: 70,
        borderRadius: 35,
        backgroundColor: "#333",
    }}/>)}
                      <View style={styles.firstRow}>
                        <StyledText variant="h1" style={styles.title}>
                          {user.firstName} {user.lastName}
                        </StyledText>
                        
                      </View>

                      <StyledText variant="h1" style={styles.subtitle}>
                        {user.subtitle}
                      </StyledText>
                    </View>} backAction={isBig ? null : () => props.navigation.goBack()} 
    // mainAction={() => {}}
    // mainActionTitle="Buscar"
    disappearingOpacity={disappearingOpacity} appearingOpacity={appearingOpacity} themeColor={props.services.app.manifest.themeColor}/>)} backgroundColor="#EEEFF3" bodyBackgroundColor="#EEEFF3" headerComponentStyle={{
        backgroundColor: "#EEEFF3",
        paddingHorizontal: 15,
    }} bodyComponentStyle={{
        flex: 1,
    }} scrollViewOverride={(onScroll, scrollEventThrottle, contentContainerPadding) => (<View style={{
        ...styles.list,
    }}>
                  <ScrollView contentContainerStyle={{ paddingTop: contentContainerPadding }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle}>
                    <View style={styles.group}>
                      <View style={styles.item}>
                        <StyledText tkey="userProfile.email" style={styles.key}>Email</StyledText>
                        <TouchableOpacity onPress={() => Linking.openURL(`mailto:${fullUser.email}`)}>
                          <StyledText style={{ ...styles.value, color: props.services.app.manifest.themeColor }}>{fullUser.email}</StyledText>
                        </TouchableOpacity>
                      </View>

                      {(fullUser.social && fullUser.social.linkedin && fullUser.social.linkedin != '') ?
        <View style={styles.item}>
                          <StyledText style={styles.key}>LinkedIn</StyledText>
                          <TouchableOpacity onPress={() => Linking.openURL(fullUser.social.linkedin)}>
                            <StyledText style={{ ...styles.value, color: props.services.app.manifest.themeColor }}>{fullUser.social.linkedin}</StyledText>
                          </TouchableOpacity>
                        </View>
        : null}
                      {(fullUser.social && fullUser.social.instagram && fullUser.social.instagram != '') ?
        <View style={styles.item}>
                          <StyledText style={styles.key}>Instagram</StyledText>
                          <TouchableOpacity onPress={() => Linking.openURL(fullUser.social.instagram)}>
                            <StyledText style={{ ...styles.value, color: props.services.app.manifest.themeColor }}>{fullUser.social.instagram}</StyledText>
                          </TouchableOpacity>
                        </View>
        : null}
                      {(fullUser.social && fullUser.social.facebook && fullUser.social.facebook != '') ?
        <View style={styles.item}>
                          <StyledText style={styles.key}>Facebook</StyledText>
                          <TouchableOpacity onPress={() => Linking.openURL(fullUser.social.facebook)}>
                            <StyledText style={{ ...styles.value, color: props.services.app.manifest.themeColor }}>{fullUser.social.facebook}</StyledText>
                          </TouchableOpacity>
                        </View>
        : null}
                      {(fullUser.social && fullUser.social.other && fullUser.social.other != '') ?
        <View style={styles.item}>
                          <StyledText tkey="userProfile.othersocial" style={styles.key}>Other</StyledText>
                          <TouchableOpacity onPress={() => Linking.openURL(fullUser.social.other)}>
                            <StyledText style={{ ...styles.value, color: props.services.app.manifest.themeColor }}>{fullUser.social.other}</StyledText>
                          </TouchableOpacity>
                        </View>
        : null}


                    </View>

                    <View style={styles.group}>
                      <View style={[styles.item, styles.lastItem]}>
                        <StyledText tkey="userProfile.bio" style={styles.key}>Bio</StyledText>
                        <StyledText style={styles.valueMulti}>{fullUser.bio}</StyledText>
                      </View>
                    </View>
                  </ScrollView>

                </View>)}/>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
