import React from 'react';
// REACT NATIVE COMPONENTS
import { View } from "react-native";
import StyledText from '../../../../../../components/StyledText';
// OTHER UTILS
// STYLING
const styles = {
    container: {},
    header: {
        backgroundColor: '#ffffff',
        paddingVertical: 50,
        paddingHorizontal: 15
    },
    index: {
        color: '#CACACA',
        fontSize: 16,
        textAlign: 'center',
        fontWeight: '500'
    },
    title: {
        marginTop: 10,
        color: '#333333',
        fontSize: 25,
        lineHeight: 30,
        textAlign: 'center',
        fontWeight: '500'
    },
    points: {
        fontSize: 16,
        lineHeight: 18,
        textAlign: 'center',
        fontWeight: '500',
        marginTop: 20
    },
    options: {
        backgroundColor: '#EEEFF3',
        paddingBottom: 25,
    },
    option: {
        color: '#333333',
        fontSize: 17,
        lineHeight: 21,
        textAlign: 'center',
        fontWeight: '500',
        marginHorizontal: 15,
        marginTop: 15,
        borderRadius: 10,
        paddingHorizontal: 15,
        paddingVertical: 15,
        overflow: 'hidden',
        backgroundColor: '#FFFFFF',
        shadowColor: "#550000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 8
    },
    incorrect: {
        borderWidth: 1,
        borderColor: '#E97D6E',
        backgroundColor: '#E97D6E',
        color: '#FFFFFF'
    },
    doubleCorrect: {
        borderWidth: 2,
        borderColor: '#4ABE95',
        backgroundColor: '#4ABE95',
        color: '#FFFFFF'
    },
    correct: {
        borderWidth: 2,
        borderColor: '#4ABE95',
    }
};
export default props => {
    const { item, index, total } = props;
    const points = item.answers.reduce((a, b) => a + b.points, 0);
    return (<View style={styles.container}>
      <View style={styles.header}>
        <StyledText style={styles.index}>{index + 1}/{total}</StyledText>
        <StyledText style={styles.title}>{item.title}</StyledText>
        <StyledText style={{ ...styles.points, color: points > 0 ? '#4ABE95' : '#E97D6E' }} tkey="gamification.report.questionPoints" interpolations={{ points }}>{points} puntos</StyledText>
      </View>

      <View style={styles.options}>
        {item.options.map(opt => {
        const answers = item.answers.filter(a => a._id.toString() == opt._id.toString());
        const answer = answers.length > 0 ? answers[0] : null;
        return (<StyledText style={{ ...styles.option, ...(opt.correct ? (answer ? styles.doubleCorrect : styles.correct) : (answer ? styles.incorrect : {})) }}>{opt.title}</StyledText>);
    })}
      </View>


    </View>);
};
