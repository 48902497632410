import React, { useEffect } from "react";
import FeathersProvider from "./services/feathers/FeathersProvider";
import { connect } from "react-redux";
import { getLocales } from 'react-native-localize';
import AnalyticsProvider from './Analytics';
import NavigationWrap from './NavigationWrap';
const FeathersWrap = (props) => {
    useEffect(() => {
        if (props.services.app.manifest.langPattern) {
            const { langPattern, title, themeColor, slug, icon57, icon72, icon76, icon114, icon120, icon144, icon152, icon180, icon192, icon512 } = props.services.app.manifest;
            const lang = langPattern.default;
            if (title[lang]) {
                manifestJSON.name = title[lang];
                manifestJSON.short_name = title[lang];
            }
            ;
            // if (bg) {
            //   manifestJSON.background_color = bg;
            // };
            if (icon192 && icon512) {
                manifestJSON.icons[0].src = icon192;
                manifestJSON.icons[1].src = icon512;
            }
            manifestJSON.scope = `https://pwa.ubelong.io/`;
            manifestJSON.start_url = `https://pwa.ubelong.io/${slug}/`;
            const stringManifest = JSON.stringify(manifestJSON);
            const blob = new Blob([stringManifest], { type: 'application/json' });
            const manifestURL = URL.createObjectURL(blob);
            document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
            document.querySelector('#touch-icon').setAttribute('href', icon57);
            document.querySelector('#touch-icon-57').setAttribute('href', icon57);
            document.querySelector('#touch-icon-72').setAttribute('href', icon72);
            document.querySelector('#touch-icon-76').setAttribute('href', icon76);
            document.querySelector('#touch-icon-114').setAttribute('href', icon114);
            document.querySelector('#touch-icon-120').setAttribute('href', icon120);
            document.querySelector('#touch-icon-144').setAttribute('href', icon144);
            document.querySelector('#touch-icon-152').setAttribute('href', icon152);
            document.querySelector('#touch-icon-180').setAttribute('href', icon180);
        }
    }, [props.services.app.manifest]);
    const deviceLocale = () => {
        const deviceLocales = getLocales().map(dl => dl.languageCode);
        return deviceLocales[0];
    };
    return (<AnalyticsProvider slug={props.slug}>
      <FeathersProvider env={props.services.app.env} slug={props.services.app.slug || props.slug} deviceLocale={deviceLocale()} pushSettings={props.pushSettings} pushSettingsEventEmmiter={props.pushSettingsEventEmmiter}>
        <NavigationWrap slug={props.services.app.slug || props.slug}/>
      </FeathersProvider>
    </AnalyticsProvider>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(FeathersWrap);
