import React, { useState } from 'react';
// REACT NATIVE COMPONENTS
import { Animated, View, TouchableOpacity, Platform } from "react-native";
// OWN ROOT COMPONENTS
import StyledText from "../StyledText";
import BelongIcon from "../BelongIcon";
// WRAPPERS AND CONTEXT CREATORS
// REDUX IMPORTS AND ACTION CREATORS
// OTHER UTILS
import hextorgba from "../../utils/hextorgba";
// STYLING
const styles = {
    headerContainer: {
        flex: 1,
        flexDirection: "column",
    },
    headerUpper: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "visible",
    },
    headerLower: {
        height: 56,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    centralTitleWrap: {
        flex: 1,
        alignItems: 'center'
    },
    centralTitle: {
        fontSize: 16,
        fontWeight: "500",
        color: "#333333",
    },
    barButton: {
        //flex: 0,
        height: 30,
        borderRadius: 15,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    barIcon: {
        fontSize: 24,
        marginRight: 3,
    },
    barText: {
        marginHorizontal: 4
    },
    barIconSmall: {
        fontSize: 17,
    },
};
export default ({ disappearingOpacity, appearingOpacity, ...props }) => {
    const [maxSideWidth, setMaxSideWidth] = useState(0);
    const _onSideLayout = (event) => {
        if (Platform.OS !== "web")
            event.persist();
        const width = event.nativeEvent.layout.width;
        if (width > maxSideWidth)
            setMaxSideWidth(width);
    };
    return (<View style={{ ...styles.headerContainer }}>
      <Animated.View style={{ ...styles.headerUpper, opacity: disappearingOpacity }}>
        {props.central}
      </Animated.View>
      <View style={styles.headerLower}>
        {props.backAction ? (<TouchableOpacity onLayout={_onSideLayout} style={{
        ...styles.barButton,
        justifyContent: "flex-start",
        minWidth: maxSideWidth,
    }} onPress={props.backAction}>
            <StyledText>
              <BelongIcon name="md-cheveron-left" style={{
        ...styles.barIcon,
        color: props.negative ? '#FFFFFF' : props.themeColor,
    }}/>
            </StyledText>
          </TouchableOpacity>) : (<View onLayout={_onSideLayout} style={{
        ...styles.barButton,
        justifyContent: "flex-start",
        minWidth: maxSideWidth,
    }}/>)}
        {props.bottomTitleOverride ?
        props.bottomTitleOverride
        :
            <Animated.View style={{ ...styles.centralTitleWrap, opacity: appearingOpacity }}>
          <StyledText style={styles.centralTitle}>{props.title}</StyledText>
        </Animated.View>}

        {props.mainAction ? (<TouchableOpacity onLayout={_onSideLayout} onPress={props.mainAction} style={{
        ...styles.barButton,
        paddingHorizontal: 12,
        backgroundColor: hextorgba(props.negative ? '#ffffff' : props.themeColor, 0.1),
        justifyContent: "flex-end",
        minWidth: maxSideWidth
    }}>
            <StyledText style={{
        ...styles.barText,
        color: props.negative ? '#ffffff' : props.themeColor,
    }}>
              {props.mainActionTitle}
            </StyledText>
          </TouchableOpacity>) : (null
    // <View
    //   onLayout={_onSideLayout}
    //   style={{
    //     ...styles.barButton,
    //     justifyContent: "flex-end",
    //     minWidth: maxSideWidth,
    //   }}
    // />
    )}
      </View>
    </View>);
};
