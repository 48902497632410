import React, { useState } from 'react';
// REACT NATIVE COMPONENTS
import { StyleSheet, FlatList } from "react-native";
// OWN ROOT COMPONENTS
import ListItem from '../../../Static/components/ListItem';
// OTHER UTILS
import { useLocale } from '../../../../../i18n';
import fileOpenHandler from '../../../../../helpers/fileOpenHandler';
// STYLING
const styles = StyleSheet.create({});
export default props => {
    const [progress, setProgress] = useState({});
    const lolcaleContext = useLocale();
    const locale = lolcaleContext.workingLocale();
    const { documents } = props.module;
    const handleItemPress = item => {
        fileOpenHandler(item, locale, setProgress);
    };
    // STYLING
    const styles = {
        contentContainer: {
            marginHorizontal: 12,
        }
    };
    return (<React.Fragment>
      <FlatList {...props} style={{ flex: 1, backgroundColor: '#FFF' }} contentContainerStyle={{ ...props.contentContainerStyle, ...styles.contentContainer, paddingTop: props.contentContainerStyle.paddingTop + 20 }} showsVerticalScrollIndicator={false} data={documents} renderItem={({ key, item, index }) => (<ListItem key={key} item={{ ...item, contentType: 'attachment' }} themeColor={props.themeColor} onPress={() => handleItemPress(item)} progress={progress[item._id]}/>)} keyExtractor={(item) => item._id}/>
    </React.Fragment>);
};
