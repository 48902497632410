import React, { useState, useEffect, useContext } from 'react';
// REACT NATIVE COMPONENTS
import { View, FlatList } from "react-native";
// OWN ROOT COMPONENTS
import CollapsibleWrapper from '../../../components/CollapsibleWrapper';
import CollapsibleHeader from '../../../components/CollapsibleHeader';
import StyledText from '../../../components/StyledText';
import ListItem from "./components/ListItem";
import BelongSpinner from '../../../components/BelongSpinner';
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
import { useTranslation } from 'react-i18next';
// STYLING
const styles = {
    title: {
        fontSize: 30,
        fontWeight: "500",
        color: "#FFFFFF",
        marginTop: 5,
    },
};
const Component = props => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [fullModule, setFullModule] = useState(null);
    // FEATHERS CONTEXT LOAD
    const feathersContext = useContext(FeathersContext);
    const { t, i18n } = useTranslation();
    const initialLoad = () => {
        setLoading(true);
        setFullModule(null);
        feathersContext
            .get("modules", props.route.params.module._id)
            .then((r) => {
            setFullModule(r);
            setLoading(false);
            setError(false);
            // setTimeout(() => {
            //   setLoading(false);
            //   setError(true);
            // }, 3000)
        })
            .catch((e) => {
            console.log(e);
            setLoading(false);
            setError(true);
        });
    };
    useEffect(() => {
        initialLoad();
        feathersContext.client.service('modules').on("updated", initialLoad);
        feathersContext.client.service('modules').on("patched", initialLoad);
        return () => {
            feathersContext.client.service('modules').off("updated", initialLoad);
            feathersContext.client.service('modules').off("patched", initialLoad);
        };
    }, []);
    return (fullModule ?
        <CollapsibleWrapper headerComponent={(disappearingOpacity, appearingOpacity) => (<CollapsibleHeader negative central={<StyledText variant="h1" style={styles.title}>
                {props.route.params.module.title}
              </StyledText>} backAction={() => props.navigation.goBack()} 
        // mainAction={() => props.actions.notifications.readAll()}
        // mainActionTitle="Leer todas"
        disappearingOpacity={disappearingOpacity} appearingOpacity={appearingOpacity} themeColor={props.services.app.manifest.themeColor}/>)} backgroundColor="#EEEFF3" bodyBackgroundColor="#FFFFFF" headerComponentStyle={{
            backgroundColor: props.services.app.manifest.themeColor,
            paddingHorizontal: 15,
        }} bodyComponentStyle={{
            flex: 1,
        }} scrollViewOverride={(onScroll, scrollEventThrottle, contentContainerPadding) => (loading || error ?
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingTop: contentContainerPadding }}>
            <BelongSpinner error={error}/>
          </View>
            :
                <View style={{
                    ...styles.list,
                    backgroundColor: '#ffffff',
                    flex: 1
                }}>
            <FlatList contentContainerStyle={{ paddingTop: contentContainerPadding }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle} keyExtractor={(item, index) => item._id} style={{}} data={fullModule.votingBlocks.filter(vb => vb.active)} renderItem={({ key, item, index }) => (<ListItem key={key} item={item} onPress={() => {
                    item.voted ||
                        props.navigation.navigate("VotingBlock", {
                            moduleId: fullModule._id,
                            block: item,
                            initialLoad: initialLoad,
                        });
                }}/>)}/>
          </View>)}/>
        : null);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
