import React, { useState, useEffect } from 'react';
// REACT NATIVE COMPONENTS
import { View } from "react-native";
// OWN ROOT COMPONENTS
import Answer from './components/Answer';
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
// OTHER UTILS
// STYLING
const styles = {
    answersWrap: {
        paddingHorizontal: 15,
        flex: 1,
        alignSelf: 'stretch'
    }
};
const Component = props => {
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    useEffect(() => {
        setSelectedAnswer(null);
    }, [props.question._id]);
    return (<View style={styles.answersWrap}>
      {props.question.options.map((o, i) => (<Answer option={o} disabled={props.answered} right={o.correct && props.answered} wrong={i == selectedAnswer && !o.correct && props.answered} selected={i == selectedAnswer} themeColor={props.services.app.manifest.themeColor} onSelectAnswer={() => {
        setSelectedAnswer(i);
        props.setValid(true);
        props.setAnswers([o]);
    }}/>))}
    </View>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
