import React, { useState } from "react";
// REACT NATIVE COMPONENTS
import { View, TouchableOpacity, Platform } from "react-native";
import { SafeAreaInsetsContext } from "react-native-safe-area-context";
// OWN ROOT COMPONENTS
import BelongIcon from "../BelongIcon";
import StyledText from "../StyledText";
// OTHER UTILS
import hextorgba from "../../utils/hextorgba";
// STYLING
const styles = {
    container: {
        backgroundColor: "#EEEFF3",
    },
    headerLower: {
        marginHorizontal: 15,
        height: 80,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    centralTitleWrap: {
        flexGrow: 1,
        alignItems: "center",
    },
    centralTitle: {
        fontSize: 16,
        fontWeight: "500",
        color: "#333333",
    },
    barButton: {
        flexGrow: 0,
        height: 30,
        borderRadius: 15,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    barIcon: {
        fontSize: 24,
        marginRight: 3,
    },
    barText: {
        marginHorizontal: 4,
    },
    barIconSmall: {
        fontSize: 17,
    },
    falseRounded: {
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
        height: 23,
        backgroundColor: "#fff",
    },
};
export default (props) => {
    const [maxSideWidth, setMaxSideWidth] = useState(0);
    const _onSideLayout = (event) => {
        if (Platform.OS !== "web")
            event.persist();
        const width = event.nativeEvent.layout.width;
        if (width > maxSideWidth)
            setMaxSideWidth(width);
    };
    return (<SafeAreaInsetsContext.Consumer>
      {(insets) => (<View style={props.white ? { backgroundColor: '#ffffff' } : (props.negative ? { backgroundColor: props.themeColor } : {})}>
          <View style={{
        ...styles.headerLower,
        marginTop: insets.top,
    }}>
            {props.backAction ? (<TouchableOpacity onLayout={_onSideLayout} style={{
        ...styles.barButton,
        justifyContent: "flex-start",
        minWidth: maxSideWidth,
    }} onPress={props.backAction}>
                  <BelongIcon name={props.hasClose ? "md-x" : "md-cheveron-left"} style={{
        ...styles.barIcon,
        color: props.negative ? '#ffffff' : props.themeColor,
    }}/>
              </TouchableOpacity>) : (<View onLayout={_onSideLayout} style={{
        ...styles.barButton,
        justifyContent: "flex-start",
        minWidth: maxSideWidth,
    }}/>)}
            <View style={styles.centralTitleWrap}>
              <StyledText numberOfLines={props.numberOfLines} style={{ ...styles.centralTitle, ...(props.negative ? { color: '#ffffff' } : {}) }}>{props.title}</StyledText>
            </View>
            {props.mainAction ? (<TouchableOpacity onLayout={_onSideLayout} onPress={props.mainAction} style={{
        ...styles.barButton,
        paddingHorizontal: 12,
        backgroundColor: hextorgba(props.themeColor, 0.1),
        justifyContent: "flex-end",
        minWidth: maxSideWidth,
    }}>
                <StyledText style={{
        ...styles.barText,
        color: props.themeColor,
    }}>
                  {props.mainActionTitle}
                </StyledText>
              </TouchableOpacity>) : (<View onLayout={_onSideLayout} style={{
        ...styles.barButton,
        justifyContent: "flex-end",
        minWidth: maxSideWidth,
    }}/>)}
          </View>
          {!props.noRound &&
        <View style={styles.falseRounded}></View>}
        </View>)}
    </SafeAreaInsetsContext.Consumer>);
};
