import React, { useState, useEffect } from 'react';
import { Image, Text, Platform } from 'react-native';
import { encode as btoa } from "base-64";
export const scaledImageSource = (key, width = 200, height = 100, fit = "inside") => {
    const request = {
        bucket: "ubelong-dev2",
        key: key,
        edits: {
            // smartCrop: faceCrop ? {
            //   faceIndex: 0,   // zero-based index of detected faces
            //   padding: 130    // padding expressed in pixels, applied to all sides
            // } : false,
            normalize: false,
            // small tip, if you have a mobile app you can use the following 2 lines of code to optimise your images further
            webp: true,
            jpeg: true,
            rotate: null,
            resize: {
                width: width,
                height: height,
                fit: fit,
            },
        },
    };
    const strRequest = JSON.stringify(request);
    const encRequest = btoa(strRequest);
    return `https://d14kpv59isoic6.cloudfront.net/${encRequest}`;
};
export default props => {
    const [height, setHeight] = useState(0);
    const [imageUrl, setImageUrl] = useState(null);
    useEffect(() => {
        if (props.image) {
            setImageUrl(scaledImageSource(props.image, props.width, props.height, props.fit));
        }
    }, [props.image, props.width, props.height, props.fit]);
    const _onLayout = (event) => {
        if (Platform.OS !== "web")
            event.persist();
        if (imageUrl) {
            Image.getSize(imageUrl, (width, height) => {
                const renderedWidth = event.nativeEvent.layout.width;
                setHeight(height * (renderedWidth / width));
            });
        }
    };
    return (imageUrl ?
        <Image onLayout={_onLayout} source={imageUrl ? { uri: imageUrl } : null} style={{ ...props.style, ...(height > 0 ? { height: height } : {}) }}/>
        : <Text>No iamge</Text>);
};
