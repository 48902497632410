import React from 'react';
import { useLocale } from '../../../../../i18n';
export default props => {
    const lolcaleContext = useLocale();
    const locale = lolcaleContext.workingLocale();
    return (<select style={{ border: 'none', background: 'none', webkitAppearance: "none" }} onChange={(e) => props.setTopic(e.target.value)}>
      <option value="">{props.fullModule.topicsPlaceholder ? props.fullModule.topicsPlaceholder[locale] : ''}</option>
      {(props.fullModule.topics || []).map(t => (<option selected={props.topic == t._id} value={t._id}>{t.title[locale]}</option>))}
    </select>);
};
