import React from 'react';
// REACT NATIVE COMPONENTS
import { View, FlatList, TouchableOpacity } from "react-native";
// OWN ROOT COMPONENTS
import StyledText from '../../../../../components/StyledText';
// WRAPPERS AND CONTEXT CREATORS
// REDUX IMPORTS AND ACTION CREATORS
// OTHER UTILS
import { moment } from '../../../../../i18n';
import { withAnchorPoint } from 'react-native-anchor-point';
import TheGradient from './components/Gradient';
// STYLING
const styles = {
    dayListContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 15
    },
    dayList: {
        maxWidth: '100%'
    },
    dayListContent: {
        marginHorizontal: 20
    },
    day: {
        marginHorizontal: 20,
        alignItems: 'center'
    },
    dayWeekText: {
        fontSize: 16,
        color: '#4F4F4F',
        marginBottom: 10
    },
    monthText: {
        fontSize: 16,
        color: '#4F4F4F',
        marginTop: 10
    },
    outerCircle: {
        padding: 6,
        borderTopLeftRadius: 36,
        borderTopRightRadius: 36,
        borderBottomLeftRadius: 36,
        borderBottomRightRadius: 36,
        transform: [{ rotate: "140deg" }]
    },
    circle: {
        width: 60,
        height: 60,
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        borderBottomLeftRadius: 30,
        borderBottomRightRadius: 30,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 2,
        borderColor: 'transparent',
        position: 'relative',
        transform: [{ rotate: "-140deg" }]
    },
    // outerCircle: {
    //   position: 'absolute',
    //   top: -8,
    //   left: -8,
    //   width: 72,
    //   height: 36,
    //   borderTopLeftRadius: 78,  /* 100px of height + 10px of border */
    //   borderTopRightRadius: 78, /* 100px of height + 10px of border */
    //   borderWidth: 6,
    //   borderColor: 'transparent',
    //   borderBottom: 0,
    // },
    dayText: {
        fontSize: 28,
        color: '#4F4F4F',
        fontWeight: '500'
    },
};
const Day = props => {
    const transform = () => {
        let transform = {
            transform: [{ rotate: "140deg" }],
        };
        return withAnchorPoint(transform, { x: 0.5, y: 1 }, { width: 72, height: 36 });
    };
    return (<TouchableOpacity style={styles.day} onPress={props.onPress}>
      <StyledText style={{ ...styles.dayWeekText, ...(props.isSelected ? { color: props.themeColor } : {}) }}>{moment(props.item, 'YYYYMMDD').format('dddd').toUpperCase().replace('.', '')}</StyledText>
      <TheGradient isSelected={props.isSelected} themeColor={props.themeColor} style={{ ...styles.outerCircle }}>
        <View style={{ ...styles.circle, ...(props.isSelected ? { borderColor: props.themeColor, backgroundColor: '#FFFFFF' } : {}) }}>
          
          <StyledText style={{ ...styles.dayText, ...(props.isSelected ? { color: props.themeColor } : {}) }}>{moment(props.item, 'YYYYMMDD').format('D')}</StyledText>
        </View>
      </TheGradient>
      <StyledText style={{ ...styles.monthText, ...(props.isSelected ? { color: props.themeColor } : {}) }}>{moment(props.item, 'YYYYMMDD').format('MMMM').toUpperCase().replace('.', '')}</StyledText>
    </TouchableOpacity>);
};
const Component = props => {
    const { days } = props;
    return (<View style={styles.dayListContainer}>
      <FlatList showsHorizontalScrollIndicator={false} style={styles.dayList} contentContainerStyle={styles.dayListContent} horizontal data={days} 
    //data={[22,23,24,25,26,27,28,29,30,31,1,2,3,4]}
    renderItem={({ item, index, separators }) => (<Day themeColor={props.themeColor} item={item} isSelected={props.selectedDay == item} onPress={() => props.onDaySelect(item)}/>)}/>
    </View>);
};
export default Component;
