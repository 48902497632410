import React, { useEffect, useContext, createContext } from 'react';
const AnalyticsContext = createContext(null);
export { AnalyticsContext };
export const useAnalytics = () => useContext(AnalyticsContext);
export default ({ slug, children }) => {
    useEffect(async () => {
    }, [slug]);
    return (<AnalyticsContext.Provider value={{ setMixpanelUser: () => { }, trackMixpanelEvent: () => { } }}>
      {children}
    </AnalyticsContext.Provider>);
};
// export default class MixpanelManager {
//     static sharedInstance = MixpanelManager.sharedInstance || new MixpanelManager();
//     constructor() {
//         this.configMixpanel();
//     }
//     configMixpanel = async () => {
//         this.mixpanel = await Mixpanel.init("1c2ad0dca35bbcdf21f0740aabccf4e0", { "api_host": "https://api-eu.mixpanel.com" }, "");
//     }
// }
