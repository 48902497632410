import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/es/storage";
import { reducer as appReducer } from "./app/reducer";
import { reducer as sessionReducer } from "./session/reducer";
const config = {
    key: "services",
    storage
};
export const reducer = persistCombineReducers(config, {
    app: appReducer,
    session: sessionReducer,
});
