import React from "react";
import { TouchableOpacity } from "react-native";
import StyledText from "../StyledText";
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../i18n';
const styles = {
    button: {
        height: 50,
        borderRadius: 25,
        backgroundColor: "#000",
        justifyContent: "center",
        paddingHorizontal: 36,
        shadowColor: "#550000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 8
    },
    text: {
        fontSize: 20,
        color: "#ffffff",
        fontWeight: "500",
    },
};
export default (props) => {
    const { t, i18n } = useTranslation();
    const lolcaleContext = useLocale();
    const locale = lolcaleContext.workingLocale();
    return (<TouchableOpacity {...props} style={{
        ...styles.button,
        ...(props.white ? { backgroundColor: "#FFF" } : {}),
        ...(props.disabled ? { opacity: 0.3 } : {}),
        ...props.style,
    }}>
      <StyledText style={{
        ...styles.text,
        ...(props.white ? { color: props.color } : { color: "#fff" }),
    }}>
        {props.tkey ?
        t(props.tkey)
        :
            ((typeof props.children === 'object' && props.children !== null && !props.children.type) ? props.children[locale] : props.children)}
      </StyledText>
    </TouchableOpacity>);
};
