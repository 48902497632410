import React from "react";
// REACT NATIVE COMPONENTS
import { TouchableOpacity, View } from "react-native";
import PlaceholderAvatar from "../../../../components/PlaceholderAvatar";
// OWN ROOT COMPONENTS
import ScaledImage from "../../../../components/ScaledImage";
import StyledText from "../../../../components/StyledText";
//import Icon from 'react-native-ico-flags';
// STYLING
const styles = {
    container: {
        flexDirection: "row",
        alignItems: "center",
        borderTopWidth: 1,
        borderTopColor: "#E9E9E9",
        marginHorizontal: 15,
        paddingHorizontal: 10,
        paddingVertical: 10
    },
    pic: {
        width: 56,
        height: 56,
        borderRadius: 28,
    },
    rightArea: {
        marginLeft: 15,
        flex: 1,
        flexDirection: "column",
    },
    firstRow: {
        flexDirection: 'row'
    },
    title: {
        fontSize: 17,
        fontWeight: "500",
        color: "#333333",
    },
    subtitle: {
        fontSize: 13,
        color: "#8D8E8F",
        marginTop: 3
    },
};
export default ({ themeColor, key, item, onPress = () => { } }) => {
    return (<TouchableOpacity key={key} onPress={onPress} style={styles.container}>
      {item.picture ? (<ScaledImage image={item.picture} width={165} height={165} fit="cover" style={styles.pic}/>) : (<PlaceholderAvatar user={item} themeColor={themeColor} style={styles.pic}/>)}
      <View style={styles.rightArea}>
        <View style={styles.firstRow}>
          <StyledText style={styles.title}>
            {item.firstName} {item.lastName}
          </StyledText>
          
        </View>
        <StyledText style={styles.subtitle}>{item.subtitle}</StyledText>
      </View>
    </TouchableOpacity>);
};
