import React, { useState, useEffect, useContext } from 'react';
// REACT NATIVE COMPONENTS
import { View, ScrollView, TouchableOpacity } from "react-native";
// OWN ROOT COMPONENTS
import CollapsibleWrapper from "../../../../components/CollapsibleWrapper";
import CollapsibleHeader from "../../../../components/CollapsibleHeader";
import StyledText from "../../../../components/StyledText";
import BelongIcon from "../../../../components/BelongIcon";
import BigHeader from "../../../../components/BelongHeader/big";
import Options from './components/Options';
// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../services/feathers/FeathersProvider";
// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import BelongButton from '../../../../components/BelongButton';
// OTHER UTILS
import MediaQuery, { useMediaQuery } from 'react-responsive';
// STYLING
const styles = {
    headerDesktop: {
        height: 100,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    desktopBackOuter: {
        flex: 1
    },
    desktopBackWrap: {
        backgroundColor: 'rgba(55,66,96,0.05)',
        width: 36,
        height: 36,
        borderRadius: 18,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    barButton: {
        height: 30,
        borderRadius: 15,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    contentContainerBig: {
        marginHorizontal: 'auto',
        width: '100%',
        maxWidth: 675,
        alignSelf: 'center'
    },
    index: {
        fontSize: 16,
        fontWeight: "500",
        color: "#333333",
        marginTop: 5,
        textAlign: 'center'
    },
    title: {
        fontSize: 30,
        //lineHeight: 30,
        fontWeight: "500",
        color: "#333333",
        marginTop: 5,
        textAlign: 'center',
        maxHeight: '80%',
    },
    legend: {
        fontSize: 13,
        color: "#8D8E8F",
        marginTop: 15,
        textAlign: 'center'
    },
    list: {
        flex: 1,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: "#EEEFF3",
        paddingBottom: 30,
        alignItems: 'center'
    }
};
const Component = props => {
    const [game, setGame] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [valid, setValid] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [answered, setAnswered] = useState(false);
    const [gameplayId, setGameplayId] = useState(null);
    // Load Feathers context
    const feathersContext = useContext(FeathersContext);
    const isBig = useMediaQuery({ query: '(min-width: 800px)' });
    useEffect(() => {
        setGame(props.route.params.game);
        feathersContext.create("gameplays", {
            gameId: props.route.params.game._id,
            moduleId: props.route.params.game.moduleId,
            startedAt: new Date(),
            endsMax: new Date(),
            pendingQuestions: props.route.params.game.questions,
            completedQuestions: []
        }).then(r => {
            setGameplayId(r._id);
        });
    }, [props.route.params.game]);
    const next = () => {
        setAnswered(true);
        setTimeout(() => {
            const question = { ...game.questions[currentQuestion] };
            question.answers = answers;
            feathersContext.patch("gameplays", gameplayId, {
                $push: {
                    completedQuestions: question
                }
            }).then(r => {
                if (game.questions.length > currentQuestion + 1) {
                    //alert(JSON.stringify(answers));
                    setCurrentQuestion(currentQuestion + 1);
                    setValid(false);
                    setAnswers([]);
                    setAnswered(false);
                }
                else {
                    //alert("Fin");
                    setCurrentQuestion(0);
                    setValid(false);
                    setAnswers([]);
                    setAnswered(false);
                    props.navigation.navigate("GamificationCompletedBlock", {
                        module: props.route.params.module,
                        game: props.route.params.game,
                        initialLoad: props.route.params.initialLoad,
                    });
                    props.route.params.initialLoad();
                }
            });
        }, 1000);
    };
    return (game &&
        <React.Fragment>
      <MediaQuery minWidth={800}>
        <BigHeader navigation={props.navigation}>
          {props.services.app.manifest.title}
        </BigHeader>
      </MediaQuery>
        <CollapsibleWrapper headerComponent={(disappearingOpacity, appearingOpacity) => (<React.Fragment>
              <MediaQuery minWidth={800}>
                <View style={styles.headerDesktop}>
                  <View style={styles.desktopBackOuter}>
                    <TouchableOpacity style={[styles.barButton, isBig ? styles.desktopBackWrap : {}]} onPress={() => {
            props.navigation.goBack();
            if (props.route.params.onGoBack)
                props.route.params.onGoBack();
        }}>
                      <StyledText>
                        <BelongIcon name="md-cheveron-left" style={{
            ...styles.barIcon,
            color: '#ffffff',
        }}/>
                      </StyledText>
                    </TouchableOpacity>
                  </View>
                  <StyledText variant="h1" style={{ ...styles.title, color: '#FFFFFF' }}>
                    {props.route.params.module.title}
                  </StyledText>
                  <View style={{ flex: 1 }}>
                  </View>
                </View>
              </MediaQuery>
              <MediaQuery maxWidth={799}>
                <CollapsibleHeader central={<View>
                      <StyledText style={{ ...styles.index, color: props.services.app.manifest.themeColor }}>{currentQuestion + 1}/{game.questions.length}</StyledText>
                      <StyledText variant="h1" style={styles.title} numberOfLines={4} adjustsFontSizeToFit minimumFontScale={0.2}>
                        {game.questions[currentQuestion].title}
                      </StyledText>
                      <StyledText tkey="gamification.chooseOption" style={styles.legend}>Escoge una opción</StyledText>
                    </View>} backAction={() => props.navigation.goBack()} 
        // mainAction={() => {}}
        // mainActionTitle="Buscar"
        disappearingOpacity={disappearingOpacity} appearingOpacity={appearingOpacity} themeColor={props.services.app.manifest.themeColor} bottomTitleOverride={<StyledText></StyledText>}/>
              </MediaQuery>
            </React.Fragment>)} backgroundColor="#FFFFFF" bodyBackgroundColor="#FFFFFF" headerComponentStyle={{
            backgroundColor: isBig ? props.services.app.manifest.themeColor : "#FFFFFF",
            paddingHorizontal: 15,
        }} bodyComponentStyle={{
            flex: 1,
        }} scrollViewOverride={(onScroll, scrollEventThrottle, contentContainerPadding) => (<View style={{
            ...styles.list,
        }}>
              <MediaQuery minWidth={800}>
                <View style={{ ...styles.contentContainerBig, paddingTop: contentContainerPadding + 70 }}>
                  <StyledText style={{ ...styles.index, color: props.services.app.manifest.themeColor }}>{currentQuestion + 1}/{game.questions.length}</StyledText>
                  <StyledText variant="h1" style={styles.title} numberOfLines={4} adjustsFontSizeToFit minimumFontScale={0.2}>
                    {game.questions[currentQuestion].title}
                  </StyledText>
                  <StyledText tkey="gamification.chooseOption" style={styles.legend}>Escoge una opción</StyledText>
                </View>
              </MediaQuery>
              <ScrollView style={{ alignSelf: 'stretch', marginBottom: 15 }} contentContainerStyle={{ ...(isBig ? styles.contentContainerBig : { paddingTop: contentContainerPadding }) }} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle}>
                <Options question={game.questions[currentQuestion]} setValid={v => setValid(v)} setAnswers={setAnswers} answered={answered}/>
              </ScrollView>
              <BelongButton onPress={next} disabled={!valid || answered} style={{ backgroundColor: props.services.app.manifest.themeColor }} tkey="gamification.next">Siguiente</BelongButton>
            </View>)}/>
    </React.Fragment>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(Component);
