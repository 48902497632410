import React, { useState, useEffect } from 'react';
// REACT NATIVE COMPONENTS
import { View, TouchableOpacity } from "react-native";
import StyledText from '../../../../../components/StyledText';
// OTHER UTILS
// STYLING
const styles = {
    container: {
        backgroundColor: 'rgba(0,0,0,0.4)',
        borderRadius: 90,
        padding: 4,
        flexDirection: 'row'
    },
    tab: {
        borderRadius: 90,
        paddingVertical: 7,
        paddingHorizontal: 14
    },
    tabLabel: {
        fontWeight: "500",
        color: '#FFFFFF'
    }
};
export default props => {
    const [activeIndex, setActiveIndex] = useState(props.baseIndex || 0);
    useEffect(() => {
        if (props.initialIndex != activeIndex) {
            setActiveIndex(props.initialIndex);
            props.onIndexChange(props.initialIndex);
        }
    }, [props.initialIndex]);
    return (<View style={styles.container}>
      {props.tabs.map((t, i) => (<TouchableOpacity onPress={() => {
        setActiveIndex(i);
        props.onIndexChange(i);
    }} style={{ ...styles.tab, ...(i === activeIndex ? { backgroundColor: props.themeColor } : {}) }}>
          <StyledText style={styles.tabLabel}>{t.title}</StyledText>
        </TouchableOpacity>))}

    </View>);
};
