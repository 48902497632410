import React from "react";
import { Text } from "react-native";
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../i18n';
const styles = {
    text: {
        fontFamily: "Matter",
    },
    h1: {
        fontWeight: "500",
        fontSize: 25,
    },
    h2a: {
        fontSize: 16,
        opacity: .5
    }
};
export default (props) => {
    const { t, i18n } = useTranslation();
    const lolcaleContext = useLocale();
    const locale = lolcaleContext.workingLocale();
    let variantStyle = {};
    if (props.variant) {
        variantStyle = styles[props.variant];
    }
    return (<Text {...props} style={{
        ...styles.text,
        ...variantStyle,
        ...(props.white ? { color: "#FFF" } : {}),
        ...(props.centered ? { textAlign: "center" } : {}),
        ...props.style,
    }}>
      {props.tkey ?
        t(props.tkey, props.interpolations)
        :
            ((typeof props.children === 'object' && !Array.isArray(props.children) && props.children !== null && !props.children.type) ? props.children[locale] : props.children)}
    </Text>);
};
