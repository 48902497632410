import React, { useContext } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { FeathersContext, } from "../../services/feathers/FeathersProvider";
import { useForm } from "react-hook-form";
import validation from "./validation.tsx";
import { View, ScrollView, KeyboardAvoidingView, Platform, TouchableWithoutFeedback, Keyboard, Linking } from "react-native";
import StyledText from "../../components/StyledText";
import { Form, FormGroup, Input } from "../../components/Form/index.tsx";
import BelongButton from "../../components/BelongButton";
import { useAlert } from "../../components/BelongAlert";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../i18n';
import { TouchableOpacity } from "react-native-gesture-handler";
import { useMediaQuery } from 'react-responsive';
const styles = {
    container: {
        backgroundColor: "#8681FF",
        flex: 1,
    },
    scrollView: {
        paddingHorizontal: 15,
        flex: 1
    },
    scrollContent: {
        flexGrow: 1,
        paddingVertical: 25,
        width: '100%',
        maxWidth: 345,
        alignSelf: 'center'
    },
    inner: {
        flex: 1,
        alignItems: "stretch",
        justifyContent: "center"
    },
    headerArea: {
        marginBottom: 25,
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: 'auto',
        justifyContent: 'center',
    },
    headerBig: {
        marginBottom: 30,
        justifyContent: 'flex-end'
    },
    subtitle: {
        marginTop: 10,
    },
    forgotText: {
        color: '#ffffff'
    },
    footerArea: {
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: 'auto',
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    footerBig: {
        marginTop: 30,
        justifyContent: 'flex-start'
    }
};
const SignIn = (props) => {
    const { control, handleSubmit, errors, reset, setValue, clearErrors, formState, getValues } = useForm();
    const feathersContext = useContext(FeathersContext);
    const { t, i18n } = useTranslation();
    const localeContext = useLocale();
    const locale = localeContext.workingLocale();
    const Alert = useAlert();
    const isBig = useMediaQuery({ query: '(min-width: 800px)' });
    const onSubmit = (data) => {
        // if (data.email == "angel@padcelona.com" && data.password == "123456") {
        //   props.navigation.goBack();
        // } else {
        //   Alert.alert("data", JSON.stringify(data));
        // }
        const password = getValues("password");
        Keyboard.dismiss();
        if (password && password != '') {
            feathersContext
                .authenticate(props.services.app.slug, data.email, data.password)
                .then(() => {
                cleanupAndNavigate();
            })
                .catch((e) => {
                //reset();
                setValue('password', null, { shouldDirty: false, shouldValidate: false });
                clearErrors();
                // Show login page (potentially with `e.message`)
                console.error("Authentication error", e);
                Alert.alert(t('signIn.wrongTitle'), t('signIn.wrongBody'));
            });
        }
    };
    const onRecovery = () => {
        Keyboard.dismiss();
        const email = getValues("email");
        if (email && email != '') {
            feathersContext.client.service("authManagement").create({
                action: 'sendResetPwd',
                value: {
                    appId: props.services.app.slug,
                    email: email.toString()
                },
                meta: {
                    locale,
                    resetOrigin: 'app'
                }
            }).then(r => {
                Alert.alert(t('signIn.forgotEmailSentTitle'), t('signIn.forgotEmailSent'));
            }).catch(e => {
                Alert.alert(t('signIn.forgotEmailSentTitle'), t('signIn.forgotEmailSent'));
            });
        }
        else {
            Alert.alert(t('signIn.forgotEmailRequiredTitle'), t('signIn.forgotEmailRequired'));
        }
    };
    //feathersContext.client.logout();
    const cleanupAndNavigate = () => {
        reset();
        props.navigation.navigate("StackNavigator");
    };
    return (<SafeAreaView style={{
        ...styles.container,
        backgroundColor: props.services.app.manifest.themeColor,
        flex: 1
    }}>
      <KeyboardAvoidingView behavior={Platform.OS == "ios" ? "padding" : "height"} style={styles.inner}>
        <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollContent}>

          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <React.Fragment>
              <View style={[styles.headerArea, isBig ? styles.headerBig : {}]}>
                <StyledText tkey="signIn.title" variant="h1" white centered>
                  Acceder
                </StyledText>
                <StyledText variant="h2a" white centered style={styles.subtitle}>
                  {props.services.app.manifest.title}
                </StyledText>
              </View>
              <Form {...{ control, validation, errors }}>
                <FormGroup elementType="FormGroup">
                  <Input name="email" label={t('signIn.email')} keyboardType="email-address" autoCompleteType="email" autoCapitalize="none"/>
                  <Input name="password" label={t('signIn.password')} secureTextEntry last/>
                </FormGroup>
              </Form>
              <View style={[styles.footerArea, isBig ? styles.footerBig : {}]}>
                <BelongButton color={props.services.app.manifest.themeColor} white disabled={false} onPress={handleSubmit(onSubmit)} tkey="signIn.button" style={{ marginBottom: 10 }}>
                  Acceder
                </BelongButton>
                {props.services.app.manifest.accessOptions.registerable && (<BelongButton color={props.services.app.manifest.themeColor} white onPress={() => {
        if (props.services.app.manifest.accessOptions.registerOverride) {
            Linking.openURL(props.services.app.manifest.accessOptions.registerURL[locale]);
        }
        else {
            props.navigation.navigate("SignUp");
        }
    }} tkey="signIn.signUp" style={{ marginBottom: 10 }}>
                    Registro
                  </BelongButton>)}
                <TouchableOpacity onPress={onRecovery}>
                  <StyledText tkey="signIn.forgot" style={styles.forgotText}>¿Has olvidado tu contraseña?</StyledText>
                </TouchableOpacity>
              </View>

            </React.Fragment>
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>);
};
export default connect((state) => ({
    services: {
        app: state.services.app,
    },
}), null)(SignIn);
