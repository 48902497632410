import React from "react";
// REACT NATIVE COMPONENTS
import { TouchableOpacity, View } from "react-native";
// OWN ROOT COMPONENTS
import StyledText from "../../../../components/StyledText";
// OTHER UTILS
import Moment from "react-moment";
import "moment-timezone";
import workingLocale from "../../../../services/locale";
const locale = workingLocale();
// STYLING
const styles = {
    container: {
        flexDirection: "row",
        alignItems: "flex-start",
        borderTopWidth: 1,
        borderTopColor: "#E9E9E9",
        marginHorizontal: 15,
        paddingHorizontal: 10,
        paddingVertical: 10
    },
    leftArea: {
        flex: 1,
        flexDirection: "column",
        marginRight: 15
    },
    titleRow: {
        flexDirection: 'row'
    },
    title: {
        fontSize: 17,
        lineHeight: 21,
        fontWeight: "500",
        color: "#333333",
    },
    unread: {
        marginLeft: 4,
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: '#000',
        alignSelf: 'center'
    },
    subtitle: {
        fontSize: 13,
        lineHeight: 20,
        color: "#8C8C8C",
        marginTop: 3
    },
    date: {
        fontSize: 13,
        color: '#D5D7D9'
    }
};
export default ({ themeColor, key, item, unread, onPress = () => { } }) => {
    return (<TouchableOpacity key={key} onPress={onPress} style={styles.container}>
      <View style={styles.leftArea}>
        <View style={styles.titleRow}>
          <StyledText style={styles.title}>
            {item.title}
          </StyledText>
          {unread && <View style={{ ...styles.unread, backgroundColor: themeColor }}/>}
        </View>
        <StyledText style={styles.subtitle}>{item.body}</StyledText>
      </View>
      <Moment locale={locale} fromNow ago element={StyledText} style={styles.date}>
        {item.sentAt}
      </Moment>
    </TouchableOpacity>);
};
