import React from 'react';
import { Animated, View, TouchableOpacity } from "react-native";
import { SafeAreaConsumer } from "react-native-safe-area-context";
import BelongIcon from "../BelongIcon";
import ScaledImage from "../ScaledImage";
import StyledText from '../StyledText';
export default class BelongMenu extends React.PureComponent {
    constructor(props) {
        super(props);
        this.animateMenu = () => {
            Animated.timing(this.state.opacityAnim, {
                toValue: this.props.hidden ? 0 : 1,
                duration: 300,
                useNativeDriver: true
            }).start();
        };
        this.state = {
            hidden: props.hidden,
            opacityAnim: props.hidden
                ? new Animated.Value(0)
                : new Animated.Value(1)
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.hidden !== this.props.hidden) {
            this.animateMenu();
        }
    }
    render() {
        const { opacityAnim } = this.state;
        return (<SafeAreaConsumer>
        {(insets) => (<Animated.View style={{
            ...styles.container,
            bottom: Math.max(insets.bottom - 20, 0) + 20,
            opacity: opacityAnim,
        }}>
          <TouchableOpacity style={styles.item} onPress={() => this.props.navigation.navigate("InboxStack", {
            screen: "Notifications",
        })}>
              <BelongIcon name="sm-bell" style={styles.itemIcon}></BelongIcon>
              {this.props.notifications.unread > 0 ?
            <View style={styles.badge}>
                  <StyledText style={styles.badgeText}>{this.props.notifications.unread}</StyledText>
                </View>
            : null}
          </TouchableOpacity>
            
            {this.props.app.feathersUser._id && (<TouchableOpacity style={styles.item} onPress={() => this.props.navigation.navigate("InboxStack", {
            screen: "Profile",
        })}>
                {this.props.app.feathersUser.picture ? (<ScaledImage width={240} height={240} fit="cover" image={this.props.app.feathersUser.picture} style={{
            width: 30,
            height: 30,
            borderRadius: 15,
        }}/>) : (<View style={{
            width: 30,
            height: 30,
            borderRadius: 15,
            backgroundColor: "#555",
        }}/>)}
              </TouchableOpacity>)}

            <TouchableOpacity style={styles.item}>
              <BelongIcon name="sm-menu" style={styles.itemIcon} onPress={() => this.props.navigation.navigate("InboxStack")}></BelongIcon>
              
            </TouchableOpacity>
          </Animated.View>)}
      </SafeAreaConsumer>);
    }
}
const styles = {
    container: {
        position: "absolute",
        bottom: 20,
        alignSelf: "center",
        flexDirection: "row",
        backgroundColor: "#333333",
        paddingVertical: 15,
        paddingLeft: 28,
        paddingRight: 8,
        borderRadius: 34,
    },
    item: {
        marginRight: 20,
        position: "relative",
    },
    itemIcon: {
        fontSize: 30,
        color: "#8d8e8f",
    },
    badge: {
        position: 'absolute',
        top: -5,
        right: -5,
        backgroundColor: "#D84444",
        width: 20,
        height: 20,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    badgeText: {
        color: '#ffffff',
        fontSize: 13
    }
};
